import "./AddData.css"
import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Tooth from "../assets/tooth.png";
import { useNavigate } from "react-router-dom";
import { BsPersonFillAdd } from "react-icons/bs";
import { loginkey } from "./LoginContext";
import LogIn from "./login";
import { IdContext } from "./userIdContext";
import { useMediaQuery } from "react-responsive";
import { FaRegSave } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { AddDetailsContext } from "./AddDetailsContext";

function EditPatient(props) {
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [sex, setSex] = useState("");
    const [phone, setPhone] = useState("");
    const [notes, setNotes] = useState("");
    const [infoo, setInfoo] = useState([]);
    const [infooPatient, setInfooPatient] = useState([]);
    const [uploadMessage, setUploadMessage] = useState('');
    const [enable, setEnable] = useState(null);
    const { auth } = useContext(loginkey);
    const { userId } = useContext(IdContext);
    const { setAddDetailsContextt } = useContext(AddDetailsContext)


    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    const handleAgeChange = (e) => {
        setAge(e.target.value);
    };
    const handleSexChange = (e) => {
        setSex(e.target.value);
    };
    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const handleNotesChange = (e) => {
        setNotes(e.target.value);
    };
    const getData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getPatientstoedit.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        setName(e.name);
                        setAge(e.age);
                        setSex(e.sex);
                        setPhone(e.phone);
                        setNotes(e.notes);
                    })
                }
                return setInfooPatient(data.values)

            })
            .catch(err => {
                console.log(err)
            })
    };
    function handleAxiosSubmit(event) {
        event.preventDefault();
        setUploadMessage('... يرجى الانتظار')
        const url = `https://dr-meladjouriah.fxcode.tech/php/editPatientdetails.php?userid=${userId}`;
        const formData = new FormData();
        formData.append('name', name);
        formData.append('age', age);
        formData.append('sex', sex);
        formData.append('phone', phone);
        formData.append('notes', notes);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setUploadMessage("تم")
                navToGet();
            }
            console.log(response);
        })
            .catch(error => {
                setUploadMessage(error.message)
                console.log(error);
            });
    }
    const navigate = useNavigate();
    function navToGet() {
        window.history.pushState({ id: 1 }, null, "GetPatients")
        navigate("/GetPatients", { replace: true });
    }

    function back() {
        window.history.pushState({ id: 1 }, null, "GetPatients")
        navigate("/GetPatients", { replace: true });
    }
    useEffect(() => {
        getData();
    }, [])
    useEffect(() => {
        setAddDetailsContextt(true);
    }, [])
    /*useEffect(()=>{
        window.history.pushState({id:1},null,"EditPatient")
    },[])*/
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <div className="add">
            {auth == 1 && <div>{isDesktopOrLaptop && <div className="container-add">
                <form className="add-form" onSubmit={handleAxiosSubmit}>
                    <img src={Tooth} className="tooth-img"></img>
                    <div className="title-add">تعديل معلومات المريض <BsPersonFillAdd /></div>
                    <input type="text" className="form-control form-input" value={name} onChange={(event) => handleNameChange(event)} placeholder="الاسم" required></input>
                    <input type="number" className="form-control form-input" value={age} onChange={(event) => handleAgeChange(event)} placeholder="العمر" required maxLength={3}></input>
                    <select type="select" className="form-select form-input" value={sex} onChange={(event) => handleSexChange(event)} required>
                        <option value="ذكر">ذكر</option>
                        <option value="انثى">انثى</option>
                    </select>
                    <input type="number" className="form-control form-input form-input-phone" value={phone} onChange={(event) => handlePhoneChange(event)} placeholder="رقم الهاتف" required maxLength={11}></input>
                    <textarea type="text" className="form-control form-input-area" value={notes} onChange={(event) => handleNotesChange(event)} placeholder="ملاحظات" required></textarea>
                    <div className="btn-container">
                        <button type="submit" className="btn btn-success add-button">حفظ</button>
                        <button className="btn btn-dark add-button" onClick={back}>رجوع</button>
                    </div>
                    {uploadMessage && <div className="label-text">{uploadMessage}</div>}
                </form>
            </div>
            }
            </div>}
            {auth == 1 && <div>{isTabletOrMobile && <div className="container-add-mobile">
                <form className="add-form-mobile" onSubmit={handleAxiosSubmit}>
                    <img src={Tooth} className="tooth-img-mobile"></img>
                    <div className="title-add-mobile">تعديل معلومات</div>
                    <input type="text" className="form-control form-input-mobile" value={name} onChange={(event) => handleNameChange(event)} placeholder="الاسم" required></input>
                    <input type="number" className="form-control form-input-mobile" value={age} onChange={(event) => handleAgeChange(event)} placeholder="العمر" required maxLength={3}></input>
                    <select type="select" className="form-select form-input-mobile" value={sex} onChange={(event) => handleSexChange(event)} required>
                        <option value="ذكر">ذكر</option>
                        <option value="انثى">انثى</option>
                    </select>
                    <input type="number" className="form-control form-input-mobile form-input-phone" value={phone} onChange={(event) => handlePhoneChange(event)} placeholder="رقم الهاتف" required maxLength={11}></input>
                    <textarea type="text" className="form-control form-input-area-mobile" value={notes} onChange={(event) => handleNotesChange(event)} placeholder="ملاحظات" required></textarea>
                    <div className="btn-container-mobile">
                        <button type="submit" className="btn btn-success add-button-mobile">إضافة</button>
                        <button className="btn btn-dark add-button-mobile" onClick={back}>رجوع</button>
                    </div>
                    {uploadMessage && <div className="label-text">{uploadMessage}</div>}
                </form>
            </div>}
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default EditPatient;