import { useContext, useEffect, useState } from "react";
import "./Profile.css";
import { IdContext } from "./userIdContext";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

function ProfilePersonalInfo() {
    const [personalInfo, setPersonalInfo] = useState([]);
    const [message, setMessage] = useState("");
    const { userId } = useContext(IdContext);
    const { setUserId } = useContext(IdContext);
    const getPersonalData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getPatientsid.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setPersonalInfo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    /*useEffect(() => {
        setUserId(window.localStorage.getItem("userid"))
        console.log(`vvvv ${window.localStorage.getItem("userid")}`);
    }, [!userId])
    useEffect(() => {
        if (userId) {
            window.localStorage.setItem("userid", userId);
        }
    }, [])*/
    const navigate = useNavigate();
    function back() {
        navigate("/GetPatients", { replace: true });
    }
    function navToPic() {
        navigate("/pic", { replace: true });
    }
    useEffect(() => {
        getPersonalData();
    }, []);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    console.log(`personal info ${personalInfo}`)
    return (
        <div style={{ width: "100%" }}>
            {isDesktopOrLaptop && <div style={{ width: "100%" }}>
                <div className="personal-info">
                    <h4 className="text">المعلومات الشخصية</h4>
                    <div className="btn-add-container-profile">
                        <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack /></div></button>
                    </div>
                    <table className="table-cell">
                        <tr className="item-table-title">
                            <th className="item">الاسم</th>
                            <th className="item">العمر</th>
                            <th className="item">الجنس</th>
                            <th className="item">رقم الهاتف</th>
                            <th className="item">ملاحظات</th>
                            <th className="item">ملف</th>
                        </tr>
                        {!message && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">يرجى الانتظار...</span>
                            </div>
                            <h2 className="loading">يرجى الانتظار...</h2>
                        </div>}
                        {personalInfo.map((e) => {
                            let downloadLink = `https://dr-meladjouriah.fxcode.tech/php/${e.fileName}`
                            return (
                                <tr className="item-table">
                                    <td className="item">{e.name}</td>
                                    <td className="item">{e.age}</td>
                                    <td className="item">{e.sex}</td>
                                    <td className="item">0{e.phone}</td>
                                    <td className="item">{e.notes}</td>
                                    <td className="item">
                                        {e.fileName == "nofile" && <div className="text">لا يوجد ملف</div>}
                                        {e.fileName != "nofile" && <button className="btn btn-dark" onClick={navToPic}>عرض الملف</button>}
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
            }
            {isTabletOrMobile && <div className="personal-info-mobile">
                <h4 className="text">المعلومات الشخصية</h4>
                <div className="btn-add-container-profile-mobile">
                    <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack /></div></button>
                </div>
                {personalInfo.map((e) => {
                    let downloadLink = `https://dr-meladjouriah.fxcode.tech/php/${e.fileName}`
                    return (
                        <div className="all-info-mobile">
                            <div className="item-info-mobile">
                                <h6 className="text">{e.name}</h6>
                                <h5 className="text"><b>الاسم</b></h5>
                            </div>
                            <div className="item-info-mobile">
                                <h6 className="text">{e.age}</h6>
                                <h5 className="text"><b>العمر</b></h5>
                            </div>
                            <div className="item-info-mobile">
                                <h6 className="text">{e.sex}</h6>
                                <h5 className="text"><b>الجنس</b></h5>
                            </div>
                            <div className="item-info-mobile">
                                <h6 className="text">{e.phone}</h6>
                                <h5 className="text"><b>رقم الهاتف</b></h5>
                            </div>
                            <div className="item-info-mobile">
                                <h6 className="text">{e.notes}</h6>
                                <h5 className="text"><b>ملاحظات</b></h5>
                            </div>
                            {e.fileName != "nofile" && <div className="item-info-mobile">
                                <div className="text"><button className="btn btn-dark" onClick={navToPic}>عرض الملف</button></div>
                                <h5 className="text"><b>ملف</b></h5>
                            </div>}
                            {e.fileName == "nofile" && <div className="item-info-mobile">
                                <h6 className="text"><div className="text">لا يوجد ملف</div></h6>
                                <h5 className="text"><b>ملف</b></h5>
                            </div>}
                        </div>
                    )
                })}
                {!message && <div className="loading-all">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">يرجى الانتظار...</span>
                    </div>
                    <h2 className="loading">يرجى الانتظار...</h2>
                </div>}
            </div>
            }
        </div >
    )
}

export default ProfilePersonalInfo;

/*                                <tr className="item-table-title-mobile">
                                    <th className="item th-mobile">Name</th>
                                </tr>
                                <td className="item">{e.name}</td>
                                <tr className="item-table-title-mobile">
                                    <th className="item th-mobile">Age</th>
                                </tr>
                                <td className="item">{e.age}</td>
                                <tr className="item-table-title-mobile">
                                    <th className="item th-mobile">Sex</th>
                                </tr>
                                <td className="item">{e.sex}</td>
                                <tr className="item-table-title-mobile">
                                    <th className="item th-mobile">Phone</th>
                                </tr>
                                <td className="item">{e.phone}</td>
                                <tr className="item-table-title-mobile">
                                    <th className="item th-mobile">Notes</th>
                                </tr>
                                <td className="item">{e.notes}</td>*/