import { useContext, useEffect, useState } from "react";
import PatientCard from "./PatientCard";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./GetData.css";
import { BsPersonFillAdd } from "react-icons/bs";
import AddData from "./AddData";
import { ConfirmContext } from "./Main";
import AddPayment from "./AddPayment";
import LogIn, { loginAuth } from "./login";
import { loginkey } from "./LoginContext";
import { IdContext } from "./userIdContext";
import axios from "axios";
import { useMediaQuery } from 'react-responsive';
import NavBar from "./navBar";
import { TabContext } from "./tabindexContext";
import { NavContext } from "./NavContext";
import PersonalInfoTable from "./PersonalInfoTable";
import SessionsTable from "./SessionsTable";
import PaymentsTable from "./paymentsTable";
import { IoMdAdd } from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AddDetailsContext } from "./AddDetailsContext";


function GetData(props) {
    const [infooo, setInfooo] = useState([]);
    const [message, setMessage] = useState("");
    const [toAdd, setToAdd] = useState(false);
    const [addDetails, setAddDetails] = useState(false);
    /*const [personal, setPersonal] = useState(false);
    const [sessions, setSessions] = useState(false);
    const [payments, setPayments] = useState(false);*/
    const [search, setSearch] = useState("");
    const [selectedId, setSelectedId] = useState();
    const { setUserId } = useContext(IdContext);
    const { userId } = useContext(IdContext);
    const confirm = useContext(loginAuth);
    const { auth } = useContext(loginkey)
    const { tab } = useContext(TabContext);
    const { patients } = useContext(NavContext);
    const { sessions } = useContext(NavContext);
    const { payments } = useContext(NavContext);
    const { setTab } = useContext(TabContext);
    const { setAddDetailsContextt } = useContext(AddDetailsContext)
    const getData = async () => {
        await fetch("https://dr-meladjouriah.fxcode.tech/php/getPatients.php",
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getSearchData = async (e) => {
        e.preventDefault();
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/search.php?search=${search}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    function handleDelete() {
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePatient.php?userid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                handleDeleteSessions();
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    function handleDeleteSessions() {
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePatientSession.php?userid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                handleDeleteTreats();
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    function handleDeleteTreats() {
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePatientTreat.php?userid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                handleDeletePayments();
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    function handleDeletePayments() {
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePatientPayment.php?userid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                getData();
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    const navigate = useNavigate();
    function navToAdd() {
        setToAdd(true)
        navigate("/AddPatients", { replace: true });
    }

    function navToSessions() {
        setUserId(selectedId);
        setToAdd(true)
        navigate("/GetSession", { replace: true });
    }

    function navToPayments() {
        setUserId(selectedId);
        navigate("/GetPayments", { replace: true });
    }
    function navToDetails() {
        setUserId(selectedId);
        navigate("/Profile", { replace: true });
    }
    function deleteData() {
        setUserId(selectedId);
        handleDelete();
    }
    function edit() {
        setUserId(selectedId);
        navigate("/EditDetails", { replace: true });
    }
    useEffect(() => {
        setAddDetailsContextt(false);
        getData();
    }, [])

    console.log(infooo)
    console.log(`selectedId ${selectedId}`)
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1240px)'
    })
    const isSmallLap = useMediaQuery({
        query: '(min-width: 1224px) and (max-width: 1240px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    useEffect(()=>{
        window.history.pushState({id:1},null,"GetPatients")
    },[])
    return (
        <div>
            {auth == 1 && <div> {isSmallLap && <div className="container-get">
                {!toAdd && <div className="container-v">
                    <div className="container-pages-smallLap">
                        {patients && <PersonalInfoTable />}
                        {sessions && <SessionsTable />}
                        {payments && <PaymentsTable />}
                    </div>
                </div>
                }
            </div>
            }
            </div>}
            {auth == 1 && <div> {isDesktopOrLaptop && <div className="container-get">
                {!toAdd && <div className="container-v">
                    <div className="container-pages">
                        {patients && <PersonalInfoTable />}
                        {sessions && <SessionsTable />}
                        {payments && <PaymentsTable />}
                    </div>
                </div>
                }
            </div>
            }
            </div>}
            {auth == 1 && <div> {isTabletOrMobile && <div className="container-get-mobile">
                {!toAdd && <div className="container-v-mobile">
                    <div className="container-pages-mobile">
                        {patients && <PersonalInfoTable />}
                        {sessions && <SessionsTable />}
                        {payments && <PaymentsTable />}
                    </div>
                </div>
                }
            </div>
            }
            </div>}
            {auth == 0 && <LogIn />}
            {isTabletOrMobile && <NavBar />}
        </div>
    );
}

export default GetData;

//                {isTabletOrMobile && <NavBar sessions={navToSessions} profile={navToDetails} payments={navToPayments} />}

/*                            <table className="table-cell">
                                <tr className="item-table-title">
                                    <th className="item">Name</th>
                                    <th className="item">Age</th>
                                    <th className="item">Sex</th>
                                    <th className="item">Phone</th>
                                    <th className="item">Notes</th>
                                </tr>
                                {!message && <div className="loading-all">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <h2 className="loading">Loading...</h2>
                                </div>}
                                {infooo.map((e) => {
                                    function showDetails() {
                                        setAddDetails(true);
                                        setSelectedId(e.id);
                                        setTab(1);
                                    }
                                    return (
                                        <tr className="item-table" tabIndex={tab} onClick={showDetails}>
                                            <td className="item">{e.name}</td>
                                            <td className="item">{e.age}</td>
                                            <td className="item">{e.sex}</td>
                                            <td className="item">0{e.phone}</td>
                                            <td className="item">{e.notes}</td>
                                        </tr>
                                    )
                                })}
                            </table>*/


/*<div className="search-nav">
<div className="search-container">
    <form className="search-form" onSubmit={getSearchData}>
        <input className="form-control" onChange={(event) => handleSearchChange(event)}></input>
        <button className="btn btn-dark">Search</button>
    </form>
</div>
<div className="item-btns">
    <button className="btn btn-dark" onClick={navToSessions} disabled={!selectedId}>Sessions</button>
    <button className="btn btn-dark" onClick={navToDetails} disabled={!selectedId}>Profile</button>
    <button className="btn btn-dark" onClick={deleteData} disabled={!selectedId}>Delete</button>
    <button className="btn btn-dark" onClick={edit} disabled={!selectedId}>Edit</button>
    <button className="btn btn-dark" onClick={navToPayments} disabled={!selectedId}>Payments</button>
</div>
</div>*/