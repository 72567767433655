import t1u from "../assets/mywork/1lu.png"
import t2u from "../assets/mywork/2lu.png"
import t3u from "../assets/mywork/3lu.png"
import t4u from "../assets/mywork/4lu.png"
import t5u from "../assets/mywork/5lu.png"
import t6u from "../assets/mywork/6lu.png"
import t7u from "../assets/mywork/7lu.png"
import t8u from "../assets/mywork/8lu.png"

import t1ru from "../assets/mywork/1ru.png"
import t2ru from "../assets/mywork/2ru.png"
import t3ru from "../assets/mywork/3ru.png"
import t4ru from "../assets/mywork/4ru.png"
import t5ru from "../assets/mywork/5ru.png"
import t6ru from "../assets/mywork/6ru.png"
import t7ru from "../assets/mywork/7ru.png"
import t8ru from "../assets/mywork/8ru.png"

import t1rd from "../assets/mywork/1rd.png"
import t2rd from "../assets/mywork/2rd.png"
import t3rd from "../assets/mywork/3rd.png"
import t4rd from "../assets/mywork/4rd.png"
import t5rd from "../assets/mywork/5rd.png"
import t6rd from "../assets/mywork/6rd.png"
import t7rd from "../assets/mywork/7rd.png"
import t8rd from "../assets/mywork/8rd.png"


import t1tlu from "../assets/mywork/t1tlu.png"
import t2tlu from "../assets/mywork/t2tlu.png"
import t3tlu from "../assets/mywork/t3tlu.png"
import t4tlu from "../assets/mywork/t4tlu.png"
import t5tlu from "../assets/mywork/t5tlu.png"

import t1tld from "../assets/mywork/t1tld.png"
import t2tld from "../assets/mywork/t2tld.png"
import t3tld from "../assets/mywork/t3tld.png"
import t4tld from "../assets/mywork/t4tld.png"
import t5tld from "../assets/mywork/t5tld.png"


import t1d from "../assets/mywork/1ld.png"
import t2d from "../assets/mywork/2ld.png"
import t3d from "../assets/mywork/3ld.png"
import t4d from "../assets/mywork/4ld.png"
import t5d from "../assets/mywork/5ld.png"
import t6d from "../assets/mywork/6ld.png"
import t7d from "../assets/mywork/7ld.png"
import t8d from "../assets/mywork/8ld.png"
import t1tu from "../assets/mywork/t1tu.png"
import t2tu from "../assets/mywork/t2tu.png"
import t3tu from "../assets/mywork/t3tu.png"
import t4tu from "../assets/mywork/t4tu.png"
import t5tu from "../assets/mywork/t5tu.png"
import t1td from "../assets/mywork/t1td.png"
import t2td from "../assets/mywork/t2td.png"
import t3td from "../assets/mywork/t3td.png"
import t4td from "../assets/mywork/t4td.png"
import t5td from "../assets/mywork/t5td.png"


/*import t1u from "../assets/t1u.png"
import t2u from "../assets/t2u.png"
import t3u from "../assets/t3u.png"
import t4u from "../assets/t4u.png"
import t5u from "../assets/t5u.png"
import t6u from "../assets/t6u.png"
import t7u from "../assets/t7u.png"
import t8u from "../assets/t8u.png"
import t1d from "../assets/t1d.png"
import t2d from "../assets/t2d.png"
import t3d from "../assets/t3d.png"
import t4d from "../assets/t4d.png"
import t5d from "../assets/t5d.png"
import t6d from "../assets/t6d.png"
import t7d from "../assets/t7d.png"
import t8d from "../assets/t8d.png"
import t1tu from "../assets/t1tu.png"
import t2tu from "../assets/t2tu.png"
import t3tu from "../assets/t3tu.png"
import t4tu from "../assets/t4tu.png"
import t5tu from "../assets/t5tu.png"
import t1td from "../assets/t1td.png"
import t2td from "../assets/t2td.png"
import t3td from "../assets/t3td.png"
import t4td from "../assets/t4td.png"
import t5td from "../assets/t5td.png"*/
import { useContext, useState, useEffect } from "react"
import "./Treatments.css"
import axios from "axios"
import { IdContext } from "./userIdContext"
import { SessionContext } from "./SessionContext"
import { useNavigate } from "react-router-dom"
import { IdTreat } from "./treatIdContext"
import { useMediaQuery } from "react-responsive"
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import { loginkey } from "./LoginContext"
import LogIn from "./login"



function EditTreatments() {
    const [treatmentType, setTreatmentType] = useState("")
    const [tt, setTt] = useState("")
    const [treatShow, setTreatShow] = useState("")
    const [lopiaType, setLopiaType] = useState("")
    const [status, setStatus] = useState("دائم");
    const [type, setType] = useState("");
    const [openAddToMenu, setopenAddToMenu] = useState(false);
    const [cannalName, setCannalName] = useState("");
    const [cannalLength, setCannalLength] = useState("");
    const [cannalLengthList, setCannalLengthList] = useState([]);
    const [channal, setChannal] = useState("");
    const [channalNote, setChannalNote] = useState("")
    const [channalB, setChannalB] = useState("")
    const [channalP, setChannalP] = useState("")
    const [channalDB, setChannalDB] = useState("")
    const [channalMB2, setChannalMB2] = useState("")
    const [channalMB1, setChannalMB1] = useState("")
    const [channalMB, setChannalMB] = useState("")
    const [channalL, setChannalL] = useState("")
    const [channalML, setChannalML] = useState("")
    const [channalD, setChannalD] = useState("")
    const [trmimtype, setTrmimtype] = useState("")
    const [tiganType, setTigantype] = useState("")
    const [tarmimMaterial, setTarmimMaterial] = useState("")
    const [chooseClass, setChooseClass] = useState("")
    const [class2Option, setClass2Option] = useState("")
    const [tagGazafMadan, setTagGazafMadan] = useState("")
    const [tagGazafiKamel, setTagGazafiKamel] = useState("")
    const [GsrGazafMadan, setGsrGazafMadan] = useState("")
    const [GsrGazafiKamel, setGsrGazafiKamel] = useState("")
    const [Tigan, setTigan] = useState("")
    const [Gsr, setGsr] = useState("")
    const [tanzif, setTanzif] = useState("")
    const [tabeed, setTabeed] = useState("")
    const [kala, setKala] = useState("")
    const [noteLopia, setNoteLopia] = useState("")
    const [noteVeneers, setNoteVeneers] = useState("")
    const [noteoutclinic, setNoteOutclinic] = useState("")
    const [noteTegan, setNoteTegan] = useState("")
    const [noteGsoor, setNoteGsoor] = useState("")
    const [noteKala, setNoteKala] = useState("")
    const [noteTanzif, setNoteTanzif] = useState("")
    const [noteTabeed, setNoteTabeed] = useState("")
    const [noteTarmim, setNoteTarmim] = useState("")
    const [cost, setCost] = useState("")
    const [tooth, setTooth] = useState("")
    const [inclinic, setInclinic] = useState("");
    const [message, setMessage] = useState("")
    const [sumCost, setSumCost] = useState("");
    const [infoooCost, setInfoooCost] = useState("");
    const [infoooSum, setInfoooSum] = useState("");
    const [infooo, setInfooo] = useState([]);
    const [infoooTreats, setInfoooTreats] = useState([]);
    const [toothList, setToothList] = useState([]);
    const [toothoutClinicList, setToothoutClinicList] = useState([]);
    const [vvv, setVvv] = useState("")
    let [v, setV] = useState("");
    let [cannals, setCannals] = useState({
        "cannalName": "",
        "cannalLength": ""
    });
    const [addTreatMessage, setAddTreatMessage] = useState("")
    const [addTrmimMaterialMessage, setAddTrmimMaterialMessage] = useState("")
    const [addTiganMaterialMessage, setAddTiganMaterialMessage] = useState("")
    const [addcannalMessage, setAddCannalMessage] = useState("")
    const [infoooAddCannals, setInfoooAddCannals] = useState([]);
    const [infoooTreatType, setInfoooTreatType] = useState([]);
    const [infoooTrmimMatreialType, setInfoooTrmimMatreialType] = useState([]);
    const [infoooTiganMaterialType, setInfoooTiganMaterialType] = useState([]);
    const [infooTreatments, setInfooTreatments] = useState([]);
    const [treatStatus, setTreatStatus] = useState("");
    const [trmimMatreialTypeArray, setTrmimMatreialTypeArray] = useState([]);
    const [tiganMaterialTypeArray, setTiganMaterialTypeArray] = useState([]);
    const [treatmentsArray, setTreatmentsArray] = useState([]);
    const [allgsrArray, setAllGsrArray] = useState([]);
    const [gsrArray, setGsrArray] = useState([]);
    const { setUserId } = useContext(IdContext);
    const { userId } = useContext(IdContext);
    const { setSessionId } = useContext(SessionContext);
    const { sessionId } = useContext(SessionContext);
    const { treatId } = useContext(IdTreat);
    const { auth } = useContext(loginkey)




    const addSum = async (sumValue) => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/addcostssum.php?userid=${userId}&costssum=${sumValue}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                }
                return setInfoooSum(data.values)

            })
            .catch(err => {
                console.log(err)
            })
    };
    const getCostData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getCosts.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        console.log(`sumcost ${e.sumcost}`);
                        addSum(e.sumcost)
                        setSumCost(e.sumcost)
                    })
                }
                return setInfoooCost(data.values)

            })
            .catch(err => {
                console.log(err)
            })
        console.log(`infoCostfunc ${infoooCost}`)
    };


    const getTreatType = async () => {
        await fetch("https://dr-meladjouriah.fxcode.tech/php/getTreatType.php",
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        treatmentsArray.push(e.type)
                    })
                }
                return setInfoooTreatType(data.values)

            })
            .catch(err => {
                console.log(err)
            })
    };

    const getTiganMaterialType = async () => {
        await fetch("https://dr-meladjouriah.fxcode.tech/php/getTiganMaterialType.php",
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        tiganMaterialTypeArray.push(e.type)
                    })
                }
                return setInfoooTiganMaterialType(data.values)

            })
            .catch(err => {
                console.log(err)
            })
    };

    const getTrmimMaterialType = async () => {
        await fetch("https://dr-meladjouriah.fxcode.tech/php/getTrmimMaterialType.php",
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        trmimMatreialTypeArray.push(e.type)
                    })
                }
                return setInfoooTrmimMatreialType(data.values)

            })
            .catch(err => {
                console.log(err)
            })
    };

    function addTreatmentType(event) {
        event.preventDefault();
        setAddTreatMessage("... يرجى الانتظار")
        const url = 'https://dr-meladjouriah.fxcode.tech/php/addTreatType.php';
        const formData = new FormData();
        formData.append('type', type);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setAddTreatMessage("تمت الإضافة بنجاح")
                getCostData()
                getTreatType();
            }
            console.log(response);
        })
            .catch(error => {
                setMessage(error);
            });
    }

    function addTiganMaterialType(event) {
        event.preventDefault();
        setAddTiganMaterialMessage("... يرجى الانتظار")
        const url = 'https://dr-meladjouriah.fxcode.tech/php/addTiganMaterial.php';
        const formData = new FormData();
        formData.append('type', tiganType);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setAddTiganMaterialMessage("تمت الإضافة بنجاح")
                getCostData()
                getTiganMaterialType();
            }
            console.log(response);
        })
            .catch(error => {
                setMessage(error);
            });
    }

    function addTrmimMatrialType(event) {
        event.preventDefault();
        setAddTrmimMaterialMessage("... يرجى الانتظار")
        const url = 'https://dr-meladjouriah.fxcode.tech/php/addTarmimMaterial.php';
        const formData = new FormData();
        formData.append('type', trmimtype);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setAddTrmimMaterialMessage("تمت الإضافة بنجاح")
                getCostData()
                getTrmimMaterialType();
            }
            console.log(response);
        })
            .catch(error => {
                setMessage(error);
            });
    }








    function handleAxiosSubmit(event) {
        event.preventDefault();
        setMessage("... يرجى الانتظار")
        const url = `https://dr-meladjouriah.fxcode.tech/php/EditTreatments.php?treatid=${treatId}`;
        const formLopia = new FormData();
        const formTarmemia = new FormData();
        const formTegan = new FormData();
        const formVeneers = new FormData();
        const formGsoor = new FormData();
        const formTanzif = new FormData();
        const formTabeed = new FormData();
        const formKala = new FormData();
        const formKatazerwa = new FormData();
        const formData = new FormData();
        formLopia.append('treatmentType', treatmentType);
        formTarmemia.append('treatmentType', treatmentType);
        formTegan.append('treatmentType', treatmentType);
        formVeneers.append('treatmentType', treatmentType);
        formGsoor.append('treatmentType', treatmentType);
        formGsoor.append('gsrArray', gsrArray);
        formTanzif.append('treatmentType', treatmentType);
        formTabeed.append('treatmentType', treatmentType);
        formKala.append('treatmentType', treatmentType);
        formKatazerwa.append('treatmentType', treatmentType);
        formLopia.append('status', status);
        formTarmemia.append('status', status);
        formTegan.append('status', status);
        formVeneers.append('status', status);
        formGsoor.append('status', status);
        formTanzif.append('status', status);
        formTabeed.append('status', status);
        formKala.append('status', status);
        formKatazerwa.append('status', status);
        formLopia.append('lopiaType', lopiaType);
        formLopia.append('cannals', JSON.stringify(infoooAddCannals));
        formLopia.append('cannalsLength', cannalLengthList);
        //formData.append('status', status);
        formLopia.append('channal', channal);
        formLopia.append('channalNote', channalNote);
        formLopia.append('channalB', channalB);
        formLopia.append('channalP', channalP);
        formLopia.append('channalDB', channalDB);
        formLopia.append('channalMB2', channalMB2);
        formLopia.append('channalMB1', channalMB1);
        formLopia.append('channalMB', channalMB);
        formLopia.append('channalL', channalL);
        formLopia.append('channalML', channalML);
        formLopia.append('channalD', channalD);
        formTarmemia.append('tarmimMaterial', tarmimMaterial);
        formTarmemia.append('chooseClass', chooseClass);
        formTarmemia.append('class2Option', class2Option);
        formTegan.append('tagGazafMadan', tagGazafMadan);
        formTegan.append('tagGazafiKamel', tagGazafiKamel);
        formGsoor.append('GsrGazafMadan', GsrGazafMadan);
        formGsoor.append('GsrGazafiKamel', GsrGazafiKamel);
        formTegan.append('Tigan', Tigan);
        formGsoor.append('Gsr', Gsr);
        formTanzif.append('tanzif', tanzif);
        formTabeed.append('tabeed', tabeed);
        formKala.append('kala', kala);
        formVeneers.append('noteVeneers', noteVeneers);
        formTegan.append('noteTegan', noteTegan);
        formGsoor.append('noteGsoor', noteGsoor);
        formKala.append('noteKala', noteKala);
        formTanzif.append('noteTanzif', noteTanzif);
        formTabeed.append('noteTabeed', noteTabeed);
        formTarmemia.append('noteTarmim', noteTarmim);
        formLopia.append('userid', userId);
        formTarmemia.append('userid', userId);
        formTegan.append('userid', userId);
        formVeneers.append('userid', userId);
        formGsoor.append('userid', userId);
        formTanzif.append('userid', userId);
        formTabeed.append('userid', userId);
        formKala.append('userid', userId);
        formKatazerwa.append('userid', userId);
        /*formLopia.append('sessionid', sessionId);
        formTarmemia.append('sessionid', sessionId);
        formTegan.append('sessionid', sessionId);
        formVeneers.append('sessionid', sessionId);
        formGsoor.append('sessionid', sessionId);
        formTanzif.append('sessionid', sessionId);
        formTabeed.append('sessionid', sessionId);
        formKala.append('sessionid', sessionId);
        formKatazerwa.append('sessionid', sessionId);*/
        formLopia.append('cost', cost);
        formTarmemia.append('cost', cost);
        formTegan.append('cost', cost);
        formVeneers.append('cost', cost);
        formGsoor.append('cost', cost);
        formTanzif.append('cost', cost);
        formTabeed.append('cost', cost);
        formKala.append('cost', cost);
        formKatazerwa.append('cost', cost);
        formLopia.append('tooth', tooth);
        formTarmemia.append('tooth', tooth);
        formTegan.append('tooth', tooth);
        formVeneers.append('tooth', tooth);
        formGsoor.append('tooth', tooth);
        formTanzif.append('tooth', tooth);
        formTabeed.append('tooth', tooth);
        formKala.append('tooth', tooth);
        formKatazerwa.append('tooth', tooth);
        formLopia.append('inclinic', inclinic);
        formTarmemia.append('inclinic', inclinic);
        formTegan.append('inclinic', inclinic);
        formVeneers.append('inclinic', inclinic);
        formGsoor.append('inclinic', inclinic);
        formTanzif.append('inclinic', inclinic);
        formTabeed.append('inclinic', inclinic);
        formKala.append('inclinic', inclinic);
        formKatazerwa.append('inclinic', inclinic);
        formLopia.append('noteLopia', noteLopia);
        formTarmemia.append('noteLopia', noteLopia);
        formTegan.append('noteLopia', noteLopia);
        formVeneers.append('noteLopia', noteLopia);
        formGsoor.append('noteLopia', noteLopia);
        formTanzif.append('noteLopia', noteLopia);
        formTabeed.append('noteLopia', noteLopia);
        formKala.append('noteLopia', noteLopia);
        formKatazerwa.append('noteLopia', noteLopia);
        formData.append('status', status);
        formData.append('noteoutclinic', noteoutclinic);
        formData.append('inclinic', inclinic);
        formData.append('tooth', tooth);
        formData.append('userid', userId);
        /*formData.append('sessionid', sessionId);*/
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        if (treatmentType === "معالجة لبية" && !type) {
            axios.post(url, formLopia, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "معالجة ترميمية" && !type) {
            axios.post(url, formTarmemia, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    addSum()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "تيجان" && !type) {
            axios.post(url, formTegan, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "جسور" && !type) {
            cc();
        }
        if (treatmentType === "Venners" && !type) {
            axios.post(url, formVeneers, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "تنظيف لثة وأسنان" && !type) {
            axios.post(url, formTanzif, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "تبييض" && !type) {
            axios.post(url, formTabeed, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "قلع" && !type) {
            axios.post(url, formKala, config).then(async response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "قطع ذروة" && !type) {
            axios.post(url, formKatazerwa, config).then(async response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (type) {
            handleAxiosAddTreatSubmitType(type)
        }
        if (tt && !type) {
            handleAxiosAddTreatSubmit(tt)
        }
        if (!treatmentType && !tt && !type) {
            axios.post(url, formData, config).then(async response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
    }
    function handleAxiosAddTreatSubmitType(type) {
        setMessage("... يرجى الانتظار")
        const url = `https://dr-meladjouriah.fxcode.tech/php/EditTreatments.php?treatid=${treatId}`;
        const formDataTypee = new FormData();
        formDataTypee.append('treatmentType', type);
        formDataTypee.append('status', status);
        formDataTypee.append('userid', userId);
        /*formDataTypee.append('sessionid', sessionId);*/
        formDataTypee.append('cost', cost);
        formDataTypee.append('tooth', tooth);
        formDataTypee.append('inclinic', inclinic);
        formDataTypee.append('noteLopia', noteLopia);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formDataTypee, config).then(response => {
            if (response.status === 200) {
                setMessage("تمت الإضافة بنجاح")
                console.log("TTTTTYYYYYYYYYYYYYYYYYYYYYYYYYYPPPPPPPPPPPEEEEEEEEEEE")
                restForm();
                getCostData()
                back();
            }
            console.log(response);
        })
            .catch(error => {
                setMessage(error);
            });
    }

    function handleAxiosAddTreatSubmit(tt) {
        setMessage("... يرجى الانتظار")
        const url = `https://dr-meladjouriah.fxcode.tech/php/EditTreatments.php?treatid=${treatId}`;
        const formDataType = new FormData();
        formDataType.append('treatmentType', tt);
        formDataType.append('status', status);
        formDataType.append('userid', userId);
        /*formDataType.append('sessionid', sessionId);*/
        formDataType.append('cost', cost);
        formDataType.append('tooth', tooth);
        formDataType.append('inclinic', inclinic);
        formDataType.append('noteLopia', noteLopia);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formDataType, config).then(response => {
            if (response.status === 200) {
                setMessage("تمت الإضافة بنجاح")
                console.log("TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT")
                restForm();
                getCostData()
                back();
            }
            console.log(response);
        })
            .catch(error => {
                setMessage(error);
            });
    }





    function handleAxiosSubmit2(toothGsr) {
        setMessage("... يرجى الانتظار")

        const url = `https://dr-meladjouriah.fxcode.tech/php/EditTreatments.php?treatid=${treatId}`;
        const formLopia = new FormData();
        const formTarmemia = new FormData();
        const formTegan = new FormData();
        const formVeneers = new FormData();
        const formGsoor = new FormData();
        const formTanzif = new FormData();
        const formTabeed = new FormData();
        const formKala = new FormData();
        const formKatazerwa = new FormData();
        const formData = new FormData();
        formLopia.append('treatmentType', treatmentType);
        formTarmemia.append('treatmentType', treatmentType);
        formTegan.append('treatmentType', treatmentType);
        formVeneers.append('treatmentType', treatmentType);
        formGsoor.append('treatmentType', treatmentType);
        formGsoor.append('gsrArray', gsrArray);
        formTanzif.append('treatmentType', treatmentType);
        formTabeed.append('treatmentType', treatmentType);
        formKala.append('treatmentType', treatmentType);
        formKatazerwa.append('treatmentType', treatmentType);
        formLopia.append('status', status);
        formTarmemia.append('status', status);
        formTegan.append('status', status);
        formVeneers.append('status', status);
        formGsoor.append('status', status);
        formTanzif.append('status', status);
        formTabeed.append('status', status);
        formKala.append('status', status);
        formKatazerwa.append('status', status);
        formLopia.append('lopiaType', lopiaType);
        //formData.append('status', status);
        formLopia.append('channal', channal);
        formLopia.append('channalNote', channalNote);
        formLopia.append('channalB', channalB);
        formLopia.append('channalP', channalP);
        formLopia.append('channalDB', channalDB);
        formLopia.append('channalMB2', channalMB2);
        formLopia.append('channalMB1', channalMB1);
        formLopia.append('channalMB', channalMB);
        formLopia.append('channalL', channalL);
        formLopia.append('channalML', channalML);
        formLopia.append('channalD', channalD);
        formTarmemia.append('tarmimMaterial', tarmimMaterial);
        formTarmemia.append('chooseClass', chooseClass);
        formTarmemia.append('class2Option', class2Option);
        formTegan.append('tagGazafMadan', tagGazafMadan);
        formTegan.append('tagGazafiKamel', tagGazafiKamel);
        formGsoor.append('GsrGazafMadan', GsrGazafMadan);
        formGsoor.append('GsrGazafiKamel', GsrGazafiKamel);
        formTegan.append('Tigan', Tigan);
        formGsoor.append('Gsr', Gsr);
        formTanzif.append('tanzif', tanzif);
        formTabeed.append('tabeed', tabeed);
        formKala.append('kala', kala);
        formVeneers.append('noteVeneers', noteVeneers);
        formTegan.append('noteTegan', noteTegan);
        formGsoor.append('noteGsoor', noteGsoor);
        formKala.append('noteKala', noteKala);
        formTanzif.append('noteTanzif', noteTanzif);
        formTabeed.append('noteTabeed', noteTabeed);
        formTarmemia.append('noteTarmim', noteTarmim);
        formLopia.append('userid', userId);
        formTarmemia.append('userid', userId);
        formTegan.append('userid', userId);
        formVeneers.append('userid', userId);
        formGsoor.append('userid', userId);
        formTanzif.append('userid', userId);
        formTabeed.append('userid', userId);
        formKala.append('userid', userId);
        formKatazerwa.append('userid', userId);
        /*formLopia.append('sessionid', sessionId);
        formTarmemia.append('sessionid', sessionId);
        formTegan.append('sessionid', sessionId);
        formVeneers.append('sessionid', sessionId);
        formGsoor.append('sessionid', sessionId);
        formTanzif.append('sessionid', sessionId);
        formTabeed.append('sessionid', sessionId);
        formKala.append('sessionid', sessionId);
        formKatazerwa.append('sessionid', sessionId);*/
        formLopia.append('cost', cost);
        formTarmemia.append('cost', cost);
        formTegan.append('cost', cost);
        formVeneers.append('cost', cost);
        formGsoor.append('cost', cost);
        formTanzif.append('cost', cost);
        formTabeed.append('cost', cost);
        formKala.append('cost', cost);
        formKatazerwa.append('cost', cost);
        formLopia.append('tooth', tooth);
        formTarmemia.append('tooth', tooth);
        formTegan.append('tooth', tooth);
        formVeneers.append('tooth', tooth);
        formGsoor.append('tooth', toothGsr);
        formTanzif.append('tooth', tooth);
        formTabeed.append('tooth', tooth);
        formKala.append('tooth', tooth);
        formKatazerwa.append('tooth', tooth);
        formLopia.append('inclinic', inclinic);
        formTarmemia.append('inclinic', inclinic);
        formTegan.append('inclinic', inclinic);
        formVeneers.append('inclinic', inclinic);
        formGsoor.append('inclinic', inclinic);
        formTanzif.append('inclinic', inclinic);
        formTabeed.append('inclinic', inclinic);
        formKala.append('inclinic', inclinic);
        formKatazerwa.append('inclinic', inclinic);
        formLopia.append('noteLopia', noteLopia);
        formTarmemia.append('noteLopia', noteLopia);
        formTegan.append('noteLopia', noteLopia);
        formVeneers.append('noteLopia', noteLopia);
        formGsoor.append('noteLopia', noteLopia);
        formTanzif.append('noteLopia', noteLopia);
        formTabeed.append('noteLopia', noteLopia);
        formKala.append('noteLopia', noteLopia);
        formKatazerwa.append('noteLopia', noteLopia);
        formData.append('status', status);
        formData.append('noteoutclinic', noteoutclinic);
        formData.append('inclinic', inclinic);
        formData.append('tooth', tooth);
        formData.append('userid', userId);
        /*formData.append('sessionid', sessionId);*/
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        if (treatmentType === "معالجة لبية") {
            axios.post(url, formLopia, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")

                    restForm();
                    getCostData()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "معالجة ترميمية") {
            axios.post(url, formTarmemia, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                    addSum()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "تيجان") {
            axios.post(url, formTegan, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "جسور") {
            axios.post(url, formGsoor, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    console.log("GGGGGGGGGSSSSSSSSSSSSRRRRRRRRRRRRRRR")
                    restForm();
                    getCostData()
                    back();
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });

        }
        if (treatmentType === "Venners") {
            axios.post(url, formVeneers, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "تنظيف لثة وأسنان") {
            axios.post(url, formTanzif, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "تبييض") {
            axios.post(url, formTabeed, config).then(response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "قلع") {
            axios.post(url, formKala, config).then(async response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (treatmentType === "قطع ذروة") {
            axios.post(url, formKatazerwa, config).then(async response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
        if (!treatmentType) {
            axios.post(url, formData, config).then(async response => {
                if (response.status === 200) {
                    setMessage("تمت الإضافة بنجاح")
                    restForm();
                    getCostData()
                }
                console.log(response);
            })
                .catch(error => {
                    setMessage(error);
                });
        }
    }

    function cc() {
        handleAxiosSubmit2(tooth)
        /*
        gsrArray.map((e) => {
            handleAxiosSubmit2(e)
        })*/
    }


    function restForm() {
        setTreatmentType("")
        setLopiaType("")
        setChannal("")
        setChannalNote("")
        setChannalB("")
        setChannalP("")
        setChannalDB("")
        setChannalMB2("")
        setChannalMB1("")
        setChannalMB("")
        setChannalL("")
        setChannalML("")
        setChannalD("")
        setTarmimMaterial("")
        setChooseClass("")
        setClass2Option("")
        setTagGazafMadan("")
        setTagGazafiKamel("")
        setGsrGazafMadan("")
        setGsrGazafiKamel("")
        setTigan("")
        setGsr("")
        setTanzif("")
        setTabeed("")
        setKala("")
        setNoteLopia("")
        setNoteVeneers("")
        setNoteOutclinic("")
        setNoteTegan("")
        setNoteGsoor("")
        setNoteKala("")
        setNoteTanzif("")
        setNoteTabeed("")
        setNoteTarmim("")
        setCost("")
        setTooth("")
        setInclinic("")
    }

    const getTreatsData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getEditidTreats.php?id=${treatId}&userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        if (e.inclinic === "in") {
                            toothList.push(e.tooth);
                        }
                        setStatus(e.status);
                        setInclinic(e.inclinic);
                        if (e.inclinic === "out") {
                            toothoutClinicList.push(e.tooth)
                        }
                    })
                    data.values.map((e) => {
                        setTreatmentType(e.treatmentType)
                        setTreatShow(e.treatmentType)
                        setLopiaType(e.lopiaType)
                        setStatus(e.status)
                        if (e.treatmentType === "معالجة لبية" && e.cannals.length > 0) {
                            setInfoooAddCannals(JSON.parse(e.cannals))
                        }
                        setChannal(e.channal)
                        setChannalNote(e.channalNote)
                        setChannalB(e.channalB)
                        setChannalP(e.channalP)
                        setChannalDB(e.channalDB)
                        setChannalMB2(e.channalMB2)
                        setChannalMB1(e.channalMB1)
                        setChannalMB(e.channalMB)
                        setChannalL(e.channalL)
                        setChannalML(e.channalML)
                        setChannalD(e.channalD)
                        setTarmimMaterial(e.tarmimMaterial)
                        setChooseClass(e.chooseClass)
                        setClass2Option(e.class2Option)
                        setTagGazafMadan(e.tagGazafMadan)
                        setTagGazafiKamel(e.tagGazafiKamel)
                        setGsrGazafMadan(e.GsrGazafMadan)
                        setGsrGazafiKamel(e.GsrGazafiKamel)
                        setTigan(e.Tigan)
                        setGsr(e.Gsr)
                        setTanzif(e.tanzif)
                        setTabeed(e.tabeed)
                        setKala(e.kala)
                        setNoteLopia(e.noteLopia)
                        setNoteVeneers(e.noteVeneers)
                        setNoteOutclinic(e.noteoutclinic)
                        setNoteTegan(e.noteTegan)
                        setNoteGsoor(e.noteGsoor)
                        setNoteKala(e.noteKala)
                        setNoteTanzif(e.noteTanzif)
                        setNoteTabeed(e.tabeed)
                        setNoteTarmim(e.noteTarmim)
                        setCost(e.cost)
                        setTooth(e.tooth)
                        setInclinic(e.inclinic)
                        if (e.gsrArray.includes(tooth)) {
                            gsrArray.push(tooth)
                        }
                        if (tooth === "1LD" || tooth === "1LDP") {
                            allgsrArray.push(41)
                        }
                        if (tooth === "2LD" || tooth === "2LDP") {
                            allgsrArray.push(42)
                        }
                        if (tooth === "3LD" || tooth === "3LDP") {
                            allgsrArray.push(43)
                        }
                        if (tooth === "4LD" || tooth === "4LDP") {
                            allgsrArray.push(44)
                        }
                        if (tooth === "5LD" || tooth === "5LDP") {
                            allgsrArray.push(45)
                        }
                        if (tooth === "6LD" || tooth === "6LDP") {
                            allgsrArray.push(46)
                        }
                        if (tooth === "7LD" || tooth === "7LDP") {
                            allgsrArray.push(47)
                        }
                        if (tooth === "8LD" || tooth === "8LDP") {
                            allgsrArray.push(48)
                        }
                        if (tooth === "1RD" || tooth === "1RDP") {
                            allgsrArray.push(31)
                        }
                        if (tooth === "2RD" || tooth === "2RDP") {
                            allgsrArray.push(32)
                        }
                        if (tooth === "3RD" || tooth === "3RDP") {
                            allgsrArray.push(33)
                        }
                        if (tooth === "4RD" || tooth === "4RDP") {
                            allgsrArray.push(34)
                        }
                        if (tooth === "5RD" || tooth === "5RDP") {
                            allgsrArray.push(35)
                        }
                        if (tooth === "6RD" || tooth === "6RDP") {
                            allgsrArray.push(36)
                        }
                        if (tooth === "7RD" || tooth === "7RDP") {
                            allgsrArray.push(37)
                        }
                        if (tooth === "8RD" || tooth === "8RDP") {
                            allgsrArray.push(38)
                        }
                        if (tooth === "1LU" || tooth === "1LUP") {
                            allgsrArray.push(11)
                        }
                        if (tooth === "2LU" || tooth === "2LUP") {
                            allgsrArray.push(12)
                        }
                        if (tooth === "3LU" || tooth === "3LUP") {
                            allgsrArray.push(13)
                        }
                        if (tooth === "4LU" || tooth === "4LUP") {
                            allgsrArray.push(14)
                        }
                        if (tooth === "5LU" || tooth === "5LUP") {
                            allgsrArray.push(15)
                        }
                        if (tooth === "6LU" || tooth === "6LUP") {
                            allgsrArray.push(16)
                        }
                        if (tooth === "7LU" || tooth === "7LUP") {
                            allgsrArray.push(17)
                        }
                        if (tooth === "8LU" || tooth === "8LUP") {
                            allgsrArray.push(18)
                        }
                        if (tooth === "1RU" || tooth === "1RUP") {
                            allgsrArray.push(21)
                        }
                        if (tooth === "2RU" || tooth === "2RUP") {
                            allgsrArray.push(22)
                        }
                        if (tooth === "3RU" || tooth === "3RUP") {
                            allgsrArray.push(23)
                        }
                        if (tooth === "4RU" || tooth === "4RUP") {
                            allgsrArray.push(24)
                        }
                        if (tooth === "5RU" || tooth === "5RUP") {
                            allgsrArray.push(25)
                        }
                        if (tooth === "6RU" || tooth === "6RUP") {
                            allgsrArray.push(26)
                        }
                        if (tooth === "7RU" || tooth === "7RUP") {
                            allgsrArray.push(27)
                        }
                        if (tooth === "8RU" || tooth === "8RUP") {
                            allgsrArray.push(28)
                        }
                        console.log(`from treatget ${e.tooth}`)
                    })
                }
                return setInfoooTreats(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };


    const getData = async () => {
        await fetch("https://dr-meladjouriah.fxcode.tech/php/getIdTreatSessions.php",
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                console.log(err)
            })
    };
    console.log(`infoocost  ${infoooCost}`)

    const handeleChannalNote = (e) => {
        setChannalNote(e.target.value)
    }

    const handeleChannalP = (e) => {
        setChannalP(e.target.value)
    }

    const handeleChannalML = (e) => {
        setChannalML(e.target.value)
    }

    const handeleChannalMB2 = (e) => {
        setChannalMB2(e.target.value)
    }

    const handeleChannalMB1 = (e) => {
        setChannalMB1(e.target.value)
    }

    const handeleChannalMB = (e) => {
        setChannalMB(e.target.value)
    }

    const handeleChannalL = (e) => {
        setChannalL(e.target.value)
    }

    const handeleChannalDB = (e) => {
        setChannalDB(e.target.value)
    }

    const handeleChannalD = (e) => {
        setChannalD(e.target.value)
    }

    const handeleChannalB = (e) => {
        setChannalB(e.target.value)
    }

    const handeleChannal = (e) => {
        setChannal(e.target.value)
    }

    const handeleLopia = (e) => {
        setLopiaType(e.target.value)
    }





    const handleCannalLength = (e) => {
        setCannalLength(e.target.value)
    }
    const handleCannalName = (e) => {
        setCannalName(e.target.value)
    }
    const handeleTreatment = (e) => {
        if (e.target.value === "add" || e.target.value === "قطع ذروة" || e.target.value === "قلع" || e.target.value === "تبييض" ||
            e.target.value === "تنظيف لثة وأسنان" || e.target.value === "Venners" || e.target.value === "تيجان" || e.target.value === "جسور"
            || e.target.value === "معالجة ترميمية" || e.target.value === "معالجة لبية"
        ) {
            setTreatmentType(e.target.value)
            setTreatShow(e.target.value)
            setopenAddToMenu(false)
            setTt("")
        }
        else {
            setTt(e.target.value)
            setTreatShow(e.target.value)
            setopenAddToMenu(false)
            setTreatmentType("")

        }
    }








    const handeleTarmimMaterial = (e) => {
        setTarmimMaterial(e.target.value)
    }

    const handeleClass = (e) => {
        setChooseClass(e.target.value)
        setClass2Option("")
    }

    const handeleClass2Option = (e) => {
        setClass2Option(e.target.value)
    }

    const handleTagGazafMadan = (e) => {
        setTagGazafMadan(e.target.value)
        setTagGazafiKamel("")
    }

    const handleTagGazafiKamel = (e) => {
        setTagGazafiKamel(e.target.value)
        setTagGazafMadan("")
    }

    const handleGsrGazafMadan = (e) => {
        setGsrGazafMadan(e.target.value)
        setGsrGazafiKamel("")
    }

    const handleGsrGazafiKamel = (e) => {
        setGsrGazafiKamel(e.target.value)
        setGsrGazafMadan("")
    }

    const handleTigan = (e) => {
        if (e.target.value === "خزف على معدن") {
            setTagGazafiKamel("")
        }
        if (e.target.value === "جسر خزفي كامل") {
            setTagGazafMadan("")
        }
        if (e.target.value === "معدن") {
            setTagGazafMadan("")
            setTagGazafiKamel("")
        }
        setTigan(e.target.value)
    }

    const handleGsr = (e) => {
        if (e.target.value === "خزف على معدن") {
            setGsrGazafiKamel("")
        }
        if (e.target.value === "جسر خزفي كامل") {
            setGsrGazafMadan("")
        }
        if (e.target.value === "معدن") {
            setGsrGazafMadan("")
            setGsrGazafiKamel("")
        }
        setGsr(e.target.value)
    }

    const handleTanzif = (e) => {
        setTanzif(e.target.value)
    }

    const handleTabeed = (e) => {
        setTabeed(e.target.value)
    }

    const handleKala = (e) => {
        setKala(e.target.value)
    }

    const handleNoteVeneers = (e) => {
        setNoteVeneers(e.target.value)
    }

    const handleNoteTegan = (e) => {
        setNoteTegan(e.target.value)
    }

    const handleNoteTarmim = (e) => {
        setNoteTarmim(e.target.value)
    }

    const handleNoteTanzif = (e) => {
        setNoteTanzif(e.target.value)
    }

    const handleNoteTabeed = (e) => {
        setNoteTabeed(e.target.value)
    }

    const handleNoteOutClinic = (e) => {
        setNoteOutclinic(e.target.value)
    }

    const handleNoteLopia = (e) => {
        setNoteLopia(e.target.value)
    }

    const handletype = (e) => {
        setType(e.target.value)
    }




    const handleTrmimType = (e) => {
        setTrmimtype(e.target.value)
        setTarmimMaterial(e.target.value)
    }

    const handleTiganType = (e) => {
        setTigantype(e.target.value)
        setTigan(e.target.value)
    }




    const handleNoteKala = (e) => {
        setNoteKala(e.target.value)
    }

    const handleNoteGsoor = (e) => {
        setNoteGsoor(e.target.value)
    }

    const handleInClinic = (e) => {
        setInclinic(e.target.value)
        if (e.target.value === "out") {
            setTreatmentType("")
            setGsrArray([])
        }
    }

    const handleCost = (e) => {
        setCost(e.target.value)
    }






    const handleOpenAddToMenu = () => {
        setTreatmentType("")
        setTt("")
        setTreatShow("")
        setopenAddToMenu(e => !e)
    }

    const handleDeleteGsr = () => {
        gsrArray.pop();
        allgsrArray.pop();
        setTooth("");
        if (tooth === "") {
            setTooth("-")
        }
        if (tooth === "-") {
            setTooth("")
        }
    }

    function deleteCannal(e) {
        e.preventDefault()
        infoooAddCannals.pop()
        if (v === "") {
            setV("-")
        }
        if (v === "-") {
            setV("")
        }
    }

    function addCannal(e) {
        e.preventDefault()
        let o = {}
        o.cannalName = `${cannalName}`;
        o.cannalLength = `${cannalLength}`;
        infoooAddCannals.push(o)
        //console.log(object);
        // cannals[`${cannalName}`]= `${cannalLength}`;
        if (v === "") {
            setV("-")
        }
        if (v === "-") {
            setV("")
        }
    }
    /*console.log(`cannalName ${cannalName}`)
    console.log(`cannalLength ${cannalLength}`)
    console.log(`object ${JSON.stringify(cannals)}`)
    console.log(`GsrArray ${gsrArray}`)
    console.log(`AllArray ${allgsrArray}`)
    console.log(`infoooAddCannals ${JSON.stringify(infoooAddCannals)}`)
    console.log(`Treatmenttype ${treatmentType}`)
    console.log(`Tt ${tt}`)
    console.log(`trmimMaterial ${tarmimMaterial}`)
    console.log(`TiganMaterial ${Tigan}`)
    console.log(`addmenu ${openAddToMenu}`)*/
    const cannalsItems = infoooAddCannals.map((e) => {
        return (
            <div>
                <label className="label-text">{e.cannalName}</label>
                <input type='text' className='form-control' value={e.cannalLength}></input>
            </div>
        )
    })







    const navigate = useNavigate();
    function back() {
        window.history.pushState({ id: 1 }, null, "GetSession")
        navigate("/GetSession", { replace: true });
    }
    useEffect(() => {
        getData();
        getTreatsData();
        getTreatType();
        getTiganMaterialType();
        getTrmimMaterialType();
    }, []);
    /*useEffect(()=>{
        window.history.pushState({id:1},null,"EditTreatments")
    },[])*/
    /*infooo.map((e) => {
        setSessionId(e.sessionid);
    })*/
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    console.log(`tooth ${tooth}`)
    return (
        <div>
            {auth == 1 && <div>{isDesktopOrLaptop && <div className="ground">
                <div className="fk">
                    <div className='h'>
                        {status == "دائم" && <div className='hh'>
                            <img className={gsrArray.includes("8LU") ? "g" : tooth === "8LU" ? "b" : "t"} src={t8u} width="30px"></img>
                            <img className={gsrArray.includes("7LU") ? "g" : tooth === "7LU" ? "b" : "t"} src={t7u} width="30px" ></img>
                            <img className={gsrArray.includes("6LU") ? "g" : tooth === "6LU" ? "b" : "t"} src={t6u} width="30px" ></img>
                            <img className={gsrArray.includes("5LU") ? "g" : tooth === "5LU" ? "b" : "t"} src={t5u} width="30px" ></img>
                            <img className={gsrArray.includes("4LU") ? "g" : tooth === "4LU" ? "b" : "t"} src={t4u} width="30px" ></img>
                            <img className={gsrArray.includes("3LU") ? "g" : tooth === "3LU" ? "b" : "t"} src={t3u} width="30px" ></img>
                            <img className={gsrArray.includes("2LU") ? "g" : tooth === "2LU" ? "b" : "t"} src={t2u} width="30px" ></img>
                            <img className={gsrArray.includes("1LU") ? "g" : tooth === "1LU" ? "b" : "t"} src={t1u} width="30px" ></img>
                        </div>}
                        {status == "دائم" && <div className='hh'>
                            <img className={gsrArray.includes("1RU") ? "g" : tooth === "1RU" ? "b" : "t"} src={t1u} width="30px" ></img>
                            <img className={gsrArray.includes("2RU") ? "g" : tooth === "2RU" ? "b" : "t"} src={t2u} width="30px" ></img>
                            <img className={gsrArray.includes("3RU") ? "g" : tooth === "3RU" ? "b" : "t"} src={t3u} width="30px" ></img>
                            <img className={gsrArray.includes("4RU") ? "g" : tooth === "4RU" ? "b" : "t"} src={t4u} width="30px" ></img>
                            <img className={gsrArray.includes("5RU") ? "g" : tooth === "5RU" ? "b" : "t"} src={t5u} width="30px" ></img>
                            <img className={gsrArray.includes("6RU") ? "g" : tooth === "6RU" ? "b" : "t"} src={t6u} width="30px" ></img>
                            <img className={gsrArray.includes("7RU") ? "g" : tooth === "7RU" ? "b" : "t"} src={t7u} width="30px" ></img>
                            <img className={gsrArray.includes("8RU") ? "g" : tooth === "8RU" ? "b" : "t"} src={t8u} width="30px" ></img>
                        </div>}
                        {status == "مؤقت" && <div className='hh'>
                            <img className={tooth === "5LUT" ? "b" : "t"} src={t5tu} width="30px" ></img>
                            <img className={tooth === "4LUT" ? "b" : "t"} src={t4tu} width="30px" ></img>
                            <img className={tooth === "3LUT" ? "b" : "t"} src={t3tu} width="30px" ></img>
                            <img className={tooth === "2LUT" ? "b" : "t"} src={t2tu} width="30px" ></img>
                            <img className={tooth === "1LUT" ? "b" : "t"} src={t1tu} width="30px" ></img>
                        </div>}
                        {status == "مؤقت" && <div className='hh'>
                            <img className={tooth === "1RUT" ? "b" : "t"} src={t1tu} width="30px" ></img>
                            <img className={tooth === "2RUT" ? "b" : "t"} src={t2tu} width="30px" ></img>
                            <img className={tooth === "3RUT" ? "b" : "t"} src={t3tu} width="30px" ></img>
                            <img className={tooth === "4RUT" ? "b" : "t"} src={t4tu} width="30px" ></img>
                            <img className={tooth === "5RUT" ? "b" : "t"} src={t5tu} width="30px"></img>
                        </div>}
                    </div>
                    <div className="l">
                        {status == "دائم" && <div className='hh'>
                            <img className={gsrArray.includes("8LD") ? "g" : tooth === "8LD" ? "b" : "t"} src={t8d} width="30px"></img>
                            <img className={gsrArray.includes("7LD") ? "g" : tooth === "7LD" ? "b" : "t"} src={t7d} width="30px" ></img>
                            <img className={gsrArray.includes("6LD") ? "g" : tooth === "6LD" ? "b" : "t"} src={t6d} width="30px" ></img>
                            <img className={gsrArray.includes("5LD") ? "g" : tooth === "5LD" ? "b" : "t"} src={t5d} width="30px" ></img>
                            <img className={gsrArray.includes("4LD") ? "g" : tooth === "4LD" ? "b" : "t"} src={t4d} width="30px" ></img>
                            <img className={gsrArray.includes("3LD") ? "g" : tooth === "3LD" ? "b" : "t"} src={t3d} width="30px" ></img>
                            <img className={gsrArray.includes("2LD") ? "g" : tooth === "2LD" ? "b" : "t"} src={t2d} width="30px" ></img>
                            <img className={gsrArray.includes("1LD") ? "g" : tooth === "1LD" ? "b" : "t"} src={t1d} width="30px"></img>
                        </div>}
                        {status == "دائم" && <div className='hh'>
                            <img className={gsrArray.includes("1RD") ? "g" : tooth === "1RD" ? "b" : "t"} src={t1d} width="30px" ></img>
                            <img className={gsrArray.includes("2RD") ? "g" : tooth === "2RD" ? "b" : "t"} src={t2d} width="30px"></img>
                            <img className={gsrArray.includes("3RD") ? "g" : tooth === "3RD" ? "b" : "t"} src={t3d} width="30px"></img>
                            <img className={gsrArray.includes("4RD") ? "g" : tooth === "4RD" ? "b" : "t"} src={t4d} width="30px" ></img>
                            <img className={gsrArray.includes("5RD") ? "g" : tooth === "5RD" ? "b" : "t"} src={t5d} width="30px" ></img>
                            <img className={gsrArray.includes("6RD") ? "g" : tooth === "6RD" ? "b" : "t"} src={t6d} width="30px" ></img>
                            <img className={gsrArray.includes("7RD") ? "g" : tooth === "7RD" ? "b" : "t"} src={t7d} width="30px" ></img>
                            <img className={gsrArray.includes("8RD") ? "g" : tooth === "8RD" ? "b" : "t"} src={t8d} width="30px"></img>
                        </div>}
                    </div>
                    <div className="l">
                        {status == "مؤقت" && <div className='hh'>
                            <img className={tooth === "5LDT" ? "b" : "t"} src={t5td} width="30px"></img>
                            <img className={tooth === "4LDT" ? "b" : "t"} src={t4td} width="30px" ></img>
                            <img className={tooth === "3LDT" ? "b" : "t"} src={t3td} width="30px" ></img>
                            <img className={tooth === "2LDT" ? "b" : "t"} src={t2td} width="30px"></img>
                            <img className={tooth === "1LDT" ? "b" : "t"} src={t1td} width="30px" ></img>
                        </div>}
                        {status == "مؤقت" && <div className='hh'>
                            <img className={tooth === "1RDT" ? "b" : "t"} src={t1td} width="30px" ></img>
                            <img className={tooth === "2RDT" ? "b" : "t"} src={t2td} width="30px" ></img>
                            <img className={tooth === "3RDT" ? "b" : "t"} src={t3td} width="30px" ></img>
                            <img className={tooth === "4RDT" ? "b" : "t"} src={t4td} width="30px" ></img>
                            <img className={tooth === "5RDT" ? "b" : "t"} src={t5td} width="30px" ></img>
                        </div>}
                    </div>
                    {status == "مختلط" && <div className='hh-both'>
                        <div className="adult">
                            <div className='h'>
                                {status == "مختلط" && <div className='hh'>
                                    <img className={tooth === "8LUP" ? "b" : "t"} src={t8u} width="30px"></img>
                                    <img className={tooth === "7LUP" ? "b" : "t"} src={t7u} width="30px" ></img>
                                    <img className={tooth === "6LUP" ? "b" : "t"} src={t6u} width="30px" ></img>
                                    <img className={tooth === "5LUP" ? "b" : "t"} src={t5u} width="30px"></img>
                                    <img className={tooth === "4LUP" ? "b" : "t"} src={t4u} width="30px" ></img>
                                    <img className={tooth === "3LUP" ? "b" : "t"} src={t3u} width="30px" ></img>
                                    <img className={tooth === "2LUP" ? "b" : "t"} src={t2u} width="30px"></img>
                                    <img className={tooth === "1LUP" ? "b" : "t"} src={t1u} width="30px"></img>
                                </div>}
                                {status == "مختلط" && <div className='hh'>
                                    <img className={tooth === "1RUP" ? "b" : "t"} src={t1u} width="30px"></img>
                                    <img className={tooth === "2RUP" ? "b" : "t"} src={t2u} width="30px"></img>
                                    <img className={tooth === "3RUP" ? "b" : "t"} src={t3u} width="30px" ></img>
                                    <img className={tooth === "4RUP" ? "b" : "t"} src={t4u} width="30px" ></img>
                                    <img className={tooth === "5RUP" ? "b" : "t"} src={t5u} width="30px" ></img>
                                    <img className={tooth === "6RUP" ? "b" : "t"} src={t6u} width="30px" ></img>
                                    <img className={tooth === "7RUP" ? "b" : "t"} src={t7u} width="30px" ></img>
                                    <img className={tooth === "8RUP" ? "b" : "t"} src={t8u} width="30px" ></img>
                                </div>}
                            </div>
                            <div className="l">
                                {status == "مختلط" && <div className='hh'>
                                    <img className={tooth === "8LDP" ? "b" : "t"} src={t8d} width="30px" ></img>
                                    <img className={tooth === "7LDP" ? "b" : "t"} src={t7d} width="30px" ></img>
                                    <img className={tooth === "6LDP" ? "b" : "t"} src={t6d} width="30px" ></img>
                                    <img className={tooth === "5LDP" ? "b" : "t"} src={t5d} width="30px" ></img>
                                    <img className={tooth === "4LDP" ? "b" : "t"} src={t4d} width="30px" ></img>
                                    <img className={tooth === "3LDP" ? "b" : "t"} src={t3d} width="30px" ></img>
                                    <img className={tooth === "2LDP" ? "b" : "t"} src={t2d} width="30px" ></img>
                                    <img className={tooth === "1LDP" ? "b" : "t"} src={t1d} width="30px" ></img>
                                </div>}
                                {status == "مختلط" && <div className='hh'>
                                    <img className={tooth === "1RDP" ? "b" : "t"} src={t1d} width="30px" ></img>
                                    <img className={tooth === "2RDP" ? "b" : "t"} src={t2d} width="30px" ></img>
                                    <img className={tooth === "3RDP" ? "b" : "t"} src={t3d} width="30px" ></img>
                                    <img className={tooth === "4RDP" ? "b" : "t"} src={t4d} width="30px" ></img>
                                    <img className={tooth === "5RDP" ? "b" : "t"} src={t5d} width="30px" ></img>
                                    <img className={tooth === "6RDP" ? "b" : "t"} src={t6d} width="30px" ></img>
                                    <img className={tooth === "7RDP" ? "b" : "t"} src={t7d} width="30px" ></img>
                                    <img className={tooth === "8RDP" ? "b" : "t"} src={t8d} width="30px" ></img>
                                </div>}
                            </div>
                        </div>
                        <div className="kids">
                            <div className="h">
                                {status == "مختلط" && <div className='hh'>
                                    <img className={tooth === "5LUTP" ? "b" : "t"} src={t5tu} width="30px" ></img>
                                    <img className={tooth === "4LUTP" ? "b" : "t"} src={t4tu} width="30px" ></img>
                                    <img className={tooth === "3LUTP" ? "b" : "t"} src={t3tu} width="30px" ></img>
                                    <img className={tooth === "2LUTP" ? "b" : "t"} src={t2tu} width="30px" ></img>
                                    <img className={tooth === "1LUTP" ? "b" : "t"} src={t1tu} width="30px" ></img>
                                </div>}
                                {status == "مختلط" && <div className='hh'>
                                    <img className={tooth === "1RUTP" ? "b" : "t"} src={t1tu} width="30px" ></img>
                                    <img className={tooth === "2RUTP" ? "b" : "t"} src={t2tu} width="30px" ></img>
                                    <img className={tooth === "3RUTP" ? "b" : "t"} src={t3tu} width="30px" ></img>
                                    <img className={tooth === "4RUTP" ? "b" : "t"} src={t4tu} width="30px" ></img>
                                    <img className={tooth === "5RUTP" ? "b" : "t"} src={t5tu} width="30px" ></img>
                                </div>}
                            </div>
                            <div className="l">
                                {status == "مختلط" && <div className='hh'>
                                    <img className={tooth === "5LDTP" ? "b" : "t"} src={t5td} width="30px" ></img>
                                    <img className={tooth === "4LDTP" ? "b" : "t"} src={t4td} width="30px" ></img>
                                    <img className={tooth === "3LDTP" ? "b" : "t"} src={t3td} width="30px" ></img>
                                    <img className={tooth === "2LDTP" ? "b" : "t"} src={t2td} width="30px" ></img>
                                    <img className={tooth === "1LDTP" ? "b" : "t"} src={t1td} width="30px" ></img>
                                </div>}
                                {status == "مختلط" && <div className='hh'>
                                    <img className={tooth === "1RDTP" ? "b" : "t"} src={t1td} width="30px" ></img>
                                    <img className={tooth === "2RDTP" ? "b" : "t"} src={t2td} width="30px" ></img>
                                    <img className={tooth === "3RDTP" ? "b" : "t"} src={t3td} width="30px" ></img>
                                    <img className={tooth === "4RDTP" ? "b" : "t"} src={t4td} width="30px" ></img>
                                    <img className={tooth === "5RDTP" ? "b" : "t"} src={t5td} width="30px" ></img>
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>
                <div className='sel'>
                    <button className="btn btn-dark btn-add" onClick={back}><IoMdArrowRoundBack /></button>
                    <h2 className="label-text-title">:تعديل معالجة</h2>
                    <label className="label-text">:مكان المعالجة</label>
                    <select typeof="select" className="form-select f-ss" value={inclinic} onChange={handleInClinic}>
                        <option value="-">-</option>
                        <option value="in">في العيادة</option>
                        <option value="out">خارج العيادة</option>
                    </select>
                    {inclinic === "out" && <div>
                        <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات المعالجة' value={noteoutclinic} onChange={handleNoteOutClinic}></textarea>
                    </div>}
                    {inclinic === "in" && <div className="treat-details">
                        {status && <div className="treatstype-container">
                            <label className="label-text">:نوع المعالجة</label>
                            <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%", gap: "10px" }}>
                                {!openAddToMenu && <button className="btn btn-outline-light" onClick={handleOpenAddToMenu}><div style={{ display: "flex", alignItems: "center" }}><IoIosArrowDropdown />إضافة</div></button>}
                                {openAddToMenu && <button className="btn btn-outline-light" onClick={handleOpenAddToMenu}><div style={{ display: "flex", alignItems: "center" }}><IoIosArrowDropup />+</div></button>}
                                <select typeof="select" className="form-select f-ss" value={treatShow} onChange={handeleTreatment}>
                                    <option value="-">-</option>
                                    <option value="معالجة لبية">معالجة لبية</option>
                                    <option value="معالجة ترميمية">معالجة ترميمية</option>
                                    <option value="تيجان">تيجان</option>
                                    <option value="Venners">Veneers</option>
                                    <option value="جسور">جسور</option>
                                    <option value="تنظيف لثة وأسنان">تنظيف لثة وأسنان</option>
                                    <option value="تبييض">تبييض</option>
                                    <option value="قلع">قلع</option>
                                    <option value="قطع ذروة">قطع ذروة</option>
                                    {treatmentsArray.map((e) => {
                                        return (
                                            <option value={e}>{e}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {openAddToMenu &&
                                <div className="addTreatType-container">
                                    <div className="addTreatType-mobile">
                                        <input type='text' className='form-control' placeholder='نوع المعالجة المضافة' onChange={handletype}></input>
                                        <button className="btn btn-success add-menu-btn" onClick={addTreatmentType}>إضافة إلى القائمة</button>
                                    </div>
                                    {addTreatMessage && <div className="label-text">{addTreatMessage}</div>}
                                </div>
                            }
                        </div>}
                        {treatmentType === "Venners" && <div className="treatstype-container-add">
                            <div>
                                <textarea type='text' className='form-control f-ssss' placeholder='Venners ملاحظات' value={noteVeneers} onChange={handleNoteVeneers}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "معالجة لبية" && <div className="treatstype-container-add">
                            {treatmentType === "معالجة لبية" && <div className="treatstype-container">
                                <label className="label-text">نوع المعالجة اللبية</label>
                                <select typeof="select" className="form-select f-s" value={lopiaType} onChange={handeleLopia}>
                                    <option value="-">-</option>
                                    <option value="معالجة لبية كاملة"> معالجة لبية كاملة</option>
                                    <option value="إعادة معالجة لبية">إعادة معالجة لبية</option>
                                    <option value="بتر لب">بتر لب</option>
                                    <option value="تغطية لبية مباشرة">تغطية لبية مباشرة</option>
                                </select>
                            </div>}
                            {treatmentType === "معالجة لبية" && lopiaType === "معالجة لبية كاملة" && <div>
                                <label className="label-text">أطوال القنوات</label>
                                <form className='formm'>
                                    {(tooth === "1LU" || tooth === "2LU" || tooth === "3LU" || tooth === "3LD" || tooth === "4LD"
                                        || tooth === "5LD" || tooth === "1RU" || tooth === "2RU" || tooth === "3RU" || tooth === "3RD"
                                        || tooth === "4RD" || tooth === "5RD" || tooth === "1LUP" || tooth === "1LUT" || tooth === "2LUT" || tooth === "3LUT" || tooth === "1LUTP" || tooth === "2LUTP" || tooth === "3LUTP"
                                        || tooth === "2LUP" || tooth === "3LUP" || tooth === "3LDP" || tooth === "4LDP" || tooth === "1LDT" || tooth === "2LDT" || tooth === "3LDT" || tooth === "1LDTP" || tooth === "2LDTP" || tooth === "3LDTP"
                                        || tooth === "5LDP" || tooth === "1RUP" || tooth === "2RUP" || tooth === "3RUP" || tooth === "3RDP" || tooth === "1RUT" || tooth === "2RUT" || tooth === "3RUT" || tooth === "1RUTP" || tooth === "2RUTP" || tooth === "3RUTP"
                                        || tooth === "4RDP" || tooth === "5RDP" || tooth === "1RDT" || tooth === "2RDT" || tooth === "3RDT" || tooth === "1RDTP" || tooth === "2RDTP" || tooth === "3RDTP") &&
                                        <div className="channals-names"><label className="label-text">القناة</label>
                                            <input type='text' className='form-control f-s' placeholder='طول القناة' value={channal} onChange={handeleChannal}></input></div>}
                                    {(tooth === "5LU" || tooth === "4LU" || tooth === "1LD" || tooth === "2LD"
                                        || tooth === "5RU" || tooth === "4RU" || tooth === "1RD" || tooth === "2RD"
                                        || tooth === "5LUP" || tooth === "4LUP" || tooth === "1LDP" || tooth === "2LDP"
                                        || tooth === "5RUP" || tooth === "4RUP" || tooth === "1RDP" || tooth === "2RDP") &&
                                        <div className="channals-names"><label className="label-text">B:</label>
                                            <input type='text' className='form-control f-s' placeholder='B طول القناة' value={channalB} onChange={handeleChannalB}></input></div>}
                                    {(tooth === "5LU" || tooth === "4LU" || tooth === "6LU" || tooth === "7LU"
                                        || tooth === "8LU" || tooth === "5RU" || tooth === "4RU" || tooth === "6RU"
                                        || tooth === "7RU" || tooth === "8RU"
                                        || tooth === "5LUP" || tooth === "4LUP" || tooth === "6LUP" || tooth === "7LUP"
                                        || tooth === "8LUP" || tooth === "5RUP" || tooth === "4RUP" || tooth === "6RUP"
                                        || tooth === "7RUP" || tooth === "8RUP" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">P:</label>
                                            <input type='text' className='form-control f-s' placeholder='P طول القناة' value={channalP} onChange={handeleChannalP}></input></div>}
                                    {(tooth === "6LD" || tooth === "7LD" || tooth === "8LD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP") &&
                                        <div className="channals-names"><label className="label-text">D:</label>
                                            <input type='text' className='form-control f-s' placeholder='D طول القناة' value={channalD} onChange={handeleChannalD}></input></div>}
                                    {(tooth === "6LD" || tooth === "7LD" || tooth === "8LD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP") &&
                                        <div className="channals-names"><label className="label-text">ML:</label>
                                            <input type='text' className='form-control f-s' placeholder='ML طول القناة' value={channalML} onChange={handeleChannalML}></input></div>}
                                    {(tooth === "6LU" || tooth === "7LU" || tooth === "8LU" || tooth === "6LR" || tooth === "7LR" || tooth === "8LR"
                                        || tooth === "6LUP" || tooth === "7LUP" || tooth === "8LUP" || tooth === "7RU" || tooth === "7RUP" || tooth === "6RUP" || tooth === "6RU" || tooth === "8RUP" || tooth === "8RU" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">DB:</label>
                                            <input type='text' className='form-control f-s' placeholder='DB طول القناة' value={channalDB} onChange={handeleChannalDB}></input></div>}
                                    {(tooth === "6LU" || tooth === "6RU" || tooth === "6LD" || tooth === "6RD" || tooth === "6LUP" || tooth === "6RUP" || tooth === "6LDP" || tooth === "6RDP" || tooth === "7RU" || tooth === "7RUP" || tooth === "7LU" || tooth === "7LUP" || tooth === "7RD" || tooth === "7RDP" || tooth === "7LD" || tooth === "7LDP") &&
                                        <div className="channals-names"><label className="label-text">MB1:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB1 طول القناة' value={channalMB1} onChange={handeleChannalMB1}></input></div>}
                                    {(tooth === "6LU" || tooth === "6RU" || tooth === "6LD" || tooth === "6RD" || tooth === "6LUP" || tooth === "6RUP" || tooth === "6LDP" || tooth === "6RDP" || tooth === "7RU" || tooth === "7RUP" || tooth === "7LU" || tooth === "7LUP" || tooth === "7RD" || tooth === "7RDP" || tooth === "7LD" || tooth === "7LDP") &&
                                        <div className="channals-names"><label className="label-text">MB2:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB2 طول القناة' value={channalMB2} onChange={handeleChannalMB2}></input></div>}
                                    {(tooth === "8LU" || tooth === "8LD"
                                        || tooth === "8RU" || tooth === "8RD"
                                        || tooth === "8LUP" || tooth === "8LDP" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "8RUP" || tooth === "8RDP" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">MB:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB طول القناة' value={channalMB} onChange={handeleChannalMB}></input></div>}
                                    {(tooth === "1LD" || tooth === "2LD" || tooth === "1RD" || tooth === "2RD"
                                        || tooth === "1LDP" || tooth === "2LDP" || tooth === "1RDP" || tooth === "2RDP") &&
                                        <div className="channals-names"><label className="label-text">L:</label>
                                            <input type='text' className='form-control f-s' placeholder='L طول القناة' value={channalL} onChange={handeleChannalL}></input></div>}
                                    {<input type='text' className='form-control f-s' placeholder='ملاحظات للقنوات' onChange={handeleChannalNote}></input>}
                                    <div className="cannalAdd">
                                        <input type='text' className='form-control' placeholder='طول القناة' onChange={handleCannalLength}></input>
                                        <input type='text' className='form-control' placeholder='اسم القناة' onChange={handleCannalName}></input>
                                        <button className="btn btn-success" disabled={!cannalName && !cannalLength} onClick={addCannal}>+</button>
                                    </div>
                                    {addcannalMessage && <div className="label-text">{addcannalMessage}</div>}
                                    {infoooAddCannals.length > 0 && <div>
                                        <div className="label-text">:القنوات المضافة</div>
                                        {cannalsItems}
                                        <button className="btn btn-danger" onClick={deleteCannal}>- حذف قناة</button>
                                    </div>}
                                </form>
                            </div>}
                            {treatmentType === "معالجة لبية" && lopiaType === "إعادة معالجة لبية" && <div>
                                <label className="label-text">أطوال القنوات</label>
                                <form className='formm'>
                                    {(tooth === "1LU" || tooth === "2LU" || tooth === "3LU" || tooth === "3LD" || tooth === "4LD"
                                        || tooth === "5LD" || tooth === "1RU" || tooth === "2RU" || tooth === "3RU" || tooth === "3RD"
                                        || tooth === "4RD" || tooth === "5RD" || tooth === "1LUP" || tooth === "1LUT" || tooth === "2LUT" || tooth === "3LUT" || tooth === "1LUTP" || tooth === "2LUTP" || tooth === "3LUTP"
                                        || tooth === "2LUP" || tooth === "3LUP" || tooth === "3LDP" || tooth === "4LDP" || tooth === "1LDT" || tooth === "2LDT" || tooth === "3LDT" || tooth === "1LDTP" || tooth === "2LDTP" || tooth === "3LDTP"
                                        || tooth === "5LDP" || tooth === "1RUP" || tooth === "2RUP" || tooth === "3RUP" || tooth === "3RDP" || tooth === "1RUT" || tooth === "2RUT" || tooth === "3RUT" || tooth === "1RUTP" || tooth === "2RUTP" || tooth === "3RUTP"
                                        || tooth === "4RDP" || tooth === "5RDP" || tooth === "1RDT" || tooth === "2RDT" || tooth === "3RDT" || tooth === "1RDTP" || tooth === "2RDTP" || tooth === "3RDTP") &&
                                        <div className="channals-names"><label className="label-text">القناة</label>
                                            <input type='text' className='form-control f-s' placeholder='طول القناة' value={channal} onChange={handeleChannal}></input></div>}
                                    {(tooth === "5LU" || tooth === "4LU" || tooth === "1LD" || tooth === "2LD"
                                        || tooth === "5RU" || tooth === "4RU" || tooth === "1RD" || tooth === "2RD"
                                        || tooth === "5LUP" || tooth === "4LUP" || tooth === "1LDP" || tooth === "2LDP"
                                        || tooth === "5RUP" || tooth === "4RUP" || tooth === "1RDP" || tooth === "2RDP") &&
                                        <div className="channals-names"><label className="label-text">B:</label>
                                            <input type='text' className='form-control f-s' placeholder='B طول القناة' value={channalB} onChange={handeleChannalB}></input></div>}
                                    {(tooth === "5LU" || tooth === "4LU" || tooth === "6LU" || tooth === "7LU"
                                        || tooth === "8LU" || tooth === "5RU" || tooth === "4RU" || tooth === "6RU"
                                        || tooth === "7RU" || tooth === "8RU"
                                        || tooth === "5LUP" || tooth === "4LUP" || tooth === "6LUP" || tooth === "7LUP"
                                        || tooth === "8LUP" || tooth === "5RUP" || tooth === "4RUP" || tooth === "6RUP"
                                        || tooth === "7RUP" || tooth === "8RUP" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">P:</label>
                                            <input type='text' className='form-control f-s' placeholder='P طول القناة' value={channalP} onChange={handeleChannalP}></input></div>}
                                    {(tooth === "6LD" || tooth === "7LD" || tooth === "8LD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP") &&
                                        <div className="channals-names"><label className="label-text">D:</label>
                                            <input type='text' className='form-control f-s' placeholder='D طول القناة' value={channalD} onChange={handeleChannalD}></input></div>}
                                    {(tooth === "6LD" || tooth === "7LD" || tooth === "8LD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP") &&
                                        <div className="channals-names"><label className="label-text">ML:</label>
                                            <input type='text' className='form-control f-s' placeholder='ML طول القناة' value={channalML} onChange={handeleChannalML}></input></div>}
                                    {(tooth === "6LU" || tooth === "7LU" || tooth === "8LU" || tooth === "6LR" || tooth === "7LR" || tooth === "8LR"
                                        || tooth === "6LUP" || tooth === "7LUP" || tooth === "8LUP" || tooth === "7RU" || tooth === "7RUP" || tooth === "6RUP" || tooth === "6RU" || tooth === "8RUP" || tooth === "8RU" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">DB:</label>
                                            <input type='text' className='form-control f-s' placeholder='DB طول القناة' value={channalDB} onChange={handeleChannalDB}></input></div>}
                                    {(tooth === "6LU" || tooth === "6RU" || tooth === "6LD" || tooth === "6RD" || tooth === "6LUP" || tooth === "6RUP" || tooth === "6LDP" || tooth === "6RDP" || tooth === "7RU" || tooth === "7RUP" || tooth === "7LU" || tooth === "7LUP" || tooth === "7RD" || tooth === "7RDP" || tooth === "7LD" || tooth === "7LDP") &&
                                        <div className="channals-names"><label className="label-text">MB1:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB1 طول القناة' value={channalMB1} onChange={handeleChannalMB1}></input></div>}
                                    {(tooth === "6LU" || tooth === "6RU" || tooth === "6LD" || tooth === "6RD" || tooth === "6LUP" || tooth === "6RUP" || tooth === "6LDP" || tooth === "6RDP" || tooth === "7RU" || tooth === "7RUP" || tooth === "7LU" || tooth === "7LUP" || tooth === "7RD" || tooth === "7RDP" || tooth === "7LD" || tooth === "7LDP") &&
                                        <div className="channals-names"><label className="label-text">MB2:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB2 طول القناة' value={channalMB2} onChange={handeleChannalMB2}></input></div>}
                                    {(tooth === "8LU" || tooth === "8LD"
                                        || tooth === "8RU" || tooth === "8RD"
                                        || tooth === "8LUP" || tooth === "8LDP" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "8RUP" || tooth === "8RDP" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">MB:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB طول القناة' value={channalMB} onChange={handeleChannalMB}></input></div>}
                                    {(tooth === "1LD" || tooth === "2LD" || tooth === "1RD" || tooth === "2RD"
                                        || tooth === "1LDP" || tooth === "2LDP" || tooth === "1RDP" || tooth === "2RDP") &&
                                        <div className="channals-names"><label className="label-text">L:</label>
                                            <input type='text' className='form-control f-s' placeholder='L طول القناة' value={channalL} onChange={handeleChannalL}></input></div>}
                                    {<input type='text' className='form-control f-s' placeholder='ملاحظات للقنوات' onChange={handeleChannalNote}></input>}
                                    <div className="cannalAdd">
                                        <input type='text' className='form-control' placeholder='طول القناة' onChange={handleCannalLength}></input>
                                        <input type='text' className='form-control' placeholder='اسم القناة' onChange={handleCannalName}></input>
                                        <button className="btn btn-success" disabled={!cannalName && !cannalLength} onClick={addCannal}>+</button>
                                    </div>
                                    {addcannalMessage && <div className="label-text">{addcannalMessage}</div>}
                                    {infoooAddCannals.length > 0 && <div>
                                        <div className="label-text">:القنوات المضافة</div>
                                        {cannalsItems}
                                        <button className="btn btn-danger" onClick={deleteCannal}>- حذف قناة</button>
                                    </div>}
                                </form>
                            </div>}
                        </div>}
                        {treatmentType === "معالجة ترميمية" && <div className="treatstype-container-add">
                            <div className="treatstype-container">
                                <label className="label-text">نوع المادة الترميمية</label>
                                <select typeof="select" className="form-select f-ss" value={tarmimMaterial} onChange={handeleTarmimMaterial}>
                                    <option value="-">-</option>
                                    <option value="أملغم">أملغم</option>
                                    <option value="كومبوزيت">كومبوزيت</option>
                                    {trmimMatreialTypeArray.map((e) => {
                                        return (
                                            <option value={e}>{e}</option>
                                        )
                                    })}
                                </select>
                                <label className="label-text">:إضافة مادة</label>
                                <div className="addTreatType">
                                    <input type='text' className='form-control' placeholder='نوع المادة المضافة' onChange={handleTrmimType}></input>
                                    <button className="btn btn-success add-menu-btn" onClick={addTrmimMatrialType}>إضافة إلى القائمة</button>
                                </div>
                                {addTrmimMaterialMessage && <div className="label-text">{addTrmimMaterialMessage}</div>}

                                <label className="label-text">Class نوع ال</label>
                                <select className="form-select f-ss" value={chooseClass} onChange={handeleClass}>
                                    <option value="-">-</option>
                                    {(tooth === "1LU" || tooth === "2LU" || tooth === "3LU" || tooth === "1LD"
                                        || tooth === "2LD" || tooth === "3LD" || tooth === "1RU" || tooth === "2RU" || tooth === "2RD" || tooth === "1RD"
                                        || tooth === "3RU" || tooth === "3RD"
                                        || tooth === "1LUP" || tooth === "2LUP" || tooth === "3LUP" || tooth === "1LDP"
                                        || tooth === "2LDP" || tooth === "3LDP" || tooth === "1RUP" || tooth === "2RUP" || tooth === "3RUP" || tooth === "3RDP" || tooth === "2RDP" || tooth === "1RDP") && <option value="Class(3)">Class(3)</option>}
                                    {(tooth === "1LU" || tooth === "2LU" || tooth === "1LD" || tooth === "2LD"
                                        || tooth === "3LU" || tooth === "3LD" || tooth === "1RU" || tooth === "2RU" || tooth === "2RD" || tooth === "1RD"
                                        || tooth === "3RU" || tooth === "3RD"
                                        || tooth === "1LUP" || tooth === "2LUP" || tooth === "3LUP" || tooth === "1LDP"
                                        || tooth === "2LDP" || tooth === "3LDP" || tooth === "1RUP" || tooth === "2RUP" || tooth === "3RUP" || tooth === "3RDP" || tooth === "2RDP" || tooth === "1RDP") && <option value="Class(4)">Class(4)</option>}
                                    <option value="Class(5)">Class(5)</option>
                                    {(tooth === "4LU" || tooth === "5LU" || tooth === "6LU" || tooth === "7LU" || tooth === "8LU"
                                        || tooth === "4RU" || tooth === "5RU" || tooth === "6RU" || tooth === "7RU" || tooth === "8RU"
                                        || tooth === "4LD" || tooth === "5LD" || tooth === "6LD" || tooth === "7LD" || tooth === "8LD"
                                        || tooth === "4RD" || tooth === "5RD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD"
                                        || tooth === "4LUP" || tooth === "5LUP" || tooth === "6LUP" || tooth === "7LUP" || tooth === "8LUP"
                                        || tooth === "4RUP" || tooth === "5RUP" || tooth === "6RUP" || tooth === "7RUP" || tooth === "8RUP"
                                        || tooth === "4LDP" || tooth === "5LDP" || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP"
                                        || tooth === "4RDP" || tooth === "5RDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP") && <option value="Class(1)">Class(1)</option>}
                                    {(tooth === "4LU" || tooth === "5LU" || tooth === "6LU" || tooth === "7LU" || tooth === "8LU"
                                        || tooth === "4RU" || tooth === "5RU" || tooth === "6RU" || tooth === "7RU" || tooth === "8RU"
                                        || tooth === "4LD" || tooth === "5LD" || tooth === "6LD" || tooth === "7LD" || tooth === "8LD"
                                        || tooth === "4RD" || tooth === "5RD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD"
                                        || tooth === "4LUP" || tooth === "5LUP" || tooth === "6LUP" || tooth === "7LUP" || tooth === "8LUP"
                                        || tooth === "4RUP" || tooth === "5RUP" || tooth === "6RUP" || tooth === "7RUP" || tooth === "8RUP"
                                        || tooth === "4LDP" || tooth === "5LDP" || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP"
                                        || tooth === "4RDP" || tooth === "5RDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP") && <option value="Class(2)">Class(2)</option>}
                                    {<input type='text' className='form-control' placeholder='ملاحظات' value={channalNote} onChange={handeleChannalNote}></input>}
                                </select>
                                {chooseClass === "Class(2)" &&
                                    <div>
                                        <label className="label-text">Class(2) نوع</label>
                                        <select className="form-select f-s" value={class2Option} onChange={handeleClass2Option}>
                                            <option value="-">-</option>
                                            <option value="MO">MO</option>
                                            <option value="DO">DO</option>
                                            <option value="MOD">MOD</option>
                                        </select>
                                    </div>}
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للمعالجة الترميمية' value={noteTarmim} onChange={handleNoteTarmim}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "تيجان" && <div className="treatstype-container-add">
                            <div className="treatstype-container">
                                <label className="label-text">نوع مادة التاج</label>
                                <select typeof="select" className="form-select f-ss" value={Tigan} onChange={handleTigan}>
                                    <option value="-">-</option>
                                    <option value="خزف على معدن">خزف على معدن</option>
                                    <option value="معدن">معدن</option>
                                    <option value="تاج خزفي كامل">تاج خزفي كامل</option>
                                    {tiganMaterialTypeArray.map((e) => {
                                        return (
                                            <option value={e}>{e}</option>
                                        )
                                    })}
                                </select>
                                <label className="label-text">:إضافة مادة</label>
                                <div className="addTreatType">
                                    <input type='text' className='form-control' placeholder='نوع المادة المضافة' onChange={handleTiganType}></input>
                                    <button className="btn btn-success add-menu-btn" onClick={addTiganMaterialType}>إضافة إلى القائمة</button>
                                </div>
                                {addTiganMaterialMessage && <div className="label-text">{addTiganMaterialMessage}</div>}
                                {Tigan === "خزف على معدن" && <select className="form-select f-ss" value={tagGazafMadan} onChange={handleTagGazafMadan}>
                                    <option value="-">-</option>
                                    <option value="عادي">عادي</option>
                                    <option value="ليزري">ليزري</option>
                                </select>}
                                {Tigan === "تاج خزفي كامل" && <select className="form-select f-ss" value={tagGazafiKamel} onChange={handleTagGazafiKamel}>
                                    <option value="-">-</option>
                                    <option value="Fullzircon">Full zircon</option>
                                    <option value="ZirconwithVita">Zircon with Vita</option>
                                    <option value="ZirconwithEMAX">Zircon with EMAX</option>
                                </select>}
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للتيجان' value={noteTegan} onChange={handleNoteTegan}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "جسور" && <div className="treatstype-container-add">
                            <div>
                                <label className="label-text">نوع مادة الجسر</label>
                                <select typeof="select" className="form-select f-ss" value={Gsr} onChange={handleGsr}>
                                    <option value="-">-</option>
                                    <option value="خزف على معدن">خزف على معدن</option>
                                    <option value="معدن">معدن</option>
                                    <option value="جسر خزفي كامل">جسر خزفي كامل</option>
                                </select>
                                {/* <label className="label-text">أسنان الجسر</label>
                                {!gsrArray.length && <div className="label-text">من فضلك قم باختيار الأسنان</div>}
                                <div className="gsrArray">
                                    <input type='text' className='form-control' value={allgsrArray}></input>
                                </div>*/}
                                {Gsr === "خزف على معدن" && <select className="form-select f-ss" value={GsrGazafMadan} onChange={handleGsrGazafMadan}>
                                    <option value="-">-</option>
                                    <option value="عادي">عادي</option>
                                    <option value="ليزري">ليزري</option>
                                </select>}
                                {Gsr === "جسر خزفي كامل" && <select className="form-select f-ss" value={GsrGazafiKamel} onChange={handleGsrGazafiKamel}>
                                    <option value="-">-</option>
                                    <option value="Fullzircon">Full zircon</option>
                                    <option value="ZirconwithVita">Zircon with Vita</option>
                                    <option value="ZirconwithEMAX">Zircon with EMAX</option>
                                </select>}
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للجسور' value={noteGsoor} onChange={handleNoteGsoor}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "تنظيف لثة وأسنان" && <div className="treatstype-container-add">
                            <div>
                                <select typeof="select" className="form-select f-ss" value={tanzif} onChange={handleTanzif}>
                                    <option value="-">-</option>
                                    <option value="تقليح وبوليش">تقليح وبوليش</option>
                                    <option value="تسوية جذور">تسوية جذور</option>
                                </select>
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للتنظيف' value={noteTanzif} onChange={handleNoteTanzif}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "تبييض" && <div className="treatstype-container-add">
                            <div>
                                <select typeof="select" className="form-select f-ss" value={tabeed} onChange={handleTabeed}>
                                    <option value="-">-</option>
                                    <option value="ليزري">ليزري</option>
                                    <option value="منزلي">منزلي</option>
                                </select>
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للتبييض' value={noteTabeed} onChange={handleNoteTabeed}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "قلع" && <div className="treatstype-container-add">
                            <div>
                                <select typeof="select" className="form-select f-s" value={kala} onChange={handleKala}>
                                    <option value="-">-</option>
                                    <option value="عادي">عادي</option>
                                    <option value="جراحي">جراحي</option>
                                </select>
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للقلع' value={noteKala} onChange={handleNoteKala}></textarea>
                            </div>
                        </div>}
                        {(openAddToMenu || treatmentType) && <div className="treatstype-container-add">
                            <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للمعالجة' value={noteLopia} onChange={handleNoteLopia}></textarea>
                            <label className="label-text">تكلفة معالجة السن</label>
                            <input type='number' className='form-control f-sss' placeholder='التكلفة' value={cost} onChange={handleCost}></input>
                        </div>}
                    </div>}
                    <div className="btn-submit-back">
                        <button className="btn btn-success submit-m-btn" onClick={handleAxiosSubmit}>حفظ</button>

                    </div>
                    {message && <div className="label-text">{message}</div>}
                </div>
            </div>}
            </div>}

















            {auth == 1 && <div>{isTabletOrMobile && <div className="ground-mobile">
                <div className="back-tool-mobile">
                    <button className="btn btn-dark btn-add" onClick={back}><IoMdArrowRoundBack /></button>
                </div>
                <div className="fk-mobile">
                    <div>
                        <div className='h-mobile'>
                            {status == "دائم" && <div className='hh-mobile'>
                                <img className={gsrArray.includes("8LU") ? "g-mobile" : tooth === "8LU" ? "b-mobile" : "t-mobile"} src={t8u} ></img>
                                <img className={gsrArray.includes("7LU") ? "g-mobile" : tooth === "7LU" ? "b-mobile" : "t-mobile"} src={t7u}></img>
                                <img className={gsrArray.includes("6LU") ? "g-mobile" : tooth === "6LU" ? "b-mobile" : "t-mobile"} src={t6u} ></img>
                                <img className={gsrArray.includes("5LU") ? "g-mobile" : tooth === "5LU" ? "b-mobile" : "t-mobile"} src={t5u} ></img>
                                <img className={gsrArray.includes("4LU") ? "g-mobile" : tooth === "4LU" ? "b-mobile" : "t-mobile"} src={t4u} ></img>
                                <img className={gsrArray.includes("3LU") ? "g-mobile" : tooth === "3LU" ? "b-mobile" : "t-mobile"} src={t3u} ></img>
                                <img className={gsrArray.includes("2LU") ? "g-mobile" : tooth === "2LU" ? "b-mobile" : "t-mobile"} src={t2u} ></img>
                                <img className={gsrArray.includes("1LU") ? "g-mobile" : tooth === "1LU" ? "b-mobile" : "t-mobile"} src={t1u} ></img>
                            </div>}
                            {status == "دائم" && <div className='hh-mobile'>
                                <img className={gsrArray.includes("1RU") ? "g-mobile" : tooth === "1RU" ? "b-mobile" : "t-mobile"} src={t1u}></img>
                                <img className={gsrArray.includes("2RU") ? "g-mobile" : tooth === "2RU" ? "b-mobile" : "t-mobile"} src={t2u} ></img>
                                <img className={gsrArray.includes("3RU") ? "g-mobile" : tooth === "3RU" ? "b-mobile" : "t-mobile"} src={t3u} ></img>
                                <img className={gsrArray.includes("4RU") ? "g-mobile" : tooth === "4RU" ? "b-mobile" : "t-mobile"} src={t4u}></img>
                                <img className={gsrArray.includes("5RU") ? "g-mobile" : tooth === "5RU" ? "b-mobile" : "t-mobile"} src={t5u} ></img>
                                <img className={gsrArray.includes("6RU") ? "g-mobile" : tooth === "6RU" ? "b-mobile" : "t-mobile"} src={t6u} ></img>
                                <img className={gsrArray.includes("7RU") ? "g-mobile" : tooth === "7RU" ? "b-mobile" : "t-mobile"} src={t7u} ></img>
                                <img className={gsrArray.includes("8RU") ? "g-mobile" : tooth === "8RU" ? "b-mobile" : "t-mobile"} src={t8u} ></img>
                            </div>}
                            {status == "مؤقت" && <div className='hh-mobile'>
                                <img className={tooth === "5LUT" ? "b-mobile" : "t-mobile"} src={t5tu} ></img>
                                <img className={tooth === "4LUT" ? "b-mobile" : "t-mobile"} src={t4tu} ></img>
                                <img className={tooth === "3LUT" ? "b-mobile" : "t-mobile"} src={t3tu} ></img>
                                <img className={tooth === "2LUT" ? "b-mobile" : "t-mobile"} src={t2tu} ></img>
                                <img className={tooth === "1LUT" ? "b-mobile" : "t-mobile"} src={t1tu} ></img>
                            </div>}
                            {status == "مؤقت" && <div className='hh-mobile'>
                                <img className={tooth === "1RUT" ? "b-mobile" : "t-mobile"} src={t1tu} ></img>
                                <img className={tooth === "2RUT" ? "b-mobile" : "t-mobile"} src={t2tu} ></img>
                                <img className={tooth === "3RUT" ? "b-mobile" : "t-mobile"} src={t3tu} ></img>
                                <img className={tooth === "4RUT" ? "b-mobile" : "t-mobile"} src={t4tu} ></img>
                                <img className={tooth === "5RUT" ? "b-mobile" : "t-mobile"} src={t5tu}></img>
                            </div>}
                        </div>
                        <div className="l-mobile">
                            {status == "دائم" && <div className='hh-mobile'>
                                <img className={gsrArray.includes("8LD") ? "g-mobile" : tooth === "8LD" ? "b-mobile" : "t-mobile"} src={t8d} ></img>
                                <img className={gsrArray.includes("7LD") ? "g-mobile" : tooth === "7LD" ? "b-mobile" : "t-mobile"} src={t7d} ></img>
                                <img className={gsrArray.includes("6LD") ? "g-mobile" : tooth === "6LD" ? "b-mobile" : "t-mobile"} src={t6d}></img>
                                <img className={gsrArray.includes("5LD") ? "g-mobile" : tooth === "5LD" ? "b-mobile" : "t-mobile"} src={t5d} ></img>
                                <img className={gsrArray.includes("4LD") ? "g-mobile" : tooth === "4LD" ? "b-mobile" : "t-mobile"} src={t4d} ></img>
                                <img className={gsrArray.includes("3LD") ? "g-mobile" : tooth === "3LD" ? "b-mobile" : "t-mobile"} src={t3d} ></img>
                                <img className={gsrArray.includes("2LD") ? "g-mobile" : tooth === "2LD" ? "b-mobile" : "t-mobile"} src={t2d} ></img>
                                <img className={gsrArray.includes("1LD") ? "g-mobile" : tooth === "1LD" ? "b-mobile" : "t-mobile"} src={t1d} ></img>
                            </div>}
                            {status == "دائم" && <div className='hh-mobile'>
                                <img className={gsrArray.includes("1RD") ? "g-mobile" : tooth === "1RD" ? "b-mobile" : "t-mobile"} src={t1d} ></img>
                                <img className={gsrArray.includes("2RD") ? "g-mobile" : tooth === "2RD" ? "b-mobile" : "t-mobile"} src={t2d} ></img>
                                <img className={gsrArray.includes("3RD") ? "g-mobile" : tooth === "3RD" ? "b-mobile" : "t-mobile"} src={t3d} ></img>
                                <img className={gsrArray.includes("4RD") ? "g-mobile" : tooth === "4RD" ? "b-mobile" : "t-mobile"} src={t4d} ></img>
                                <img className={gsrArray.includes("5RD") ? "g-mobile" : tooth === "5RD" ? "b-mobile" : "t-mobile"} src={t5d} ></img>
                                <img className={gsrArray.includes("6RD") ? "g-mobile" : tooth === "6RD" ? "b-mobile" : "t-mobile"} src={t6d} ></img>
                                <img className={gsrArray.includes("7RD") ? "g-mobile" : tooth === "7RD" ? "b-mobile" : "t-mobile"} src={t7d} ></img>
                                <img className={gsrArray.includes("8RD") ? "g-mobile" : tooth === "8RD" ? "b-mobile" : "t-mobile"} src={t8d} ></img>
                            </div>}
                        </div>
                        <div className="l-mobile">
                            {status == "مؤقت" && <div className='hh-mobile'>
                                <img className={tooth === "5LDT" ? "b-mobile" : "t-mobile"} src={t5td} ></img>
                                <img className={tooth === "4LDT" ? "b-mobile" : "t-mobile"} src={t4td} ></img>
                                <img className={tooth === "3LDT" ? "b-mobile" : "t-mobile"} src={t3td} ></img>
                                <img className={tooth === "2LDT" ? "b-mobile" : "t-mobile"} src={t2td} ></img>
                                <img className={tooth === "1LDT" ? "b-mobile" : "t-mobile"} src={t1td} ></img>
                            </div>}
                            {status == "مؤقت" && <div className='hh-mobile'>
                                <img className={tooth === "1RDT" ? "b-mobile" : "t-mobile"} src={t1td} ></img>
                                <img className={tooth === "2RDT" ? "b-mobile" : "t-mobile"} src={t2td} ></img>
                                <img className={tooth === "3RDT" ? "b-mobile" : "t-mobile"} src={t3td} ></img>
                                <img className={tooth === "4RDT" ? "b-mobile" : "t-mobile"} src={t4td} ></img>
                                <img className={tooth === "5RDT" ? "b-mobile" : "t-mobile"} src={t5td} ></img>
                            </div>}
                        </div>
                        {status == "مختلط" && <div className='hh-both'>
                            <div className="adult">
                                <div className='h-mobile'>
                                    {status == "مختلط" && <div className='hh-mobile'>
                                        <img className={tooth === "8LUP" ? "b-mobile" : "t-mobile"} src={t8u}></img>
                                        <img className={tooth === "7LUP" ? "b-mobile" : "t-mobile"} src={t7u} ></img>
                                        <img className={tooth === "6LUP" ? "b-mobile" : "t-mobile"} src={t6u} ></img>
                                        <img className={tooth === "5LUP" ? "b-mobile" : "t-mobile"} src={t5u} ></img>
                                        <img className={tooth === "4LUP" ? "b-mobile" : "t-mobile"} src={t4u} ></img>
                                        <img className={tooth === "3LUP" ? "b-mobile" : "t-mobile"} src={t3u} ></img>
                                        <img className={tooth === "2LUP" ? "b-mobile" : "t-mobile"} src={t2u} ></img>
                                        <img className={tooth === "1LUP" ? "b-mobile" : "t-mobile"} src={t1u} ></img>
                                    </div>}
                                    {status == "مختلط" && <div className='hh-mobile'>
                                        <img className={tooth === "1RUP" ? "b-mobile" : "t-mobile"} src={t1u} ></img>
                                        <img className={tooth === "2RUP" ? "b-mobile" : "t-mobile"} src={t2u} ></img>
                                        <img className={tooth === "3RUP" ? "b-mobile" : "t-mobile"} src={t3u} ></img>
                                        <img className={tooth === "4RUP" ? "b-mobile" : "t-mobile"} src={t4u} ></img>
                                        <img className={tooth === "5RUP" ? "b-mobile" : "t-mobile"} src={t5u} ></img>
                                        <img className={tooth === "6RUP" ? "b-mobile" : "t-mobile"} src={t6u} ></img>
                                        <img className={tooth === "7RUP" ? "b-mobile" : "t-mobile"} src={t7u} ></img>
                                        <img className={tooth === "8RUP" ? "b-mobile" : "t-mobile"} src={t8u} ></img>
                                    </div>}
                                </div>
                                <div className="l-mobile">
                                    {status == "مختلط" && <div className='hh-mobile'>
                                        <img className={tooth === "8LDP" ? "b-mobile" : "t-mobile"} src={t8d} ></img>
                                        <img className={tooth === "7LDP" ? "b-mobile" : "t-mobile"} src={t7d} ></img>
                                        <img className={tooth === "6LDP" ? "b-mobile" : "t-mobile"} src={t6d} ></img>
                                        <img className={tooth === "5LDP" ? "b-mobile" : "t-mobile"} src={t5d} ></img>
                                        <img className={tooth === "4LDP" ? "b-mobile" : "t-mobile"} src={t4d} ></img>
                                        <img className={tooth === "3LDP" ? "b-mobile" : "t-mobile"} src={t3d} ></img>
                                        <img className={tooth === "2LDP" ? "b-mobile" : "t-mobile"} src={t2d} ></img>
                                        <img className={tooth === "1LDP" ? "b-mobile" : "t-mobile"} src={t1d} ></img>
                                    </div>}
                                    {status == "مختلط" && <div className='hh-mobile'>
                                        <img className={tooth === "1RDP" ? "b-mobile" : "t-mobile"} src={t1d} ></img>
                                        <img className={tooth === "2RDP" ? "b-mobile" : "t-mobile"} src={t2d} ></img>
                                        <img className={tooth === "3RDP" ? "b-mobile" : "t-mobile"} src={t3d} ></img>
                                        <img className={tooth === "4RDP" ? "b-mobile" : "t-mobile"} src={t4d} ></img>
                                        <img className={tooth === "5RDP" ? "b-mobile" : "t-mobile"} src={t5d} ></img>
                                        <img className={tooth === "6RDP" ? "b-mobile" : "t-mobile"} src={t6d} ></img>
                                        <img className={tooth === "7RDP" ? "b-mobile" : "t-mobile"} src={t7d} ></img>
                                        <img className={tooth === "8RDP" ? "b-mobile" : "t-mobile"} src={t8d} ></img>
                                    </div>}
                                </div>
                            </div>
                            <div className="kids">
                                <div className="h-mobile">
                                    {status == "مختلط" && <div className='hh-mobile'>
                                        <img className={tooth === "5LUTP" ? "b-mobile" : "t-mobile"} src={t5tu} ></img>
                                        <img className={tooth === "4LUTP" ? "b-mobile" : "t-mobile"} src={t4tu} ></img>
                                        <img className={tooth === "3LUTP" ? "b-mobile" : "t-mobile"} src={t3tu} ></img>
                                        <img className={tooth === "2LUTP" ? "b-mobile" : "t-mobile"} src={t2tu} ></img>
                                        <img className={tooth === "1LUTP" ? "b-mobile" : "t-mobile"} src={t1tu} ></img>
                                    </div>}
                                    {status == "مختلط" && <div className='hh-mobile'>
                                        <img className={tooth === "1RUTP" ? "b-mobile" : "t-mobile"} src={t1tu} ></img>
                                        <img className={tooth === "2RUTP" ? "b-mobile" : "t-mobile"} src={t2tu} ></img>
                                        <img className={tooth === "3RUTP" ? "b-mobile" : "t-mobile"} src={t3tu} ></img>
                                        <img className={tooth === "4RUTP" ? "b-mobile" : "t-mobile"} src={t4tu} ></img>
                                        <img className={tooth === "5RUTP" ? "b-mobile" : "t-mobile"} src={t5tu} ></img>
                                    </div>}
                                </div>
                                <div className="l-mobile">
                                    {status == "مختلط" && <div className='hh-mobile'>
                                        <img className={tooth === "5LDTP" ? "b-mobile" : "t-mobile"} src={t5td}></img>
                                        <img className={tooth === "4LDTP" ? "b-mobile" : "t-mobile"} src={t4td} ></img>
                                        <img className={tooth === "3LDTP" ? "b-mobile" : "t-mobile"} src={t3td} ></img>
                                        <img className={tooth === "2LDTP" ? "b-mobile" : "t-mobile"} src={t2td} ></img>
                                        <img className={tooth === "1LDTP" ? "b-mobile" : "t-mobile"} src={t1td} ></img>
                                    </div>}
                                    {status == "مختلط" && <div className='hh-mobile'>
                                        <img className={tooth === "1RDTP" ? "b-mobile" : "t-mobile"} src={t1td} ></img>
                                        <img className={tooth === "2RDTP" ? "b-mobile" : "t-mobile"} src={t2td} ></img>
                                        <img className={tooth === "3RDTP" ? "b-mobile" : "t-mobile"} src={t3td}></img>
                                        <img className={tooth === "4RDTP" ? "b-mobile" : "t-mobile"} src={t4td} ></img>
                                        <img className={tooth === "5RDTP" ? "b-mobile" : "t-mobile"} src={t5td} ></img>
                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className='sel-mobile'>
                    <h2 className="label-text-title">:تعديل معالجة</h2>
                    <label className="label-text">:مكان المعالجة</label>
                    <select typeof="select" className="form-select f-ss" value={inclinic} onChange={handleInClinic}>
                        <option value="-">-</option>
                        <option value="in">في العيادة</option>
                        <option value="out">خارج العيادة</option>
                    </select>
                    {inclinic === "out" && <div>
                        <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات المعالجة' value={noteoutclinic} onChange={handleNoteOutClinic}></textarea>
                    </div>}
                    {inclinic === "in" && <div className="treat-details">
                        {status && <div className="treatstype-container">
                            <label className="label-text">:نوع المعالجة</label>
                            <div style={{ display: "flex", alignItems: "end", justifyContent: "center", width: "100%", gap: "10px" }}>
                                {!openAddToMenu && <button className="btn btn-outline-light" onClick={handleOpenAddToMenu}><div style={{ display: "flex", alignItems: "center" }}><IoIosArrowDropdown />إضافة</div></button>}
                                {openAddToMenu && <button className="btn btn-outline-light" onClick={handleOpenAddToMenu}><div style={{ display: "flex", alignItems: "center" }}><IoIosArrowDropup />+</div></button>}
                                <select typeof="select" className="form-select f-ss" value={treatShow} onChange={handeleTreatment}>
                                    <option value="-">-</option>
                                    <option value="معالجة لبية">معالجة لبية</option>
                                    <option value="معالجة ترميمية">معالجة ترميمية</option>
                                    <option value="تيجان">تيجان</option>
                                    <option value="Venners">Veneers</option>
                                    <option value="جسور">جسور</option>
                                    <option value="تنظيف لثة وأسنان">تنظيف لثة وأسنان</option>
                                    <option value="تبييض">تبييض</option>
                                    <option value="قلع">قلع</option>
                                    <option value="قطع ذروة">قطع ذروة</option>
                                    {treatmentsArray.map((e) => {
                                        return (
                                            <option value={e}>{e}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {openAddToMenu &&
                                <div className="addTreatType-container">
                                    <div className="addTreatType-mobile">
                                        <input type='text' className='form-control' placeholder='نوع المعالجة المضافة' onChange={handletype}></input>
                                        <button className="btn btn-success add-menu-btn" onClick={addTreatmentType}>إضافة إلى القائمة</button>
                                    </div>
                                    {addTreatMessage && <div className="label-text">{addTreatMessage}</div>}
                                </div>
                            }
                        </div>}
                        {treatmentType === "Venners" && <div className="treatstype-container-add">
                            <div>
                                <textarea type='text' className='form-control f-ssss' placeholder='Venners ملاحظات' value={noteVeneers} onChange={handleNoteVeneers}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "معالجة لبية" && <div className="treatstype-container-add">
                            {treatmentType === "معالجة لبية" && <div className="treatstype-container">
                                <label className="label-text">نوع المعالجة اللبية</label>
                                <select typeof="select" className="form-select f-s" value={lopiaType} onChange={handeleLopia}>
                                    <option value="-">-</option>
                                    <option value="معالجة لبية كاملة"> معالجة لبية كاملة</option>
                                    <option value="إعادة معالجة لبية">إعادة معالجة لبية</option>
                                    <option value="بتر لب">بتر لب</option>
                                    <option value="تغطية لبية مباشرة">تغطية لبية مباشرة</option>
                                </select>
                            </div>}
                            {treatmentType === "معالجة لبية" && lopiaType === "معالجة لبية كاملة" && <div>
                                <label className="label-text">أطوال القنوات</label>
                                <form className='formm'>
                                    {(tooth === "1LU" || tooth === "2LU" || tooth === "3LU" || tooth === "3LD" || tooth === "4LD"
                                        || tooth === "5LD" || tooth === "1RU" || tooth === "2RU" || tooth === "3RU" || tooth === "3RD"
                                        || tooth === "4RD" || tooth === "5RD" || tooth === "1LUP" || tooth === "1LUT" || tooth === "2LUT" || tooth === "3LUT" || tooth === "1LUTP" || tooth === "2LUTP" || tooth === "3LUTP"
                                        || tooth === "2LUP" || tooth === "3LUP" || tooth === "3LDP" || tooth === "4LDP" || tooth === "1LDT" || tooth === "2LDT" || tooth === "3LDT" || tooth === "1LDTP" || tooth === "2LDTP" || tooth === "3LDTP"
                                        || tooth === "5LDP" || tooth === "1RUP" || tooth === "2RUP" || tooth === "3RUP" || tooth === "3RDP" || tooth === "1RUT" || tooth === "2RUT" || tooth === "3RUT" || tooth === "1RUTP" || tooth === "2RUTP" || tooth === "3RUTP"
                                        || tooth === "4RDP" || tooth === "5RDP" || tooth === "1RDT" || tooth === "2RDT" || tooth === "3RDT" || tooth === "1RDTP" || tooth === "2RDTP" || tooth === "3RDTP") &&
                                        <div className="channals-names"><label className="label-text">القناة</label>
                                            <input type='text' className='form-control f-s' placeholder='طول القناة' value={channal} onChange={handeleChannal}></input></div>}
                                    {(tooth === "5LU" || tooth === "4LU" || tooth === "1LD" || tooth === "2LD"
                                        || tooth === "5RU" || tooth === "4RU" || tooth === "1RD" || tooth === "2RD"
                                        || tooth === "5LUP" || tooth === "4LUP" || tooth === "1LDP" || tooth === "2LDP"
                                        || tooth === "5RUP" || tooth === "4RUP" || tooth === "1RDP" || tooth === "2RDP") &&
                                        <div className="channals-names"><label className="label-text">B:</label>
                                            <input type='text' className='form-control f-s' placeholder='B طول القناة' value={channalB} onChange={handeleChannalB}></input></div>}
                                    {(tooth === "5LU" || tooth === "4LU" || tooth === "6LU" || tooth === "7LU"
                                        || tooth === "8LU" || tooth === "5RU" || tooth === "4RU" || tooth === "6RU"
                                        || tooth === "7RU" || tooth === "8RU"
                                        || tooth === "5LUP" || tooth === "4LUP" || tooth === "6LUP" || tooth === "7LUP"
                                        || tooth === "8LUP" || tooth === "5RUP" || tooth === "4RUP" || tooth === "6RUP"
                                        || tooth === "7RUP" || tooth === "8RUP" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">P:</label>
                                            <input type='text' className='form-control f-s' placeholder='P طول القناة' value={channalP} onChange={handeleChannalP}></input></div>}
                                    {(tooth === "6LD" || tooth === "7LD" || tooth === "8LD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP") &&
                                        <div className="channals-names"><label className="label-text">D:</label>
                                            <input type='text' className='form-control f-s' placeholder='D طول القناة' value={channalD} onChange={handeleChannalD}></input></div>}
                                    {(tooth === "6LD" || tooth === "7LD" || tooth === "8LD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP") &&
                                        <div className="channals-names"><label className="label-text">ML:</label>
                                            <input type='text' className='form-control f-s' placeholder='ML طول القناة' value={channalML} onChange={handeleChannalML}></input></div>}
                                    {(tooth === "6LU" || tooth === "7LU" || tooth === "8LU" || tooth === "6LR" || tooth === "7LR" || tooth === "8LR"
                                        || tooth === "6LUP" || tooth === "7LUP" || tooth === "8LUP" || tooth === "7RU" || tooth === "7RUP" || tooth === "6RUP" || tooth === "6RU" || tooth === "8RUP" || tooth === "8RU" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">DB:</label>
                                            <input type='text' className='form-control f-s' placeholder='DB طول القناة' value={channalDB} onChange={handeleChannalDB}></input></div>}
                                    {(tooth === "6LU" || tooth === "6RU" || tooth === "6LD" || tooth === "6RD" || tooth === "6LUP" || tooth === "6RUP" || tooth === "6LDP" || tooth === "6RDP" || tooth === "7RU" || tooth === "7RUP" || tooth === "7LU" || tooth === "7LUP" || tooth === "7RD" || tooth === "7RDP" || tooth === "7LD" || tooth === "7LDP") &&
                                        <div className="channals-names"><label className="label-text">MB1:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB1 طول القناة' value={channalMB1} onChange={handeleChannalMB1}></input></div>}
                                    {(tooth === "6LU" || tooth === "6RU" || tooth === "6LD" || tooth === "6RD" || tooth === "6LUP" || tooth === "6RUP" || tooth === "6LDP" || tooth === "6RDP" || tooth === "7RU" || tooth === "7RUP" || tooth === "7LU" || tooth === "7LUP" || tooth === "7RD" || tooth === "7RDP" || tooth === "7LD" || tooth === "7LDP") &&
                                        <div className="channals-names"><label className="label-text">MB2:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB2 طول القناة' value={channalMB2} onChange={handeleChannalMB2}></input></div>}
                                    {(tooth === "8LU" || tooth === "8LD"
                                        || tooth === "8RU" || tooth === "8RD"
                                        || tooth === "8LUP" || tooth === "8LDP" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "8RUP" || tooth === "8RDP" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">MB:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB طول القناة' value={channalMB} onChange={handeleChannalMB}></input></div>}
                                    {(tooth === "1LD" || tooth === "2LD" || tooth === "1RD" || tooth === "2RD"
                                        || tooth === "1LDP" || tooth === "2LDP" || tooth === "1RDP" || tooth === "2RDP") &&
                                        <div className="channals-names"><label className="label-text">L:</label>
                                            <input type='text' className='form-control f-s' placeholder='L طول القناة' value={channalL} onChange={handeleChannalL}></input></div>}
                                    {<input type='text' className='form-control f-s' placeholder='ملاحظات للقنوات' onChange={handeleChannalNote}></input>}
                                    <div className="cannalAdd-mobile">
                                        <input type='text' className='form-control' placeholder='طول القناة' onChange={handleCannalLength}></input>
                                        <input type='text' className='form-control' placeholder='اسم القناة' onChange={handleCannalName}></input>
                                        <button className="btn btn-success" disabled={!cannalName && !cannalLength} onClick={addCannal}>+</button>
                                    </div>
                                    {addcannalMessage && <div className="label-text">{addcannalMessage}</div>}
                                    {infoooAddCannals.length > 0 && <div>
                                        <div className="label-text">:القنوات المضافة</div>
                                        {cannalsItems}
                                        <button className="btn btn-danger" onClick={deleteCannal}>- حذف قناة</button>
                                    </div>}
                                </form>
                            </div>}
                            {treatmentType === "معالجة لبية" && lopiaType === "إعادة معالجة لبية" && <div>
                                <label className="label-text">أطوال القنوات</label>
                                <form className='formm'>
                                    {(tooth === "1LU" || tooth === "2LU" || tooth === "3LU" || tooth === "3LD" || tooth === "4LD"
                                        || tooth === "5LD" || tooth === "1RU" || tooth === "2RU" || tooth === "3RU" || tooth === "3RD"
                                        || tooth === "4RD" || tooth === "5RD" || tooth === "1LUP" || tooth === "1LUT" || tooth === "2LUT" || tooth === "3LUT" || tooth === "1LUTP" || tooth === "2LUTP" || tooth === "3LUTP"
                                        || tooth === "2LUP" || tooth === "3LUP" || tooth === "3LDP" || tooth === "4LDP" || tooth === "1LDT" || tooth === "2LDT" || tooth === "3LDT" || tooth === "1LDTP" || tooth === "2LDTP" || tooth === "3LDTP"
                                        || tooth === "5LDP" || tooth === "1RUP" || tooth === "2RUP" || tooth === "3RUP" || tooth === "3RDP" || tooth === "1RUT" || tooth === "2RUT" || tooth === "3RUT" || tooth === "1RUTP" || tooth === "2RUTP" || tooth === "3RUTP"
                                        || tooth === "4RDP" || tooth === "5RDP" || tooth === "1RDT" || tooth === "2RDT" || tooth === "3RDT" || tooth === "1RDTP" || tooth === "2RDTP" || tooth === "3RDTP") &&
                                        <div className="channals-names"><label className="label-text">القناة</label>
                                            <input type='text' className='form-control f-s' placeholder='طول القناة' value={channal} onChange={handeleChannal}></input></div>}
                                    {(tooth === "5LU" || tooth === "4LU" || tooth === "1LD" || tooth === "2LD"
                                        || tooth === "5RU" || tooth === "4RU" || tooth === "1RD" || tooth === "2RD"
                                        || tooth === "5LUP" || tooth === "4LUP" || tooth === "1LDP" || tooth === "2LDP"
                                        || tooth === "5RUP" || tooth === "4RUP" || tooth === "1RDP" || tooth === "2RDP") &&
                                        <div className="channals-names"><label className="label-text">B:</label>
                                            <input type='text' className='form-control f-s' placeholder='B طول القناة' value={channalB} onChange={handeleChannalB}></input></div>}
                                    {(tooth === "5LU" || tooth === "4LU" || tooth === "6LU" || tooth === "7LU"
                                        || tooth === "8LU" || tooth === "5RU" || tooth === "4RU" || tooth === "6RU"
                                        || tooth === "7RU" || tooth === "8RU"
                                        || tooth === "5LUP" || tooth === "4LUP" || tooth === "6LUP" || tooth === "7LUP"
                                        || tooth === "8LUP" || tooth === "5RUP" || tooth === "4RUP" || tooth === "6RUP"
                                        || tooth === "7RUP" || tooth === "8RUP" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">P:</label>
                                            <input type='text' className='form-control f-s' placeholder='P طول القناة' value={channalP} onChange={handeleChannalP}></input></div>}
                                    {(tooth === "6LD" || tooth === "7LD" || tooth === "8LD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP") &&
                                        <div className="channals-names"><label className="label-text">D:</label>
                                            <input type='text' className='form-control f-s' placeholder='D طول القناة' value={channalD} onChange={handeleChannalD}></input></div>}
                                    {(tooth === "6LD" || tooth === "7LD" || tooth === "8LD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP") &&
                                        <div className="channals-names"><label className="label-text">ML:</label>
                                            <input type='text' className='form-control f-s' placeholder='ML طول القناة' value={channalML} onChange={handeleChannalML}></input></div>}
                                    {(tooth === "6LU" || tooth === "7LU" || tooth === "8LU" || tooth === "6LR" || tooth === "7LR" || tooth === "8LR"
                                        || tooth === "6LUP" || tooth === "7LUP" || tooth === "8LUP" || tooth === "7RU" || tooth === "7RUP" || tooth === "6RUP" || tooth === "6RU" || tooth === "8RUP" || tooth === "8RU" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">DB:</label>
                                            <input type='text' className='form-control f-s' placeholder='DB طول القناة' value={channalDB} onChange={handeleChannalDB}></input></div>}
                                    {(tooth === "6LU" || tooth === "6RU" || tooth === "6LD" || tooth === "6RD" || tooth === "6LUP" || tooth === "6RUP" || tooth === "6LDP" || tooth === "6RDP" || tooth === "7RU" || tooth === "7RUP" || tooth === "7LU" || tooth === "7LUP" || tooth === "7RD" || tooth === "7RDP" || tooth === "7LD" || tooth === "7LDP") &&
                                        <div className="channals-names"><label className="label-text">MB1:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB1 طول القناة' value={channalMB1} onChange={handeleChannalMB1}></input></div>}
                                    {(tooth === "6LU" || tooth === "6RU" || tooth === "6LD" || tooth === "6RD" || tooth === "6LUP" || tooth === "6RUP" || tooth === "6LDP" || tooth === "6RDP" || tooth === "7RU" || tooth === "7RUP" || tooth === "7LU" || tooth === "7LUP" || tooth === "7RD" || tooth === "7RDP" || tooth === "7LD" || tooth === "7LDP") &&
                                        <div className="channals-names"><label className="label-text">MB2:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB2 طول القناة' value={channalMB2} onChange={handeleChannalMB2}></input></div>}
                                    {(tooth === "8LU" || tooth === "8LD"
                                        || tooth === "8RU" || tooth === "8RD"
                                        || tooth === "8LUP" || tooth === "8LDP" || tooth === "4LDT" || tooth === "4LDTP" || tooth === "5LDT" || tooth === "5LDTP" || tooth === "4RDT" || tooth === "4RDTP" || tooth === "5RDT" || tooth === "5RDTP"
                                        || tooth === "8RUP" || tooth === "8RDP" || tooth === "4LUT" || tooth === "4LUTP" || tooth === "5LUT" || tooth === "5LUTP" || tooth === "4RUT" || tooth === "4RUTP" || tooth === "5RUT" || tooth === "5RUTP") &&
                                        <div className="channals-names"><label className="label-text">MB:</label>
                                            <input type='text' className='form-control f-s' placeholder='MB طول القناة' value={channalMB} onChange={handeleChannalMB}></input></div>}
                                    {(tooth === "1LD" || tooth === "2LD" || tooth === "1RD" || tooth === "2RD"
                                        || tooth === "1LDP" || tooth === "2LDP" || tooth === "1RDP" || tooth === "2RDP") &&
                                        <div className="channals-names"><label className="label-text">L:</label>
                                            <input type='text' className='form-control f-s' placeholder='L طول القناة' value={channalL} onChange={handeleChannalL}></input></div>}
                                    {<input type='text' className='form-control f-s' placeholder='ملاحظات للقنوات' onChange={handeleChannalNote}></input>}
                                    <div className="cannalAdd-mobile">
                                        <input type='text' className='form-control' placeholder='طول القناة' onChange={handleCannalLength}></input>
                                        <input type='text' className='form-control' placeholder='اسم القناة' onChange={handleCannalName}></input>
                                        <button className="btn btn-success" disabled={!cannalName && !cannalLength} onClick={addCannal}>+</button>
                                    </div>
                                    {addcannalMessage && <div className="label-text">{addcannalMessage}</div>}
                                    {infoooAddCannals.length > 0 && <div>
                                        <div className="label-text">:القنوات المضافة</div>
                                        {cannalsItems}
                                        <button className="btn btn-danger" onClick={deleteCannal}>- حذف قناة</button>
                                    </div>}
                                </form>
                            </div>}
                        </div>}
                        {treatmentType === "معالجة ترميمية" && <div className="treatstype-container-add">
                            <div className="treatstype-container">
                                <label className="label-text">نوع المادة الترميمية</label>
                                <select typeof="select" className="form-select f-ss" value={tarmimMaterial} onChange={handeleTarmimMaterial}>
                                    <option value="-">-</option>
                                    <option value="أملغم">أملغم</option>
                                    <option value="كومبوزيت">كومبوزيت</option>
                                    {trmimMatreialTypeArray.map((e) => {
                                        return (
                                            <option value={e}>{e}</option>
                                        )
                                    })}
                                </select>
                                <label className="label-text">:إضافة مادة</label>
                                <div className="addTreatType">
                                    <input type='text' className='form-control' placeholder='نوع المادة المضافة' onChange={handleTrmimType}></input>
                                    <button className="btn btn-success add-menu-btn" onClick={addTrmimMatrialType}>إضافة إلى القائمة</button>
                                </div>
                                {addTrmimMaterialMessage && <div className="label-text">{addTrmimMaterialMessage}</div>}

                                <label className="label-text">Class نوع ال</label>
                                <select className="form-select f-ss" value={chooseClass} onChange={handeleClass}>
                                    <option value="-">-</option>
                                    {(tooth === "1LU" || tooth === "2LU" || tooth === "3LU" || tooth === "1LD"
                                        || tooth === "2LD" || tooth === "3LD" || tooth === "1RU" || tooth === "2RU" || tooth === "2RD" || tooth === "1RD"
                                        || tooth === "3RU" || tooth === "3RD"
                                        || tooth === "1LUP" || tooth === "2LUP" || tooth === "3LUP" || tooth === "1LDP"
                                        || tooth === "2LDP" || tooth === "3LDP" || tooth === "1RUP" || tooth === "2RUP" || tooth === "3RUP" || tooth === "3RDP" || tooth === "2RDP" || tooth === "1RDP") && <option value="Class(3)">Class(3)</option>}
                                    {(tooth === "1LU" || tooth === "2LU" || tooth === "1LD" || tooth === "2LD"
                                        || tooth === "3LU" || tooth === "3LD" || tooth === "1RU" || tooth === "2RU" || tooth === "2RD" || tooth === "1RD"
                                        || tooth === "3RU" || tooth === "3RD"
                                        || tooth === "1LUP" || tooth === "2LUP" || tooth === "3LUP" || tooth === "1LDP"
                                        || tooth === "2LDP" || tooth === "3LDP" || tooth === "1RUP" || tooth === "2RUP" || tooth === "3RUP" || tooth === "3RDP" || tooth === "2RDP" || tooth === "1RDP") && <option value="Class(4)">Class(4)</option>}
                                    <option value="Class(5)">Class(5)</option>
                                    {(tooth === "4LU" || tooth === "5LU" || tooth === "6LU" || tooth === "7LU" || tooth === "8LU"
                                        || tooth === "4RU" || tooth === "5RU" || tooth === "6RU" || tooth === "7RU" || tooth === "8RU"
                                        || tooth === "4LD" || tooth === "5LD" || tooth === "6LD" || tooth === "7LD" || tooth === "8LD"
                                        || tooth === "4RD" || tooth === "5RD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD"
                                        || tooth === "4LUP" || tooth === "5LUP" || tooth === "6LUP" || tooth === "7LUP" || tooth === "8LUP"
                                        || tooth === "4RUP" || tooth === "5RUP" || tooth === "6RUP" || tooth === "7RUP" || tooth === "8RUP"
                                        || tooth === "4LDP" || tooth === "5LDP" || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP"
                                        || tooth === "4RDP" || tooth === "5RDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP") && <option value="Class(1)">Class(1)</option>}
                                    {(tooth === "4LU" || tooth === "5LU" || tooth === "6LU" || tooth === "7LU" || tooth === "8LU"
                                        || tooth === "4RU" || tooth === "5RU" || tooth === "6RU" || tooth === "7RU" || tooth === "8RU"
                                        || tooth === "4LD" || tooth === "5LD" || tooth === "6LD" || tooth === "7LD" || tooth === "8LD"
                                        || tooth === "4RD" || tooth === "5RD" || tooth === "6RD" || tooth === "7RD" || tooth === "8RD"
                                        || tooth === "4LUP" || tooth === "5LUP" || tooth === "6LUP" || tooth === "7LUP" || tooth === "8LUP"
                                        || tooth === "4RUP" || tooth === "5RUP" || tooth === "6RUP" || tooth === "7RUP" || tooth === "8RUP"
                                        || tooth === "4LDP" || tooth === "5LDP" || tooth === "6LDP" || tooth === "7LDP" || tooth === "8LDP"
                                        || tooth === "4RDP" || tooth === "5RDP" || tooth === "6RDP" || tooth === "7RDP" || tooth === "8RDP") && <option value="Class(2)">Class(2)</option>}
                                    {<input type='text' className='form-control' placeholder='ملاحظات' value={channalNote} onChange={handeleChannalNote}></input>}
                                </select>
                                {chooseClass === "Class(2)" &&
                                    <div>
                                        <label className="label-text">Class(2) نوع</label>
                                        <select className="form-select f-s" value={class2Option} onChange={handeleClass2Option}>
                                            <option value="-">-</option>
                                            <option value="MO">MO</option>
                                            <option value="DO">DO</option>
                                            <option value="MOD">MOD</option>
                                        </select>
                                    </div>}
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للمعالجة الترميمية' value={noteTarmim} onChange={handleNoteTarmim}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "تيجان" && <div className="treatstype-container-add">
                            <div className="treatstype-container">
                                <label className="label-text">نوع مادة التاج</label>
                                <select typeof="select" className="form-select f-ss" value={Tigan} onChange={handleTigan}>
                                    <option value="-">-</option>
                                    <option value="خزف على معدن">خزف على معدن</option>
                                    <option value="معدن">معدن</option>
                                    <option value="تاج خزفي كامل">تاج خزفي كامل</option>
                                    {tiganMaterialTypeArray.map((e) => {
                                        return (
                                            <option value={e}>{e}</option>
                                        )
                                    })}
                                </select>
                                <label className="label-text">:إضافة مادة</label>
                                <div className="addTreatType">
                                    <input type='text' className='form-control' placeholder='نوع المادة المضافة' onChange={handleTiganType}></input>
                                    <button className="btn btn-success add-menu-btn" onClick={addTiganMaterialType}>إضافة إلى القائمة</button>
                                </div>
                                {addTiganMaterialMessage && <div className="label-text">{addTiganMaterialMessage}</div>}
                                {Tigan === "خزف على معدن" && <select className="form-select f-ss" value={tagGazafMadan} onChange={handleTagGazafMadan}>
                                    <option value="-">-</option>
                                    <option value="عادي">عادي</option>
                                    <option value="ليزري">ليزري</option>
                                </select>}
                                {Tigan === "تاج خزفي كامل" && <select className="form-select f-ss" value={tagGazafiKamel} onChange={handleTagGazafiKamel}>
                                    <option value="-">-</option>
                                    <option value="Fullzircon">Full zircon</option>
                                    <option value="ZirconwithVita">Zircon with Vita</option>
                                    <option value="ZirconwithEMAX">Zircon with EMAX</option>
                                </select>}
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للتيجان' value={noteTegan} onChange={handleNoteTegan}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "جسور" && <div className="treatstype-container-add">
                            <div>
                                <label className="label-text">نوع مادة الجسر</label>
                                <select typeof="select" className="form-select f-ss" value={Gsr} onChange={handleGsr}>
                                    <option value="-">-</option>
                                    <option value="خزف على معدن">خزف على معدن</option>
                                    <option value="معدن">معدن</option>
                                    <option value="جسر خزفي كامل">جسر خزفي كامل</option>
                                </select>
                                {/*<label className="label-text">أسنان الجسر</label>
                                {!gsrArray.length && <div className="label-text">من فضلك قم باختيار الأسنان</div>}
                                <div className="gsrArray">
                                    <input type='text' className='form-control' value={allgsrArray}></input>
                                </div>*/}
                                {Gsr === "خزف على معدن" && <select className="form-select f-ss" value={GsrGazafMadan} onChange={handleGsrGazafMadan}>
                                    <option value="-">-</option>
                                    <option value="عادي">عادي</option>
                                    <option value="ليزري">ليزري</option>
                                </select>}
                                {Gsr === "جسر خزفي كامل" && <select className="form-select f-ss" value={GsrGazafiKamel} onChange={handleGsrGazafiKamel}>
                                    <option value="-">-</option>
                                    <option value="Fullzircon">Full zircon</option>
                                    <option value="ZirconwithVita">Zircon with Vita</option>
                                    <option value="ZirconwithEMAX">Zircon with EMAX</option>
                                </select>}
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للجسور' value={noteGsoor} onChange={handleNoteGsoor}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "تنظيف لثة وأسنان" && <div className="treatstype-container-add">
                            <div>
                                <select typeof="select" className="form-select f-ss" value={tanzif} onChange={handleTanzif}>
                                    <option value="-">-</option>
                                    <option value="تقليح وبوليش">تقليح وبوليش</option>
                                    <option value="تسوية جذور">تسوية جذور</option>
                                </select>
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للتنظيف' value={noteTanzif} onChange={handleNoteTanzif}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "تبييض" && <div className="treatstype-container-add">
                            <div>
                                <select typeof="select" className="form-select f-ss" value={tabeed} onChange={handleTabeed}>
                                    <option value="-">-</option>
                                    <option value="ليزري">ليزري</option>
                                    <option value="منزلي">منزلي</option>
                                </select>
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للتبييض' value={noteTabeed} onChange={handleNoteTabeed}></textarea>
                            </div>
                        </div>}
                        {treatmentType === "قلع" && <div className="treatstype-container-add">
                            <div>
                                <select typeof="select" className="form-select f-s" value={kala} onChange={handleKala}>
                                    <option value="-">-</option>
                                    <option value="عادي">عادي</option>
                                    <option value="جراحي">جراحي</option>
                                </select>
                                <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للقلع' value={noteKala} onChange={handleNoteKala}></textarea>
                            </div>
                        </div>}
                        {(openAddToMenu || treatmentType) && <div className="treatstype-container-add">
                            <textarea type='text' className='form-control f-ssss' placeholder='ملاحظات للمعالجة' value={noteLopia} onChange={handleNoteLopia}></textarea>
                            <label className="label-text">تكلفة معالجة السن</label>
                            <input type='number' className='form-control f-sss' placeholder='التكلفة' value={cost} onChange={handleCost}></input>
                        </div>}
                    </div>}
                    <div className="btn-submit-back">
                        <button className="btn btn-success submit-m-btn" onClick={handleAxiosSubmit}>حفظ</button>

                    </div>
                    {message && <div className="label-text">{message}</div>}
                </div>
            </div>}
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default EditTreatments;