import "./AddData.css"
import { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Tooth from "../assets/tooth.png";
import { useNavigate } from "react-router-dom";
import { MdAttachMoney } from "react-icons/md";
import { IdContext } from "./userIdContext";
import { loginAuthhh, loginkey } from "./LoginContext";
import LogIn from "./login";
import { useMediaQuery } from "react-responsive";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { FaRegSave } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";

function AddPayment(props) {
    const [amount, setAmount] = useState("");
    const [date, setDate] = useState("");
    const [uploadMessage, setUploadMessage] = useState('');
    const [enable, setEnable] = useState(null);
    const { userId } = useContext(IdContext);
    const { setUserId } = useContext(IdContext);
    const { auth } = useContext(loginkey);


    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };
    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    function handleAxiosSubmit(event) {
        event.preventDefault();
        setEnable(true);
        setUploadMessage('... يرجى الانتظار')
        const url = 'https://dr-meladjouriah.fxcode.tech/php/addPayment.php';
        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('date', date);
        formData.append('userid', userId);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setUploadMessage("تم")
                setEnable(false)
                navToGet();
            }
            console.log(response);
        })
            .catch(error => {
                setUploadMessage(error.message)
                console.log(error);
            });
    }
    const navigate = useNavigate();
    function navToGet() {
        navigate("/GetPayments", { replace: true });
    }

    function back() {
        window.history.pushState({ id: 1 }, null, "GetPayments")
        navigate("/GetPayments", { replace: true });
    }
    /*useEffect(() => {
        setUserId(window.localStorage.getItem("userid"))
        console.log(`vvvv ${window.localStorage.getItem("userid")}`);
    }, [!userId])
    useEffect(() => {
        if (userId) {
            window.localStorage.setItem("userid", userId);
        }
    }, [])*/
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <div className="add">
            {auth == 1 && <div>{isDesktopOrLaptop && <div className="container-add">
                <form className="add-form" onSubmit={handleAxiosSubmit}>
                    <img src={Tooth} className="tooth-img"></img>
                    <div className="title-add">إضافة مبلغ <AiOutlineDollarCircle /></div>
                    <input type="number" className="form-control form-input" onChange={(event) => handleAmountChange(event)} placeholder="المبلغ" required></input>
                    <input type="date" className="form-control form-input" onChange={(event) => handleDateChange(event)} placeholder="التاريخ" required></input>
                    <div className="btn-container">
                        <button type="submit" className="btn btn-success add-button">إضافة</button>
                        <button className="btn btn-dark add-button" onClick={back}>رجوع</button>
                    </div>
                    {uploadMessage && <div className="label-text">{uploadMessage}</div>}
                </form>
            </div>
            }
            </div>}
            {auth == 1 && <div>{isTabletOrMobile && <div className="container-add-mobile">
                <form className="add-form-mobile" onSubmit={handleAxiosSubmit}>
                    <img src={Tooth} className="tooth-img-mobile"></img>
                    <input type="number" className="form-control form-input-mobile" onChange={(event) => handleAmountChange(event)} placeholder="المبلغ" required></input>
                    <input type="date" className="form-control form-input-mobile" onChange={(event) => handleDateChange(event)} placeholder="التاريخ" required></input>
                    <div className="btn-container-mobile">
                        <button type="submit" className="btn btn-success add-button-mobile">إضافة</button>
                        <button className="btn btn-dark add-button-mobile" onClick={back}>رجوع</button>
                    </div>
                    {uploadMessage && <div className="label-text">{uploadMessage}</div>}
                </form>
            </div>}
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default AddPayment;