import { useContext, useEffect, useState } from "react";
import Treatments from "./treatments";
import GetTreatments from "./getTreatments";
import { useMediaQuery } from "react-responsive";
import { loginkey } from "./LoginContext";
import LogIn from "./login";

function TreatmentsPage() {
    const [changepage, setChangePage] = useState(true);
    const {auth} = useContext(loginkey)
    const handleChange = () => {
        setChangePage(true)
    };
    const handleChangeGet = () => {
        setChangePage(false)
    };
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    /*useEffect(()=>{
        window.history.pushState({id:1},null,"Treatments")
    },[])*/
    return (
        <div className="t-page">
            {auth == 1 && <div>{isDesktopOrLaptop && <div>
                <span className="nav-s">
                    <button className="btn btn-outline-light btn-treats" disabled={changepage} onClick={handleChange}>إضافة معالجة +</button>
                    <button className="btn btn-outline-light btn-treats" disabled={!changepage} onClick={handleChangeGet}>المعالجات</button>
                </span>
            </div>}
            </div>}
            {auth == 1 && <div>{isTabletOrMobile && <div>
                <span className="nav-s-mobile">
                    <button className="btn btn-outline-light btn-treats" disabled={changepage} onClick={handleChange}>إضافة معالجة +</button>
                    <button className="btn btn-outline-light btn-treats" disabled={!changepage} onClick={handleChangeGet}>المعالجات</button>
                </span>
            </div>}
            </div>}

            {auth==1 && <div>{changepage && <Treatments />}</div>}
            {auth==1 && <div>{!changepage && <GetTreatments />}</div>}
            {auth == 0 && <LogIn/>}
        </div>
    )
}

export default TreatmentsPage;