import "./NavBar.css";
import { useContext, useEffect, useState } from "react";
import { IdContext } from "./userIdContext";
import { IoPerson } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { NavContext } from "./NavContext";
import { useNavigate } from "react-router-dom";
import { loginkey } from "./LoginContext";
import { AddDetailsContext } from "./AddDetailsContext";

function NavBar(props) {
    const { auth } = useContext(loginkey);
    const { setAuth } = useContext(loginkey);
    const { userId } = useContext(IdContext);
    const { setPatients } = useContext(NavContext);
    const [a, setA] = useState("")
    const [d, setD] = useState("")
    const { setSessions } = useContext(NavContext);
    const { setPayments } = useContext(NavContext);
    const { addDetailsContextt } = useContext(AddDetailsContext);
    const { setAddDetailsContextt } = useContext(AddDetailsContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const navigate = useNavigate();
    function navToAdd() {
        window.history.pushState({id:1},null,"GetPatients")
        navigate("/GetPatients", { replace: true });
    }
    function selectPatients() {
        setPatients(true);
        setPayments(false);
        setSessions(false);
        navToAdd();
    }
    function selectSessions() {
        setSessions(true);
        setPatients(false);
        setPayments(false);
        navToAdd();
    }
    function selectPayments() {
        setPayments(true);
        setSessions(false);
        setPatients(false);
        navToAdd();
    }
    /*useEffect(() => {
        if (auth === 0) {
            setAuth(window.localStorage.getItem("auth"))
            setAddDetailsContextt(window.localStorage.getItem("details"))
            console.log(`auth ${window.localStorage.getItem("auth")}`);
            console.log(`details ${window.localStorage.getItem("details")}`);
        }
    }, [])
    useEffect(() => {
        if (auth === 1) {
            window.localStorage.setItem("auth", auth);
            console.log(`auth from 1111 ${auth}`)
            window.localStorage.setItem("details", addDetailsContextt);
        }
    },)
    console.log(`aaaauth ${auth}`);
    console.log(`ddddetails ${addDetailsContextt}`);*/
    return (
        <div>
            {isDesktopOrLaptop && <nav className="nav-bar-desktop">
                <div className="nav-list-desktop">
                    <div className="btn-nav-desktop"><button className="nav-icon-desktop" onClick={selectPatients}><IoPerson color="white" size={30} /><span className="nav-text-desktop">المرضى</span></button></div>
                    <div className="btn-nav-desktop"><button className="nav-icon-desktop" onClick={selectSessions}><SlCalender color="white" size={30} /><span className="nav-text-desktop">الجلسات</span></button></div>
                    <div className="btn-nav-desktop"><button className="nav-icon-desktop" onClick={selectPayments}><FaMoneyCheckDollar color="white" size={30} /><span className="nav-text-desktop">الحسابات</span></button></div>
                </div>
            </nav>}
            {isTabletOrMobile && auth==1 && !addDetailsContextt && <nav className="nav-bar">
                <div className="nav-list">
                    <div className="btn-nav"><button className="nav-icon" onClick={selectPatients}><IoPerson color="white" size={30} /><span className="nav-text">المرضى</span></button></div>
                    <div className="btn-nav"><button className="nav-icon" onClick={selectSessions}><SlCalender color="white" size={30} /><span className="nav-text">الجلسات</span></button></div>
                    <div className="btn-nav"><button className="nav-icon" onClick={selectPayments}><FaMoneyCheckDollar color="white" size={30} /><span className="nav-text">الحسابات</span></button></div>
                </div>
            </nav>}
        </div>
    )
}

export default NavBar;