import { useContext, useEffect, useState } from "react";
import Tooth from "../assets/tooth.png";
import { IdContext } from "./userIdContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import GetTreats from "./getTreats";
import Treats from "./Treats";
import { useMediaQuery } from "react-responsive";
import "./AddData.css";
import { PiToothFill } from "react-icons/pi";
import { FaRegSave } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { loginkey } from "./LoginContext";
import LogIn from "./login";

function Sessions() {
    const [date, setDate] = useState();
    const [uploadMessage, setUploadMessage] = useState();
    const [sessionsNum, setSessionsNum] = useState();
    const [infoooNum, setInfoooNum] = useState([]);
    const [infooosessionsNum, setInfoooSessionsNum] = useState([]);
    const [message, setMessage] = useState("");
    const [messageSessionNum, setmessageSessionNum] = useState("");
    const { userId } = useContext(IdContext);
    const { setUserId } = useContext(IdContext);
    const { auth } = useContext(loginkey)
    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const addSessionsnum = async (sessionNums) => {
        //console.log(`snum from get ${sessionsNum}`)
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/addsessionsnum.php?userid=${userId}&sessionsnum=${sessionNums}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('تم')
                }
                return setInfoooNum(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };

    async function getSessionsNum() {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getsessionsnum.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        setSessionsNum(e.sessionsnum)
                        addSessionsnum(e.sessionsnum);
                        setmessageSessionNum('تم')
                    })

                }
                return setInfoooSessionsNum(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };

    function handleAxiosSubmit(event) {
        event.preventDefault();
        setUploadMessage('... يرجى الانتظار')
        const url = 'https://dr-meladjouriah.fxcode.tech/php/addSession.php';
        const formData = new FormData();
        formData.append('date', date);
        formData.append('userid', userId);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setUploadMessage("تمت العملية بنجاح")
                getSessionsNum()
                navToAdd();
            }
            console.log(response);
        })
            .catch(error => {
                setUploadMessage(error.message)
                console.log(error);
            });
    }
    const navigate = useNavigate();
    function navToAdd() {
        navigate("/Treatments", { replace: true });
    }
    function back() {
        window.history.pushState({ id: 1 }, null, "GetSession")
        navigate("/GetSession", { replace: true });
    }
    /*useEffect(() => {
        setUserId(window.localStorage.getItem("userid"))
        console.log(`vvvv ${window.localStorage.getItem("userid")}`);
    }, [!userId])
    useEffect(() => {
        if (userId) {
            window.localStorage.setItem("userid", userId);
        }
    }, [])*/
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    /*useEffect(()=>{
         window.history.pushState({id:1},null,"AddSession")
     },[])*/
    return (
        <div>
            {auth == 1 && <div>{isDesktopOrLaptop && <div className="container-add">
                <div className="session-container">
                    <form className="add-form-session" onSubmit={handleAxiosSubmit}>
                        <img src={Tooth} className="tooth-img"></img>
                        <div className="title-add">إضافة جلسة</div>
                        <input type="date" className="form-control form-input" onChange={(event) => handleDateChange(event)} placeholder="التاريخ" required></input>
                        <div className="btn-container">
                            <button type="submit" className="btn btn-success add-button">إضافة</button>
                            <button className="btn btn-dark add-button" onClick={back}>رجوع</button>
                        </div>
                        {uploadMessage && <div className="label-text">{uploadMessage}</div>}
                    </form>
                </div>
            </div>
            }
            </div>}
            {auth == 1 && <div>{isTabletOrMobile && <div className="session-container-mobile">
                <form className="add-form-mobile" onSubmit={handleAxiosSubmit}>
                    <img src={Tooth} className="tooth-img-mobile"></img>
                    <div className="title-add-mobile">إضافة جلسة</div>
                    <input type="date" className="form-control form-input-mobile" onChange={(event) => handleDateChange(event)} required></input>
                    {uploadMessage && <div className="label-text">{uploadMessage}</div>}
                    <div className="btn-container-mobile">
                        <button type="submit" className="btn btn-success add-button-mobile">إضافة</button>
                        <button className="btn btn-dark add-button-mobile" onClick={back}>رجوع</button>
                    </div>
                </form>
            </div>}
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default Sessions;