import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginkey } from "./LoginContext";
import LogIn from "./login";
import { IdContext } from "./userIdContext";
import { SessionContext } from "./SessionContext";
import { useMediaQuery } from "react-responsive";
import { TabContext } from "./tabindexContext";
import { IoMdAdd } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";

function SessionProfile() {
    const [infooo, setInfooo] = useState([]);
    const [treats, setTreats] = useState([]);
    const [message, setMessage] = useState("");
    const [toAdd, setToAdd] = useState(false);
    const [addDetails, setAddDetails] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [treatsNum, setTreatsNum] = useState();
    const { auth } = useContext(loginkey);
    const { userId } = useContext(IdContext);
    const {setUserId} = useContext(IdContext)
    const { sessionId } = useContext(SessionContext);
    const { setSessionId } = useContext(SessionContext);
    const getData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getidSessions.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getTreats = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getidTreatsnums.php?sessionid=${sessionId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setTreats(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
        //console.log(`sessionid ${sessionId}`)
    };
   /* useEffect(() => {
        setUserId(window.localStorage.getItem("userid"))
        console.log(`vvvv ${window.localStorage.getItem("userid")}`);
    }, [!userId])
    useEffect(() => {
        if (userId) {
            window.localStorage.setItem("userid", userId);
        }
    }, [])*/
    useEffect(() => {
        getData();
        getTreats();
    }, [sessionId]);
    const navigate = useNavigate();
    function navToAdd() {
        setToAdd(true)
        navigate("/AddSession", { replace: true });
    }
    function navToTreats() {
        setSessionId(selectedId);
        setToAdd(true)
        navigate("/TreatsList", { replace: true });
    }
    function back() {
        navigate("/GetPatients", { replace: true });
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <div className="container-payments-sessions">
            {isDesktopOrLaptop && <div>
                {!toAdd && <div >
                    <h4 className="text">معلومات الجلسات</h4>
                    <div className="btn-add-container-profile">
                        <button className="btn btn-success btn-add" onClick={navToAdd}><div><IoMdAdd/></div></button>
                        <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack/></div></button>
                    </div>
                    <div className="items-container">
                        <table className="table-cell">
                            <tr className="item-table-title">
                                <th className="item">التاريخ</th>
                                <th className="item">تفاصيل</th>
                            </tr>
                            {!message && <div className="loading-all">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">يرجى الانتظار...</span>
                                </div>
                                <h2 className="loading">يرجى الانتظار...</h2>
                            </div>}
                            {infooo.map((e) => {
                                function showDetails() {
                                    setAddDetails(true);
                                    setSelectedId(e.id)
                                    showNum()
                                }
                                function showNum() {
                                    setTreatsNum(e.date)
                                    setSessionId(e.id);
                                }
                                return (
                                    <tr className="item-table" onClick={showDetails}>
                                        <td className="item">{e.date}</td>
                                        <td className="item"><button className="btn btn-success" onClick={navToTreats}>المعالجات</button></td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                }
            </div>}
            {isTabletOrMobile && <div className="container-get-mobile">
                {!toAdd && <div className="container-profile-mobile">
                    <h4 className="text">معلومات الجلسات</h4>
                    <div className="btn-add-container-profile-mobile">
                        <button className="btn btn-success btn-add" onClick={navToAdd}><div><IoMdAdd/></div></button>
                        <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack/></div></button>
                    </div>
                    <div className="items-container-profile-mobile">
                        <table className="table-cell-mobile">
                            <tr className="item-table-title-mobile">
                                <th className="item th-mobile">التاريخ</th>
                                <th className="item th-mobile">التفاصيل</th>
                            </tr>
                            {!message && <div className="loading-all">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">يرجى الانتظار...</span>
                                </div>
                                <h2 className="loading">يرجى الانتظار...</h2>
                            </div>}
                            {infooo.map((e) => {
                                function showDetails() {
                                    setAddDetails(true);
                                    setSelectedId(e.id)
                                    showNum()
                                }
                                function showNum() {
                                    setTreatsNum(e.date)
                                    setSessionId(e.id);
                                }
                                return (
                                    <tr className="item-table-mobile" onClick={showDetails}>
                                        <td className="item">{e.date}</td>
                                        <td className="item"><button className="btn btn-success" onClick={navToTreats}>المعالجات</button></td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                }
            </div>}
        </div>
    );
}

export default SessionProfile;