import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./RestPassword.css";
import { useMediaQuery } from "react-responsive";

function RestPassword() {
    const [uploadMessage, setUploadMessage] = useState("");
    const [submitMessage, setSubmitMessage] = useState("");
    const [renewMessage, setRenewMessage] = useState("");
    const [codeMessage, setCodeMessage] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [sentCode, setSentCode] = useState(false);
    const [code, setCode] = useState("");
    const [renew, setRenew] = useState(false);
    const [infoo, setInfoo] = useState([]);

    function sendEmail(event) {
        event.preventDefault();
        setUploadMessage('... يرجى الانتظار')
        const url = 'https://dr-meladjouriah.fxcode.tech/php/sendemail.php';
        const formData = new FormData();
        formData.append('email', email);
        formData.append('message', message);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setUploadMessage("تم")
            }
            console.log(response);
        })
            .catch(error => {
                setMessage('تم')
                setUploadMessage(error.message)
                console.log(error);
            });
        setSentCode(true)
    }

    const getRest = async (event) => {
        event.preventDefault();
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/rest.php?code=${code}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    setMessage('يرجى الانتظار')
                    throw Error('Could not fetch the data')
                }
                else if (response.ok) {
                    setMessage('تم')
                    return response.json()
                }
            })
            .then(async (data) => {
                if (data.values.length > 0) {
                    setCodeMessage('تم')
                }
                else {
                    setCodeMessage("رمز التفعيل خاطئ")
                }
                console.log(data)
                return (
                    setInfoo(data.values));
            })
            .catch(err => {
                setMessage(err.message)
            })
    };

    function handleAxiosSubmit(event) {
        event.preventDefault();
        setSubmitMessage('... يرجى الانتظار')
        const url = 'https://dr-meladjouriah.fxcode.tech/php/restpassword.php';
        const formData = new FormData();
        formData.append('newPassword', newPassword);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setSubmitMessage("تم")
                refreshPage();
            }
            console.log(response);
        })
            .catch(error => {
                setSubmitMessage(error.message)
                console.log(error);
            });
        /* if (submitMessage === "success") {
             navToLogin();
         }*/
    }
    const navigate = useNavigate();
    function navToLogin() {
        navigate("/", { replace: true });
    }

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    }
    const handlereNewChange = () => {
        setRenew(true);
    }
    var sha1 = require('sha1');

    console.log(`sha sha ${sha1("h")}`);
    const handleNewPasswordChange = (e) => {
        setNewPassword(sha1(`${e.target.value}`));
    }
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(sha1(`${e.target.value}`));
    }
    function refreshPage() {
        window.location.reload();
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <div>
            {isDesktopOrLaptop && <div className="rest">
                <div className="container-send">
                    {!sentCode && <div>من فضلك انقر على الزر لإرسال رمز التفعيل إلى الإيميل الخاص بك</div>}
                    {!sentCode && <button className="btn btn-dark" onClick={sendEmail}>أرسل رمز التفعيل</button>}
                    <div className="submit-container">
                        {sentCode && <div>من فضلك أدخل رمز التفعيل</div>}
                        {sentCode && <form onSubmit={getRest} className="form-submit">
                            <input className="form-control" onChange={(event) => { handleCodeChange(event) }} required></input>
                            <div className="submit-btn-container">
                                <button className="btn btn-dark submit-btn" onClick={handlereNewChange}>تأكيد</button>
                            </div>
                            {codeMessage && <div>{codeMessage}</div>}
                        </form>}
                    </div>
                </div>
                {renew && infoo.length > 0 && <div>
                    <form className="renew-form" onSubmit={handleAxiosSubmit}>
                        <input type="password" className="form-control" placeholder="كلمة السر الجديدة" onChange={(event) => { handleNewPasswordChange(event) }} required></input>
                        <input type="password" className="form-control" placeholder="تأكيد كلمة السر الجديدة" onChange={(event) => { handleConfirmPasswordChange(event) }} required></input>
                        <button type="submit" className="btn btn-dark renew-btn" disabled={newPassword !== confirmPassword} >تأكيد</button>
                        {submitMessage && <div>{submitMessage}</div>}
                    </form>
                </div>
                }
            </div>
            }
            {isTabletOrMobile && <div className="rest-mobile">
                <div className="container-send-mobile">
                    {!sentCode && <div>من فضلك انقر على الزر لإرسال رمز التفعيل إلى الإيميل الخاص بك</div>}
                    {!sentCode && <button className="btn btn-dark" onClick={sendEmail}>أرسل رمز التفعيل</button>}
                    <div className="submit-container-mobile">
                        {sentCode && <div>من فضلك أدخل رمز التفعيل</div>}
                        {sentCode && <form onSubmit={getRest} className="form-submit-mobile">
                            <input className="form-control input-form-mobile" onChange={(event) => { handleCodeChange(event) }} required></input>
                            <div className="submit-btn-container-mobile">
                                <button className="btn btn-dark submit-btn" onClick={handlereNewChange}>تأكيد</button>
                            </div>
                            {codeMessage && <div>{codeMessage}</div>}
                        </form>}
                    </div>
                </div>
                {renew && infoo.length > 0 && <div>
                    <form className="renew-form-mobile" onSubmit={handleAxiosSubmit}>
                        <input type="password" className="form-control input-form-mobile" placeholder="كلمة السر الجديدة" onChange={(event) => { handleNewPasswordChange(event) }} required></input>
                        <input type="password" className="form-control input-form-mobile" placeholder="تأكيد كلمة السر الجديدة" onChange={(event) => { handleConfirmPasswordChange(event) }} required></input>
                        <button type="submit" className="btn btn-dark renew-btn" disabled={newPassword !== confirmPassword} >تأكيد</button>
                        {submitMessage && <div>{submitMessage}</div>}
                    </form>
                </div>
                }
            </div>}
        </div>
    );
}

export default RestPassword;