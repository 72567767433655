import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginkey } from "./LoginContext";
import LogIn from "./login";
import { IdContext } from "./userIdContext";
import { SessionContext } from "./SessionContext";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { TabContext } from "./tabindexContext";
import { PiToothFill } from "react-icons/pi";
import { IoMdAdd } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AddDetailsContext } from "./AddDetailsContext";
import Popup from "react-widget-popup";

function GetSessions() {
    const [infooo, setInfooo] = useState([]);
    const [sessionsNum, setSessionsNum] = useState();
    const [infooosessionsNum, setInfoooSessionsNum] = useState([]);
    const [treats, setTreats] = useState([]);
    const [infoooNum, setInfoooNum] = useState([]);
    const [open, setOpen] = useState("");
    const [infoooCost, setInfoooCost] = useState([]);
    const [infoooSum, setInfoooSum] = useState([]);
    const [messageAddCosts, setMessageAddCosts] = useState("");
    const [sumCost, setSumCost] = useState("");
    const [message, setMessage] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [messageSessionNum, setmessageSessionNum] = useState("");
    const [messageNum, setMessageNum] = useState("");
    const [toAdd, setToAdd] = useState(false);
    const [addDetails, setAddDetails] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [treatsNum, setTreatsNum] = useState();
    const { auth } = useContext(loginkey);
    const { setUserId } = useContext(IdContext);
    const { userId } = useContext(IdContext);
    const { sessionId } = useContext(SessionContext);
    const { setSessionId } = useContext(SessionContext);
    const [personalInfo, setPersonalInfo] = useState([]);
    const { tab } = useContext(TabContext);
    const { setTab } = useContext(TabContext);
    const { setAddDetailsContextt } = useContext(AddDetailsContext)
    async function getData() {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getidSessions.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then(async (data) => {
                if (data.successful) {
                    console.log(`snum ${sessionsNum}`)
                    await getSessionsNum();
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };

    const addSessionsnum = async (sessionNums) => {
        console.log(`snum from get ${sessionsNum}`)
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/addsessionsnum.php?userid=${userId}&sessionsnum=${sessionNums}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfoooNum(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };

    async function getSessionsNum() {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getsessionsnum.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        setSessionsNum(e.sessionsnum)
                        addSessionsnum(e.sessionsnum)
                        setmessageSessionNum('Done')
                    })

                }
                return setInfoooSessionsNum(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getCostData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getCosts.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                    data.values.map((e) => {
                        console.log(`sumcost ${e.sumcost}`);
                        if (e.sumcost == null) {
                            addSum(0)
                        } else {
                            addSum(e.sumcost);
                        }
                        setSumCost(e.sumcost)
                    })
                }
                return setInfoooCost(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
        console.log(`infoCostfunc ${infoooCost}`)
    };
    const addSum = async (sumValue) => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/addcostssum.php?userid=${userId}&costssum=${sumValue}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessageAddCosts('Done')
                }
                return setInfoooSum(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };


    const getPersonalData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getPatientsid.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setPersonalInfo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
        setSessionsNum(infooo.length);
    };

    const getTreats = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getidTreatsnums.php?sessionid=${sessionId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessageNum('Done')
                }
                return setTreats(data.values)

            })
            .catch(err => {
                setMessageNum(err)
            })
        /*console.log(`sessionid ${sessionId}`)
        console.log(`treatsnum ${treats}`)*/
    };

    function handleDelete() {
        const url = `https://dr-meladjouriah.fxcode.tech/php/deleteSession.php?sessionid=${sessionId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                getData();
                handleDeleteTreats();
                getCostData();
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }

    function handleDeleteTreats() {
        setDeleteMessage("... يرجى الانتظار")
        const url = `https://dr-meladjouriah.fxcode.tech/php/deleteTreatsofSession.php?sessionid=${sessionId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setDeleteMessage("تم الحذف")
                getCostData();
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }

    const navigate = useNavigate();
    function navToAdd() {
        setToAdd(true)
        navigate("/AddSession", { replace: true });
    }
    function navToTreats() {
        setSessionId(selectedId);
        setToAdd(true)
        //window.history.pushState({ id: 1 }, null, "TreatsList")
        navigate("/TreatsList", { replace: true });
    }
    function back() {
        //addSessionsnum();
        setToAdd(true)
        window.history.pushState({ id: 1 }, null, "GetPatients")
        navigate("/GetPatients", { replace: true });
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    /*useEffect(() => {
        setUserId(window.localStorage.getItem("userid"))
        console.log(`vvvv ${window.localStorage.getItem("userid")}`);
    }, [!userId])
    useEffect(() => {
        if (userId) {
            window.localStorage.setItem("userid", userId);
        }
    }, [])*/
    useEffect(() => {
        getData();
        getTreats();
        getPersonalData();
        getCostData();
        console.log(`snum from effect ${sessionsNum}`)
        setAddDetailsContextt(true);
    }, [sessionId]);
    useEffect(() => {
        getTreats();
    }, []);
    /*useEffect(()=>{
        window.history.pushState({id:1},null,"GetSession")
    },[])*/
    setTab(0)
    return (
        <div>
            {auth == 1 && <div>{isDesktopOrLaptop && <div className="container-get">
                {!toAdd && <div className="container-v">
                    <div className="text-path">
                        الجلسات/
                        {personalInfo.map((e) => {
                            return (
                                <div className="text">{e.name}</div>
                            )
                        })}
                    </div>
                    <h1 className="text">الجلسات <PiToothFill /></h1>
                    <div className="btn-add-container-control">
                        <div className="btns-controls">
                            <button className="btn btn-success btn-add" onClick={navToAdd}><div><IoMdAdd /></div></button>
                            <button className="btn btn-dark btn-add" disabled={!messageSessionNum} onClick={back}><div><IoMdArrowRoundBack /></div></button>
                        </div>
                    </div>
                    <div className="items-container">
                        <table className="table-cell">
                            <tr className="item-table-title">
                                <th className="item">التاريخ</th>
                                <th className="item">المعالجات</th>
                                <th className="item">خيارات</th>
                            </tr>
                            {!message && <div className="loading-all">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">يرجى الانتظار...</span>
                                </div>
                                <h2 className="loading">يرجى الانتظار...</h2>
                            </div>}
                            {infooo.map((e) => {
                                function handleBackPopup() {
                                    setOpen("close");
                                }

                                function handleOpenPopup() {
                                    setOpen(e.id);
                                    setDeleteMessage("")
                                }
                                function showDetails() {
                                    setAddDetails(true);
                                    setSelectedId(e.id)
                                    setTab(1);
                                    showNum()
                                }
                                function showNum() {
                                    setTreatsNum(e.date)
                                    setSessionId(e.id);
                                }
                                return (
                                    <tr className="item-table" tabIndex={tab} onClick={showDetails}>
                                        <td className="item">{e.date}</td>
                                        <td className="item"><button className="btn btn-success" onClick={navToTreats}>المعالجات
                                            {selectedId == e.id && <div className="item text treat-num-text">{treats.length}</div>}</button></td>
                                        <td style={{ width: "20%" }}><div className="tools">
                                            {open === e.id && <Popup
                                                visible={true}
                                                style={{
                                                    border: "2px solid white",
                                                    paddingTop: "20px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                                    top: 100,
                                                    width: "100%",
                                                }}>
                                                <div className="delete-popup">
                                                    <h4>هل تريد بالتأكيد الحذف؟</h4>
                                                    <div className="btns-popup">
                                                        <button className="btn btn-danger" onClick={handleDelete}>حذف</button>
                                                        <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                                    </div>
                                                    {deleteMessage && <div className="loading-all">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden label-text">{deleteMessage}</span>
                                                        </div>
                                                        <h2 className="loading label-text">{deleteMessage}</h2>
                                                    </div>}
                                                </div>
                                            </Popup>}
                                            {open !== e.id && <button className="btn btn-danger" onClick={handleOpenPopup} position="left center"><RiDeleteBin6Line /></button>}
                                        </div></td>

                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                }
            </div>
            }
            </div>}
            {auth == 1 && <div>{isTabletOrMobile && <div className="container-get-mobile">
                {!toAdd && <div className="container-v-mobile">
                    <div className="btn-add-container-control-mobile">
                        <div className="btns-controls-mobile">
                            <button className="btn btn-success btn-add" onClick={navToAdd}><div><IoMdAdd /></div></button>
                            <button className="btn btn-dark btn-add" disabled={!messageSessionNum} onClick={back}><div><IoMdArrowRoundBack /></div></button>
                        </div>
                    </div>
                    <div className="items-container-mobile">
                        <table className="table-cell-mobile">
                            <tr className="item-table-title-mobile">
                                <th className="item th-mobile">التاريخ</th>
                                <th className="item th-mobile">المعالجات</th>
                                <th className="item th-mobile">خيارات</th>
                            </tr>
                            {!message && <div className="loading-all">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">يرجى الانتظار...</span>
                                </div>
                                <h2 className="loading">يرجى الانتظار...</h2>
                            </div>}
                            {infooo.map((e) => {
                                function handleBackPopup() {
                                    setOpen("close");
                                }

                                function handleOpenPopup() {
                                    setOpen(e.id);
                                    setDeleteMessage("")
                                }
                                function showDetails() {
                                    setAddDetails(true);
                                    setSelectedId(e.id)
                                    showNum()
                                }
                                function showNum() {
                                    setTreatsNum(e.date)
                                    setSessionId(e.id);
                                }
                                return (
                                    <tr className="item-table-mobile" tabIndex={tab} onClick={showDetails}>
                                        <td className="item">{e.date}</td>
                                        <td className="item"><button className="btn btn-success" onClick={navToTreats}>المعالجات
                                            {selectedId == e.id && <div className="item text treat-num-text">{treats.length}</div>}</button></td>
                                        <td style={{ width: "20%" }}><div className="tools">
                                            {open === e.id && <Popup
                                                visible={true}
                                                style={{
                                                    border: "2px solid white",
                                                    paddingTop: "20px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                                    top: 100,
                                                    width: "100%",
                                                }}>
                                                <div className="delete-popup">
                                                    <h6>هل تريد بالتأكيد الحذف؟</h6>
                                                    <div className="btns-popup-mobile">
                                                        <button className="btn btn-danger" onClick={handleDelete}>حذف</button>
                                                        <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                                    </div>
                                                    {deleteMessage && <div className="loading-all">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden label-text">{deleteMessage}</span>
                                                        </div>
                                                        <h2 className="loading label-text">{deleteMessage}</h2>
                                                    </div>}
                                                </div>
                                            </Popup>}
                                            {open !== e.id && <button className="btn btn-danger" onClick={handleOpenPopup} position="left center"><RiDeleteBin6Line /></button>}
                                        </div></td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                }
            </div>}
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default GetSessions;

/*{open === e.id && <Popup
                                            visible={true}
                                            style={{
                                                border: "2px solid white",
                                                paddingTop: "20px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                                top: 100,
                                                width: "300px",
                                            }}>
                                            <div className="delete-popup">
                                                <h4>هل تريد بالتأكيد الحذف؟</h4>
                                                <div className="btns-popup">
                                                    <button className="btn btn-danger" onClick={handleDelete}>حذف</button>
                                                    <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                                </div>
                                            </div>
                                        </Popup>}*/