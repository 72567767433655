import { useContext, useEffect, useState } from "react";
import { TabContext } from "./tabindexContext";
import { IdContext } from "./userIdContext";
import { Link, useNavigate } from "react-router-dom";
import { IoMdAdd, IoMdPerson, IoMdPersonAdd } from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { IoPerson } from "react-icons/io5";
import { IoSearchOutline } from "react-icons/io5";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import { AiOutlineMenu } from "react-icons/ai";
import { PiTooth } from "react-icons/pi";
import { CiDollar } from "react-icons/ci";

import { AddDetailsContext } from "./AddDetailsContext";
import Popup from "react-widget-popup";
import { FaTeethOpen } from "react-icons/fa";
import { TbCurrencyDollarSingapore } from "react-icons/tb";
import { FaCircleDollarToSlot } from "react-icons/fa6";

function PersonalInfoTable() {
    const [infooo, setInfooo] = useState([]);
    const [message, setMessage] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [addDetails, setAddDetails] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [open, setOpen] = useState("");
    const [search, setSearch] = useState("");
    const { tab } = useContext(TabContext);
    const { setTab } = useContext(TabContext);
    const { setUserId } = useContext(IdContext);
    const { setAddDetailsContextt } = useContext(AddDetailsContext)
    const getData = async () => {
        await fetch("https://dr-meladjouriah.fxcode.tech/php/getPatients.php",
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    function handleDelete() {
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePatient.php?userid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                handleDeleteSessions();
                setSelectedId("")
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    function handleDeleteSessions() {
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePatientSession.php?userid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                handleDeleteTreats();
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    function handleDeleteTreats() {
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePatientTreat.php?userid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                handleDeletePayments();
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    function handleDeletePayments() {
        setDeleteMessage("... يرجى الانتظار")
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePatientPayment.php?userid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setDeleteMessage("تم الحذف")
                getData();
            }
            setDeleteMessage("")
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    const getSearchData = async (searchValue) => {
        //e.preventDefault();
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/search.php?search=${searchValue}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const handleSearchChange = (e) => {
        if (e.target.value === "") {
            getData()
        }
        if (e.target.value !== "") {
            getSearchData(e.target.value)
        }
        setSearch(e.target.value);
    }
    const navigate = useNavigate();
    function navToAdd() {
        navigate("/AddPatients", { replace: true });
    }

    function addSession() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        navigate("/AddSession", { replace: true });
    }

    function addPayment() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        navigate("/AddPayment", { replace: true });
    }
    function navToSessions() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        window.history.pushState({ id: 1 }, null, "GetSession")
        navigate("/GetSession", { replace: true });
    }

    function navToPayments() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        window.history.pushState({ id: 1 }, null, "GetPayments")
        navigate("/GetPayments", { replace: true });
    }
    function navToDetails() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        window.history.pushState({ id: 1 }, null, "Profile")
        navigate("/Profile", { replace: true });
    }
    function deleteData() {
        setUserId(selectedId);
        handleDelete();
    }
    function edit() {
        setUserId(selectedId);
        navigate("/EditDetails", { replace: true });
    }
    useEffect(() => {
        getData();
    }, []);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1240px)'
    })
    const isSmallLap = useMediaQuery({
        query: '(min-width: 1224px) and (max-width: 1240px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    console.log(`selected--id ${selectedId}`)
    return (
        <div>
            {isSmallLap && <div>
                <div className="btn-add-container-smallLap">
                    <div className="search-container">
                        <form className="search-form">
                            <input type="text" className="form-control" placeholder="البحث" onChange={handleSearchChange}></input>
                        </form>
                    </div>
                    <div className="btns-controls">
                        <button className="btn btn-primary btn-submit" onClick={addSession} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><PiTooth />+</div> </button>
                        <button className="btn btn-primary btn-submit" onClick={addPayment} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><CiDollar />+</div> </button>
                        <button className="btn btn-success btn-submit" onClick={navToAdd}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><IoMdPerson />+</div></button>

                    </div>
                </div>
                <div className="items-container-smallLap">
                    <table className="table-cell">
                        <tr className="item-table-title">
                            <th className="item">الاسم</th>
                            <th className="item">العمر</th>
                            <th className="item">الجنس</th>
                            <th className="item">رقم الهاتف</th>
                            <th className="item">ملاحظات</th>
                            <th className="item">خيارات</th>
                        </tr>
                        {!message && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">... يرجى الانتظار</span>
                            </div>
                            <h2 className="loading">... يرجى الانتظار</h2>
                        </div>}
                        {infooo.map((e) => {
                            function handleBackPopup() {
                                setOpen("close");
                            }

                            function handleOpenPopup() {
                                setDeleteMessage("")
                                setOpen(e.id);
                            }
                            function showDetails() {
                                setAddDetails(true);
                                setSelectedId(e.id);
                                setTab(1);
                            }
                            return (
                                <tr className="item-table" tabIndex={tab} onClick={showDetails}>
                                    <td className="item">{e.name}</td>
                                    <td className="item">{e.age}</td>
                                    <td className="item">{e.sex}</td>
                                    <td className="item">0{e.phone}</td>
                                    <td className="item">{e.notes}</td>
                                    <td className="item" onClick={showDetails}>{open !== e.id && <div className="tools">
                                        <div className="nav-bar-patients">
                                            <Menu menuButton={<MenuButton className="m-btn"><AiOutlineMenu color='white' size={25} /></MenuButton>}>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-primary nav-item" onClick={navToSessions}><FaTeethOpen /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-info nav-item" onClick={navToPayments}><FaCircleDollarToSlot /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className="btn btn-light" onClick={edit}><div><BiEditAlt /></div></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <a href="#d" className="btn btn-danger" onClick={handleOpenPopup} position="left center"><RiDeleteBin6Line /></a>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-warning nav-item" onClick={navToDetails}><IoPerson /></button>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </div>}
                                        {open === e.id && <Popup
                                            id="d"
                                            visible={true}
                                            style={{
                                                border: "2px solid white",
                                                paddingTop: "20px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                                top: 100,
                                                width: "100%",
                                            }}>
                                            <div className="delete-popup">
                                                <h4>هل تريد بالتأكيد الحذف؟</h4>
                                                <div className="btns-popup">
                                                    <button className="btn btn-danger" onClick={handleDelete}>حذف</button>
                                                    <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                                </div>
                                                {deleteMessage && <div className="loading-all">
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden label-text">{deleteMessage}</span>
                                                    </div>
                                                    <h2 className="loading label-text">{deleteMessage}</h2>
                                                </div>}
                                            </div>
                                        </Popup>}
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>}
            {isDesktopOrLaptop && <div>
                <div className="btn-add-container">
                    <div className="search-container">
                        <form className="search-form">
                            <input type="text" className="form-control" placeholder="البحث" onChange={handleSearchChange}></input>
                        </form>
                    </div>
                    <div className="btns-controls">
                        <button className="btn btn-primary btn-submit" onClick={addSession} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><PiTooth />+</div> </button>
                        <button className="btn btn-primary btn-submit" onClick={addPayment} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><CiDollar />+</div> </button>
                        <button className="btn btn-success btn-submit" onClick={navToAdd}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><IoMdPerson />+</div></button>

                    </div>
                </div>
                <div className="items-container">
                    <table className="table-cell">
                        <tr className="item-table-title">
                            <th className="item">الاسم</th>
                            <th className="item">العمر</th>
                            <th className="item">الجنس</th>
                            <th className="item">رقم الهاتف</th>
                            <th className="item">ملاحظات</th>
                            <th className="item">خيارات</th>
                        </tr>
                        {!message && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">... يرجى الانتظار</span>
                            </div>
                            <h2 className="loading">... يرجى الانتظار</h2>
                        </div>}
                        {infooo.map((e) => {
                            function handleBackPopup() {
                                setOpen("close");
                            }

                            function handleOpenPopup() {
                                setOpen(e.id);
                            }
                            function showDetails() {
                                setAddDetails(true);
                                setSelectedId(e.id);
                                setTab(1);
                            }
                            return (
                                <tr className="item-table" tabIndex={tab} onClick={showDetails}>
                                    <td className="item">{e.name}</td>
                                    <td className="item">{e.age}</td>
                                    <td className="item">{e.sex}</td>
                                    <td className="item">0{e.phone}</td>
                                    <td className="item">{e.notes}</td>
                                    <td className="item" onClick={showDetails}>{open !== e.id && <div className="tools">
                                        <div className="nav-bar-patients">
                                            <Menu menuButton={<MenuButton className="m-btn"><AiOutlineMenu color='white' size={25} /></MenuButton>}>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-primary nav-item" onClick={navToSessions}><FaTeethOpen /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-info nav-item" onClick={navToPayments}><FaCircleDollarToSlot /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className="btn btn-light" onClick={edit}><div><BiEditAlt /></div></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <a href="#d" className="btn btn-danger" onClick={handleOpenPopup} position="left center"><RiDeleteBin6Line /></a>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-warning nav-item" onClick={navToDetails}><IoPerson /></button>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </div>}
                                        {open === e.id && <Popup
                                            id="d"
                                            visible={true}
                                            style={{
                                                border: "2px solid white",
                                                paddingTop: "20px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                                top: 100,
                                                width: "100%",
                                            }}>
                                            <div className="delete-popup">
                                                <h4>هل تريد بالتأكيد الحذف؟</h4>
                                                <div className="btns-popup">
                                                    <button className="btn btn-danger" onClick={handleDelete}>حذف</button>
                                                    <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                                </div>
                                                {deleteMessage && <div className="loading-all">
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden label-text">{deleteMessage}</span>
                                                    </div>
                                                    <h2 className="loading label-text">{deleteMessage}</h2>
                                                </div>}
                                            </div>
                                        </Popup>}
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>}
            {isTabletOrMobile && <div>
                <div className="btn-add-container-mobile">
                    <div className="search-container-mobile">
                        <form className="search-form-mobile">
                            <input type="text" className="form-control" placeholder="البحث" onChange={handleSearchChange}></input>
                        </form>
                    </div>
                    <div className="btns-controls">
                        <button className="btn btn-primary btn-submit" onClick={addSession} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><PiTooth />+</div> </button>
                        <button className="btn btn-primary btn-submit" onClick={addPayment} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><CiDollar />+</div> </button>
                        <button className="btn btn-success btn-submit" onClick={navToAdd}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><IoMdPerson />+</div></button>
                    </div>
                </div>
                <div className="items-container-mobile">
                    <table className="table-cell-mobile">
                        <tr className="item-table-title-mobile">
                            <th className="item">الاسم</th>
                            <th className="item">رقم الهاتف</th>
                            <th className="item">خيارات</th>
                        </tr>
                        {!message && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">... يرجى الانتظار</span>
                            </div>
                            <h2 className="loading">... يرجى الانتظار</h2>
                        </div>}
                        {infooo.map((e) => {
                            function handleBackPopup() {
                                setOpen("close");
                            }

                            function handleOpenPopup() {
                                setDeleteMessage("")
                                setOpen(e.id);
                            }
                            function showDetails() {
                                setAddDetails(true);
                                setSelectedId(e.id);
                                setTab(1);
                            }
                            return (
                                <tr className="item-table-mobile" tabIndex={tab} onClick={showDetails}>
                                    <td className="item">{e.name}</td>
                                    <td className="item">0{e.phone}</td>
                                    <td className="item"> {open !== e.id && <div className="tools">
                                        <div className="nav-bar-patients">
                                            <Menu menuButton={<MenuButton className="m-btn" style={{ overflowY: "scroll" }}><AiOutlineMenu color='white' size={25} /></MenuButton>}>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-primary nav-item" onClick={navToSessions}><FaTeethOpen /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-info nav-item" onClick={navToPayments}><FaCircleDollarToSlot /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className="btn btn-light" onClick={edit}><div><BiEditAlt /></div></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <a href="#d" className="btn btn-danger" onClick={handleOpenPopup} position="left center"><RiDeleteBin6Line /></a>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-warning nav-item" onClick={navToDetails}><IoPerson /></button>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </div>}
                                        {open === e.id && <Popup
                                            id="d"
                                            visible={true}
                                            style={{
                                                border: "2px solid white",
                                                paddingTop: "20px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                                top: 100,
                                                width: "100%",
                                            }}>
                                            <div className="delete-popup">
                                                <h4>هل تريد بالتأكيد الحذف؟</h4>
                                                <div className="btns-popup">
                                                    <button className="btn btn-danger" onClick={handleDelete}>حذف</button>
                                                    <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                                </div>
                                                {deleteMessage && <div className="loading-all">
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden label-text">{deleteMessage}</span>
                                                    </div>
                                                    <h2 className="loading label-text">{deleteMessage}</h2>
                                                </div>}
                                            </div>
                                        </Popup>}
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>}
        </div>
    );
}

export default PersonalInfoTable;

/*                        <button className="btn btn-light btn-add" onClick={edit} disabled={!selectedId}><div><BiEditAlt /></div> </button>
                        <button className="btn btn-danger btn-add" onClick={deleteData} disabled={!selectedId}><div><RiDeleteBin6Line /></div> </button>*/


/*<div className="btns-controls-mobile">
<Menu menuButton={<MenuButton className="m-btn"><AiOutlineMenu color='white' size={25} /></MenuButton>}>
    <MenuItem className="menu-nav">
        <button className="btn btn-success btn-add" onClick={navToAdd}><div><IoMdPersonAdd /></div> </button>
    </MenuItem>
    <MenuItem className="menu-nav">
        <button className="btn btn-light btn-add" onClick={edit} disabled={!selectedId}><div><BiEditAlt /></div> </button>
    </MenuItem>
    <MenuItem className="menu-nav">
        <button className="btn btn-danger btn-add" onClick={deleteData} disabled={!selectedId}><div><RiDeleteBin6Line /></div> </button>
    </MenuItem>
</Menu>
</div>*/