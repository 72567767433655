import { createContext, useContext, useEffect, useState } from "react";
import "./login.css"
import axios from "axios";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import RestPassword from "./RestPassword";
import Sync from "./sync";
import { loginkey } from "./LoginContext";
import { useMediaQuery } from "react-responsive";
import React from "react";
import Register from "./register";

export const loginAuth = createContext(null);

function LogIn(props) {
    const [message, setMessage] = useState("");
    const [messageData, setMessageData] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [register, setRegister] = useState("0");
    const [infoo, setInfoo] = useState([]);
    const [infooLogin, setInfooLogin] = useState([]);
    const [forgot, setForgot] = useState(false);
    const [confirm, setConfirm] = useState(0);
    const { setAuth } = useContext(loginkey);

    const getLoginAllData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/loginData.php`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')
                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then(async (data) => {
                if (data.values.length === 0) {
                    setRegister("1")
                }
                if (data.values.length > 0) {
                    setRegister("2")
                }
                return (
                    setInfooLogin(data.values));
            })
            .catch(err => {
                setMessageData(err.message)
            })
    };

    const getLoginFetchData = async (event) => {
        event.preventDefault();
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/login.php?email=${email}&password=${password}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    setMessage('... يرجى الانتظار')
                    throw Error('Could not fetch the data')
                }
                else if (response.ok) {
                    setMessage('تم')
                    return response.json()
                }
            })
            .then(async (data) => {
                if (data.values.length === 0) {
                    setMessage("يرجى التأكد من كلمة السر أو الايميل")
                }
                if (data.values.length > 0) {
                    setConfirm(1)
                    setAuth(1);
                    setMessage('Success');
                    handlePushState();
                    navToSync();
                }
                return (
                    setInfoo(data.values));
            })
            .catch(err => {
                setMessage(err.message)
            })
    };
    var sha1 = require('sha1');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handlePasswordChange = (e) => {
        setPassword(sha1(`${e.target.value}`));
    }
    const navigate = useNavigate();
    function navToRest() {
        setForgot(true)
        // navigate("/RestPassword", { replace: true });
    }

    function navToSync() {
        setConfirm(1);
        setAuth(1);
        console.log(confirm)
        navigate("/GetPatients", { replace: true });
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    useEffect(() => {
        getLoginAllData();
    }, [])
    function handlePushState() {
        window.history.pushState({ id: 1 }, null, "GetPatients")
    }
    //console.log(window.location.href)
    return (
        <div>
            {isDesktopOrLaptop && <div className="login-page">
                {register === "0" && <div className="loading-all">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">... يرجى الانتظار</span>
                    </div>
                    <h2 className="loading">... يرجى الانتظار</h2>
                </div>}
                {register === "1" && <Register />}
                {register === "2" && <div className="container">
                    <div className="login-container" onSubmit={getLoginFetchData}>
                        {forgot && <RestPassword />}
                        {!forgot && <form className="login-form"><h4 className="title-login">تسجيل الدخول</h4>
                            <input type="email" className="form-control login-input" placeholder="الإيميل" onChange={(event) => { handleEmailChange(event) }}></input>
                            <input type="password" className="form-control login-input" placeholder="كلمة السر" onChange={(event) => { handlePasswordChange(event) }}></input>
                            <div className="btn-forgot">
                                <a className="forgot-link title-login" onClick={navToRest}>تسيت كلمة السر</a>
                            </div>
                            <div className="login-btn-container">
                                <button type="submit" className="btn btn-dark login-btn">تسجيل دخول</button>
                            </div>
                            {message && <div className="label-text">{message}</div>}
                        </form>
                        }
                    </div>
                </div>}
            </div>}
            {isTabletOrMobile && <div className="login-page-mobile">
                {register === "0" && <div className="loading-all">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">... يرجى الانتظار</span>
                    </div>
                    <h2 className="loading">... يرجى الانتظار</h2>
                </div>}
                {register === "1" && <Register />}
                {register === "2" && <div className="container">
                    <div className="login-container-mobile" onSubmit={getLoginFetchData}>
                        {forgot && <RestPassword />}
                        {!forgot && <form className="login-form-mobile"><h4 className="title-login">تسجيل الدخول</h4>
                            <input type="email" className="form-control login-input"placeholder="الإيميل" onChange={(event) => { handleEmailChange(event) }}></input>
                            <input type="password" className="form-control login-input" placeholder="كلمة السر" onChange={(event) => { handlePasswordChange(event) }}></input>
                            <div className="btn-forgot">
                                <a className="forgot-link title-login" onClick={navToRest}>نسيت كلمة السر</a>
                            </div>
                            <div className="login-btn-container-mobile">
                                <button type="submit" className="btn btn-dark login-btn">تسجيل دخول</button>
                            </div>
                            {message && <div className="label-text">{message}</div>}
                        </form>
                        }
                    </div>
                </div>}
            </div>}
            <loginAuth.Provider value={confirm}>
                {props.children}
            </loginAuth.Provider>
        </div>
    );
}

export default LogIn;