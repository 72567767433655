import { createContext, useState } from "react";
import React from "react";
export const SearcgContext= createContext();

function SearcgContextProvider(props){
    const [search,setSearch]=useState();
    const [info,setInfo] = useState([]);
    const value = {search,setSearch,info,setInfo};
    return(
        <SearcgContext.Provider value={value}>
            {props.children}
        </SearcgContext.Provider>
    );
}

export default SearcgContextProvider;