import { useContext, useEffect, useState } from "react";
import { IdContext } from "./userIdContext";
import { TabContext } from "./tabindexContext";
import { Link, useNavigate } from "react-router-dom";
import { BsPersonFillAdd } from "react-icons/bs";
import { IoPerson } from "react-icons/io5";
import { SlCalender } from "react-icons/sl";
import { FaCircleDollarToSlot, FaMoneyCheckDollar } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { IoSearchOutline } from "react-icons/io5";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import { AiOutlineMenu } from "react-icons/ai";
import { AddDetailsContext } from "./AddDetailsContext";
import { PiTooth } from "react-icons/pi";
import { CiDollar } from "react-icons/ci";
import { FaTeethOpen } from "react-icons/fa";
import { TbCurrencyDollarSingapore } from "react-icons/tb";

function PaymentsTable() {
    const [infooo, setInfooo] = useState([]);
    const [infoooPatients, setInfoooPatients] = useState([]);
    const [infoooCost, setInfoooCost] = useState([]);
    const [infoooSumPayments, setInfoooSumPayments] = useState([]);
    const [infoooSum, setInfoooSum] = useState([])
    const [message, setMessage] = useState("");
    const [num, setNum] = useState();
    const [sumCost, setSumCost] = useState();
    const [search, setSearch] = useState("");
    const [sumAmount, setSumAmount] = useState();
    const { userId } = useContext(IdContext);
    const { tab } = useContext(TabContext);
    const { setTab } = useContext(TabContext);
    const { setUserId } = useContext(IdContext);
    const [selectedId, setSelectedId] = useState();
    const { setAddDetailsContextt } = useContext(AddDetailsContext)
    const getData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getpayments.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getDataPatients = async () => {
        await fetch("https://dr-meladjouriah.fxcode.tech/php/getPatients.php",
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfoooPatients(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getSearchData = async (searchValue) => {
        //e.preventDefault();
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/search.php?search=${searchValue}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfoooPatients(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const handleSearchChange = (e) => {
        if (e.target.value === "") {
            getDataPatients()
        }
        if (e.target.value !== "") {
            getSearchData(e.target.value)
        }
        setSearch(e.target.value);
    }
    const navigate = useNavigate();


    function addSession() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        navigate("/AddSession", { replace: true });
    }

    function addPayment() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        navigate("/AddPayment", { replace: true });
    }

    function navToSessions() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        window.history.pushState({ id: 1 }, null, "GetSession")
        navigate("/GetSession", { replace: true });
    }

    function navToPayments() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        window.history.pushState({ id: 1 }, null, "GetPayments")
        navigate("/GetPayments", { replace: true });
    }
    function navToDetails() {
        setAddDetailsContextt(true);
        setUserId(selectedId);
        window.history.pushState({ id: 1 }, null, "Profile")
        navigate("/Profile", { replace: true });
    }
    useEffect(() => {
        getData();
        getDataPatients();
    }, [])
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1240px)'
    })
    const isSmallLap = useMediaQuery({
        query: '(min-width: 1224px) and (max-width: 1240px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <div>
            {isSmallLap && <div>
                <div className="btn-add-container-smallLap">
                    <div className="search-container">
                        <form className="search-form">
                            <input type="text" className="form-control" placeholder="البحث" onChange={handleSearchChange}></input>
                        </form>
                    </div>
                    <div className="btns-controls">
                        <button className="btn btn-primary btn-submit" onClick={addSession} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><PiTooth />+</div> </button>
                        <button className="btn btn-primary btn-submit" onClick={addPayment} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><CiDollar />+</div> </button>
                    </div>
                </div>
                <div className="items-container-smallLap">
                    <table className="table-cell">
                        <tr className="item-table-title">
                            <th className="item">الاسم</th>
                            <th className="item">التكاليف</th>
                            <th className="item">المدفوعات</th>
                            <th className="item">الباقي</th>
                            <th className="item">خيارات</th>
                        </tr>
                        {!message && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">... يرجى الانتظار</span>
                            </div>
                            <h2 className="loading">... يرجى الانتظار</h2>
                        </div>}
                        {infoooPatients.map((e) => {
                            function showDetails() {
                                setSelectedId(e.id);
                                setTab(1);
                            }
                            return (
                                <tr className="item-table" tabIndex={tab} onClick={showDetails}>
                                    <td className="item">{e.name}</td>
                                    <td className="item">{e.costssum}</td>
                                    <td className="item">{e.paymentssum}</td>
                                    <td className="item">{parseInt(e.costssum - e.paymentssum)}</td>
                                    <td className="item" onClick={showDetails}> <div className="tools">
                                        <div className="nav-bar-patients">
                                            <Menu menuButton={<MenuButton className="m-btn"><AiOutlineMenu color='white' size={25} /></MenuButton>}>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-primary nav-item" onClick={navToSessions}><FaTeethOpen /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-info nav-item" onClick={navToPayments}><FaCircleDollarToSlot /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-warning nav-item" onClick={navToDetails}><IoPerson /></button>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </div></td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>}
            {isDesktopOrLaptop && <div>
                <div className="btn-add-container">
                    <div className="search-container">
                        <form className="search-form">
                            <input type="text" className="form-control" placeholder="البحث" onChange={handleSearchChange}></input>
                        </form>
                    </div>
                    <div className="btns-controls">
                        <button className="btn btn-primary btn-submit" onClick={addSession} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><PiTooth />+</div> </button>
                        <button className="btn btn-primary btn-submit" onClick={addPayment} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><CiDollar />+</div> </button>
                    </div>
                </div>
                <div className="items-container">
                    <table className="table-cell">
                        <tr className="item-table-title">
                            <th className="item">الاسم</th>
                            <th className="item">التكاليف</th>
                            <th className="item">المدفوعات</th>
                            <th className="item">الباقي</th>
                            <th className="item">خيارات</th>
                        </tr>
                        {!message && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">... يرجى الانتظار</span>
                            </div>
                            <h2 className="loading">... يرجى الانتظار</h2>
                        </div>}
                        {infoooPatients.map((e) => {
                            function showDetails() {
                                setSelectedId(e.id);
                                setTab(1);
                            }
                            return (
                                <tr className="item-table" tabIndex={tab} onClick={showDetails}>
                                    <td className="item">{e.name}</td>
                                    <td className="item">{e.costssum}</td>
                                    <td className="item">{e.paymentssum}</td>
                                    <td className="item">{parseInt(e.costssum - e.paymentssum)}</td>
                                    <td className="item" onClick={showDetails}> <div className="tools">
                                        <div className="nav-bar-patients">
                                            <Menu menuButton={<MenuButton className="m-btn"><AiOutlineMenu color='white' size={25} /></MenuButton>}>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-primary nav-item" onClick={navToSessions}><FaTeethOpen /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-info nav-item" onClick={navToPayments}><FaCircleDollarToSlot /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-warning nav-item" onClick={navToDetails}><IoPerson /></button>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </div></td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>}
            {isTabletOrMobile && <div>
                <div className="btn-add-container-mobile">
                    <div className="search-container-mobile">
                        <form className="search-form-mobile">
                            <input type="text" className="form-control" placeholder="البحث" onChange={handleSearchChange}></input>
                        </form>
                    </div>
                    <div className="btns-controls">
                        <button className="btn btn-primary btn-submit" onClick={addSession} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><PiTooth />+</div> </button>
                        <button className="btn btn-primary btn-submit" onClick={addPayment} disabled={!selectedId}><div style={{ display: "flex", alignItems: "center", width: "100%" }}><CiDollar />+</div> </button>
                    </div>
                </div>
                <div className="items-container-mobile">
                    <table className="table-cell-mobile">
                        <tr className="item-table-title-mobile">
                            <th className="item">الاسم</th>
                            <th className="item">التكاليف</th>
                            <th className="item">المدفوعات</th>
                            <th className="item">الباقي</th>
                            <th className="item">خيارات</th>
                        </tr>
                        {!message && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">... يرجى الانتظار</span>
                            </div>
                            <h2 className="loading">... يرجى الانتظار</h2>
                        </div>}
                        {infoooPatients.map((e) => {
                            function showDetails() {
                                setSelectedId(e.id);
                                setTab(1);
                            }
                            return (
                                <tr className="item-table-mobile" tabIndex={tab} onClick={showDetails}>
                                    <td className="item">{e.name}</td>
                                    <td className="item">{e.costssum}</td>
                                    <td className="item">{e.paymentssum}</td>
                                    <td className="item">{parseInt(e.costssum - e.paymentssum)}</td>
                                    <td className="item" onClick={showDetails}> <div className="tools">
                                        <div className="nav-bar-patients">
                                            <Menu menuButton={<MenuButton className="m-btn"><AiOutlineMenu color='white' size={25} /></MenuButton>}>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-primary nav-item" onClick={navToSessions}><FaTeethOpen /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-info nav-item" onClick={navToPayments}><FaCircleDollarToSlot /></button>
                                                </MenuItem>
                                                <MenuItem className="menu-nav">
                                                    <button className=" btn btn-warning nav-item" onClick={navToDetails}><IoPerson /></button>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </div></td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>}
        </div>
    );
}

export default PaymentsTable;

/*                    <button className="btn btn-dark btn-add" onClick={navToDetails} disabled={!selectedId}><div><IoPerson /></div></button>*/

/*                <div className="btns-controls-mobile">
                    <button className="btn btn-dark btn-add" onClick={navToPayments} disabled={!selectedId}><div><FaMoneyCheckDollar /></div></button>
                </div>*/