import { createContext, useState } from "react";
import React from "react";
export const loginkey = createContext()

function LoginContextProvider(props) {
  const [auth, setAuth] = useState(0)
  const value = { auth, setAuth }
  return (
    <loginkey.Provider value={value}>
      {props.children}
    </loginkey.Provider>
  )
}

export default LoginContextProvider;