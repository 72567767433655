import { useContext, useEffect, useState } from "react";
import { IdContext } from "./userIdContext";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { loginkey } from "./LoginContext";
import LogIn from "./login";

function Pic() {
    const { userId } = useContext(IdContext);
    const { setUserId } = useContext(IdContext);
    const [downloadLink, setDownloadLink] = useState("")
    const [message, setMessage] = useState("")
    const [personalInfo, setPersonalInfo] = useState([])
    const { auth } = useContext(loginkey)
    const getPersonalData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getPatientsid.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        //console.log(`eee ${e}`)
                        setDownloadLink(`https://dr-meladjouriah.fxcode.tech/php/${e.fileName}`)
                        //console.log(downloadLink)
                    })
                    console.log(downloadLink)
                    setMessage('Done')
                }
                return setPersonalInfo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    /*useEffect(() => {
        setUserId(window.localStorage.getItem("userid"))
        console.log(`vvvv ${window.localStorage.getItem("userid")}`);
    }, [!userId])
    useEffect(() => {
        if (userId) {
            window.localStorage.setItem("userid", userId);
        }
    }, [])*/
    useEffect(() => {
        getPersonalData();
       // console.log(downloadLink)
    }, []);
    /*useEffect(()=>{
        window.history.pushState({id:1},null,"Pic")
    },[])*/
    const navigate = useNavigate();
    function back() {
        window.history.pushState({ id: 1 }, null, "Profile")
        navigate("/Profile", { replace: true });
    }
    return (
        <div>
            {auth == 1 && <div><div className="Pic-background">
                <div className="tools-up">
                    <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack /></div></button>
                </div>
                <div >
                    <img className="img-pic" src={downloadLink}></img>
                </div>
                {/*personalInfo.map((e) => {
                let downloadLink = `https://hassan-mohammad.tech/clinic-arabic/php/${e.fileName}`
                console.log(downloadLink);
                return (
                    <div>
                        <img className="img-pic" src={downloadLink}></img>
                    </div>
                )
            })*/}
            </div>
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default Pic;