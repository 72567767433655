import { createContext, useState } from "react";
import React from "react";
export const IdTreat = createContext();

function TreatProvider(props) {
    const [treatId, setTreatId] = useState();
   const value = { treatId, setTreatId }
    return (
        <IdTreat.Provider value={value}>
            {props.children}
        </IdTreat.Provider>
    )
}

export default TreatProvider;