import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GetData.css";
import LogIn, { loginAuth } from "./login";
import { loginkey } from "./LoginContext";
import { IdContext } from "./userIdContext";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { TabContext } from "./tabindexContext";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AddDetailsContext } from "./AddDetailsContext";
import { IdPayment } from "./paymentContext";
import Popup from "react-widget-popup";


function GetPayments() {
    const [infooo, setInfooo] = useState([]);
    const [infoooCost, setInfoooCost] = useState([]);
    const [infoooSumPayments, setInfoooSumPayments] = useState([]);
    const [infoooSum, setInfoooSum] = useState([]);
    const [infoooBalance, setInfoooBalance] = useState([]);
    const [selectedId, setSelectedId] = useState();
    const [message, setMessage] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [open, setOpen] = useState("");
    const [sumCost, setSumCost] = useState();
    const [sumAmount, setSumAmount] = useState();
    const [toAdd, setToAdd] = useState(false);
    const { auth } = useContext(loginkey)
    const { userId } = useContext(IdContext);
    const { setPaymentId } = useContext(IdPayment)
    const { setUserId } = useContext(IdContext);
    const [personalInfo, setPersonalInfo] = useState([]);
    const { tab } = useContext(TabContext);
    const { setTab } = useContext(TabContext);
    const { setAddDetailsContextt } = useContext(AddDetailsContext)
    const getData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getpayments.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getPersonalData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getPatientsid.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setPersonalInfo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const addSum = async (sumValue) => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/addpaymentssum.php?userid=${userId}&paymentssum=${sumValue}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfoooSum(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getPaymentsSumData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getamountpayments.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                    data.values.map((e) => {
                        if (e.sumamount == null) {
                            addSum(0)
                        } else {
                            addSum(e.sumamount)
                        }
                        setSumAmount(e.sumamount)
                    })
                }
                return setInfoooSumPayments(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };

    const navigate = useNavigate();
    function navToAdd() {
        setToAdd(true)
        navigate("/AddPayment", { replace: true });
    }
    function back() {
        window.history.pushState({ id: 1 }, null, "GetPatients")
        navigate("/GetPatients", { replace: true });
    }
    function edit() {
        setPaymentId(selectedId);
        navigate("/EditPayment", { replace: true });
    }
    function deletePayment() {
        //setTreatId(selectedId);
        handleDelete();
    }
    function handleDelete() {
        setDeleteMessage("... يرجى الانتظار")
        const url = `https://dr-meladjouriah.fxcode.tech/php/deletePayment.php?paymentid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setDeleteMessage("تم الحذف")
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
        getData();
    }
    /* useEffect(() => {
         setUserId(window.localStorage.getItem("userid"))
         console.log(`vvvv ${window.localStorage.getItem("userid")}`);
     }, [!userId])
     useEffect(() => {
         if (userId) {
             window.localStorage.setItem("userid", userId);
         }
     }, [])*/
    useEffect(() => {
        getData();
        getPersonalData();
        getPaymentsSumData();
        setAddDetailsContextt(true);
    }, [handleDelete])
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    /*useEffect(()=>{
        window.history.pushState({id:1},null,"GetPayments")
    },[])*/
    setTab(0);
    return (
        <div>
            {auth == 1 && <div>{isDesktopOrLaptop && <div className="container-get">
                {!toAdd && <div className="container-v">
                    {/*<h1 className="text">الحسابات</h1>*/}
                    <div className="text-path">
                        الحسابات/
                        {personalInfo.map((e) => {
                            return (
                                <div className="text">{e.name}</div>
                            )
                        })}
                    </div>
                    <h1 className="text">الحسابات <AiOutlineDollarCircle /></h1>
                    <div className="btn-add-container-control">
                        <div className="btns-controls">
                            <button className="btn btn-success btn-add" onClick={navToAdd}><IoMdAdd /></button>
                            <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack /></div></button>
                        </div>
                    </div>
                    <div className="items-container">
                        <table className="table-cell">
                            <tr className="item-table-title">
                                <th className="item">المبلغ</th>
                                <th className="item">التاريخ</th>
                                <th className="item th-mobile">خيارات</th>
                            </tr>
                            {!message && <div className="loading-all">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">يرجى الانتظار...</span>
                                </div>
                                <h2 className="loading">يرجى الانتظار...</h2>
                            </div>}
                            {infooo.map((e) => {
                                function handleBackPopup() {
                                    setOpen("close");
                                }

                                function handleOpenPopup() {
                                    setOpen(e.id);
                                    setDeleteMessage("")
                                }
                                function addDetails() {
                                    setSelectedId(e.id)
                                    setTab(1)
                                }
                                return (
                                    <tr className="item-table" tabIndex={tab} onClick={addDetails}>
                                        <td className="item">{e.amount}</td>
                                        <td className="item">{e.date}</td>
                                        <td className="item"><div className="tools">
                                            {selectedId === e.id && <div>
                                                {open !== e.id && <button className="btn btn-light" onClick={edit}><div><BiEditAlt /></div></button>}
                                            </div>}
                                            {open !== e.id && <button className="btn btn-danger" onClick={handleOpenPopup} position="left center"><RiDeleteBin6Line /></button>}
                                        </div>
                                            {open === e.id && <Popup
                                                visible={true}
                                                style={{
                                                    border: "2px solid white",
                                                    paddingTop: "20px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                                    top: 100,
                                                    width: "100%",
                                                }}>
                                                <div className="delete-popup">
                                                    <h4>هل تريد بالتأكيد الحذف؟</h4>
                                                    <div className="btns-popup">
                                                        <button className="btn btn-danger" onClick={handleDelete}>حذف</button>
                                                        <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                                    </div>
                                                    {deleteMessage && <div className="loading-all">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden label-text">{deleteMessage}</span>
                                                        </div>
                                                        <h2 className="loading label-text">{deleteMessage}</h2>
                                                    </div>}
                                                </div>
                                            </Popup>}
                                        </td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                }
            </div>
            }
            </div>}
            {auth == 1 && <div>{isTabletOrMobile && <div className="container-get-mobile">
                {!toAdd && <div className="container-v-mobile">
                    <h1 className="text">الحسابات</h1>
                    <div className="btn-add-container-control-mobile">
                        <div className="btns-controls-mobile">
                            <button className="btn btn-success btn-add" onClick={navToAdd}><IoMdAdd /></button>
                            <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack /></div></button>
                        </div>
                    </div>
                    <div className="items-container-mobile">
                        <table className="table-cell-mobile">
                            <tr className="item-table-title-mobile">
                                <th className="item th-mobile">المبلغ</th>
                                <th className="item th-mobile">التاريخ</th>
                                <th className="item th-mobile">خيارات</th>
                            </tr>
                            {!message && <div className="loading-all">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">يرجى الانتظار...</span>
                                </div>
                                <h2 className="loading">يرجى الانتظار...</h2>
                            </div>}
                            {infooo.map((e) => {
                                function handleBackPopup() {
                                    setOpen("close");
                                }

                                function handleOpenPopup() {
                                    setOpen(e.id);
                                    setDeleteMessage("")
                                }
                                function addDetails() {
                                    setSelectedId(e.id)
                                    setTab(1)
                                }
                                return (
                                    <tr className="item-table-mobile" tabIndex={tab} onClick={addDetails}>

                                        <td className="item">{e.amount}</td>
                                        <td className="item">{e.date}</td>
                                        <td className="item"><div className="tools">
                                            {selectedId === e.id && <div>
                                                {open !== e.id && <button className="btn btn-light" onClick={edit}><div><BiEditAlt /></div></button>}
                                            </div>}
                                            {open !== e.id && <button className="btn btn-danger" onClick={handleOpenPopup} position="left center"><RiDeleteBin6Line /></button>}
                                        </div>
                                            {open === e.id && <Popup
                                                visible={true}
                                                style={{
                                                    border: "2px solid white",
                                                    paddingTop: "20px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                                    top: 100,
                                                    width: "100%",
                                                }}>
                                                <div className="delete-popup">
                                                    <h6>هل تريد بالتأكيد الحذف؟</h6>
                                                    <div className="btns-popup-mobile">
                                                        <button className="btn btn-danger" onClick={handleDelete}>حذف</button>
                                                        <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                                    </div>
                                                    {deleteMessage && <div className="loading-all">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden label-text">{deleteMessage}</span>
                                                        </div>
                                                        <h2 className="loading label-text">{deleteMessage}</h2>
                                                    </div>}
                                                </div>
                                            </Popup>}
                                        </td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                }
            </div>}
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default GetPayments;

/*                    <div className="results">
                        {infoooCost.map((e) => {
                            return (
                                <div className="amount-text">Costs Amount: {e.sumcost}</div>
                            )
                        })}
                        {infoooSumPayments.map((e) => {
                            return (
                                <div className="amount-text">Payments Amount: {e.sumamount}</div>
                            )
                        })}
                        <div className="amount-text">Balance: {parseInt(sumCost - sumAmount)}</div>
                    </div>*/

/*                    <div className="results-mobile">
                        {infoooCost.map((e) => {
                            return (
                                <div className="amount-text">Costs Amount: {e.sumcost}</div>
                            )
                        })}
                        {infoooSumPayments.map((e) => {
                            return (
                                <div className="amount-text">Payments Amount: {e.sumamount}</div>
                            )
                        })}
                        <div className="amount-text">Balance: {parseInt(sumCost - sumAmount)}</div>
                    </div>*/