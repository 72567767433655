import { createContext, useState } from "react";
import React from "react";
export const AddDetailsContext = createContext();

function AddDetailsContextProvider(props) {
    const [addDetailsContextt, setAddDetailsContextt] = useState(false);
    const value = {addDetailsContextt, setAddDetailsContextt };
    return(
        <AddDetailsContext.Provider value={value}>
            {props.children}
        </AddDetailsContext.Provider>
    );
}

export default AddDetailsContextProvider;