import { createContext, useState } from "react";
import React from "react";
export const TabContext = createContext();

function TabProvider(props) {
    const [tab, setTab] = useState(0);
    const value = { tab, setTab }
    return (
        <TabContext.Provider value={value}>
            {props.children}
        </TabContext.Provider>
    )
}

export default TabProvider;