import './App.css';
import React from "react";
import Header from './components/Header';
import AddData from './components/AddData';
import LogIn from './components/login';
import { BrowserRouter, HashRouter, Route, Routes, useLocation, useRoutes } from 'react-router-dom';
import GetData from './components/GetData';
import AddPayment from './components/AddPayment';
import IdProvider from './components/userIdContext';
import LoginContextProvider, { loginkey } from './components/LoginContext';
import GetPayments from './components/GetPayments';
import Treats from './components/Treats';
import GetTreats from './components/getTreats';
import Sessions from './components/Sessions';
import GetSessions from './components/GetSessions';
import SessionProvider from './components/SessionContext';
import Profile from './components/Profile';
import TreatProvider from './components/treatIdContext';
import EditTreat from './components/EditTreat';
import EditPatient from './components/EditPatient';
import NavBar from './components/navBar';
import EditPayment from './components/EditPayment';
import TabProvider from './components/tabindexContext';
import { useMediaQuery } from 'react-responsive';
import NavContextProvider, { NavContext } from './components/NavContext';
import SearcgContextProvider from './components/SearchContext';
import GetTreatments from './components/getTreatments';
import TreatmentsPage from './components/treatmentsPage';
import AddDetailsContextProvider from './components/AddDetailsContext';
import TreatmentsList from './components/TreatmentsList';
import EditTreatments from './components/EditTreatments';
import Pic from './components/Pic';
import PaymentProvider from './components/paymentContext';
import { useEffect, useState } from 'react';
import CompanyLogo from './components/companyLogo';

function App() {
  //const location = useLocation();
  const [url, setUrl] = useState("");
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  /*useEffect(() => {
    setUrl(window.location.pathname)
  }, [])
  console.log(`urll ${url}`)*/
  /*useEffect(()=>{
    window.addEventListener('beforeunload',(event) => {
      event.preventDefault()
    })
  },[])*/
  return (
    <div className='App'>
      <BrowserRouter>
        <LoginContextProvider>
          <IdProvider>
            <SessionProvider>
              <TreatProvider>
                <PaymentProvider>
                  <TabProvider>
                    <NavContextProvider>
                      <SearcgContextProvider>
                        <AddDetailsContextProvider>
                          {/*<CompanyLogo />*/}
                          <Header />
                          {/*<TreatmentsPage />*/}
                          {/*<Treatments />
                      <GetTreatments />*/}
                          <Routes>
                            <Route path="/" element={<LogIn />}></Route>
                            <Route path="AddPatients" element={<AddData />}></Route>
                            <Route path="GetPatients" element={<GetData />}></Route>
                            <Route path="GetPayments" element={<GetPayments />}></Route>
                            <Route path="AddPayment" element={<AddPayment />}></Route>
                            <Route path="Treatments" element={<TreatmentsPage />}></Route>
                            <Route path="AddSession" element={<Sessions />}></Route>
                            <Route path="GetSession" element={<GetSessions />}></Route>
                            <Route path="Profile//*" element={<Profile />}></Route>
                            <Route path="TreatsList" element={<TreatmentsList />}></Route>
                            <Route path="EditTreat" element={<EditTreatments />}></Route>
                            <Route path="EditPayment" element={<EditPayment />}></Route>
                            <Route path="EditDetails" element={<EditPatient />}></Route>
                            <Route path="pic" element={<Pic />}></Route>
                          </Routes>
                        </AddDetailsContextProvider>
                      </SearcgContextProvider>
                    </NavContextProvider>
                  </TabProvider>
                </PaymentProvider>
              </TreatProvider>
            </SessionProvider>
          </IdProvider>
        </LoginContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

/*      <HashRouter hashType="hashbang">*/

/*<Routes>
                        <Route path="/" element={<LogIn />}></Route>
                        <Route path="/AddPatients" element={<AddData />}></Route>
                        <Route path="/GetPatients" element={<GetData />}></Route>
                        <Route path="/GetPayments" element={<GetPayments />}></Route>
                        <Route path="/AddPayment" element={<AddPayment />}></Route>
                        <Route path="/Treatments" element={<TreatmentsPage />}></Route>
                        <Route path="/AddTreat" element={<Treats />}></Route>
                        <Route path="/GetTreat" element={<GetTreats />}></Route>
                        <Route path="/AddSession" element={<Sessions />}></Route>
                        <Route path="/GetSession" element={<GetSessions />}></Route>
                        <Route path="/Profile//*" element={<Profile />}></Route>
                        <Route path="/TreatsList" element={<TreatmentsList />}></Route>
                        <Route path="/EditTreat" element={<EditTreatments />}></Route>
                        <Route path="/EditPayment" element={<EditPayment />}></Route>
                        <Route path="/EditDetails" element={<EditPatient />}></Route>
                        <Route path="/Pic" element={<Pic />}></Route>
                      </Routes> */