import { createContext, useState } from "react";
import React from "react";
export const NavContext =createContext();

function NavContextProvider(props){
    const [patients,setPatients]=useState(true);
    const [sessions,setSessions]=useState(false);
    const [payments,setPayments]=useState(false);
    const value ={patients,setPatients,sessions,setSessions,payments,setPayments}
    return(
        <NavContext.Provider value={value}>
            {props.children}
        </NavContext.Provider>
    )
}

export default NavContextProvider;