import { createContext, useState } from "react";
import React from "react";
export const IdPayment= createContext();

function PaymentProvider(props) {
    const [paymentId, setPaymentId] = useState();
   const value = { paymentId, setPaymentId }
    return (
        <IdPayment.Provider value={value}>
            {props.children}
        </IdPayment.Provider>
    )
}

export default PaymentProvider;