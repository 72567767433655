import { createContext, useEffect, useState } from "react";
import React from "react";
export const IdContext = createContext();

function IdProvider (props) {
    const [userId, setUserId] = useState();
    const value={userId,setUserId}
    return (
        <IdContext.Provider value={value}>
            {props.children}
        </IdContext.Provider>
    )
}

export default IdProvider;