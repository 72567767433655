import { useContext, useEffect, useState } from "react";
import GetPayments from "./GetPayments";
import GetSessions from "./GetSessions";
import "./Profile.css";
import { IdContext } from "./userIdContext";
import PaymentProfile from "./PaymentProfile";
import SessionProfile from "./SessionProfile";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import ProfilePersonalInfo from "./PersonalInfo";
import { useMediaQuery } from "react-responsive";
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import { AiOutlineMenu } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { loginkey } from "./LoginContext";
import LogIn from "./login";

function Profile() {
    const [personalInfo, setPersonalInfo] = useState([]);
    const [message, setMessage] = useState("");
    const { userId } = useContext(IdContext);
    const { setUserId } = useContext(IdContext)
    const { auth } = useContext(loginkey)
    const [underLine1, setUnderLine1] = useState({
        textDecoration: "none"
    })
    const [underLine2, setUnderLine2] = useState({
        textDecoration: "none"
    })
    const [underLine3, setUnderLine3] = useState({
        textDecoration: "none"
    })
    function Checked1() {
        setUnderLine1({ textDecoration: "underline" })
        setUnderLine2({ textDecoration: "none" })
        setUnderLine3({ textDecoration: "none" })
    }
    function Checked2() {
        setUnderLine2({ textDecoration: "underline" })
        setUnderLine1({ textDecoration: "none" })
        setUnderLine3({ textDecoration: "none" })
    }
    function Checked3() {
        setUnderLine3({ textDecoration: "underline" })
        setUnderLine1({ textDecoration: "none" })
        setUnderLine2({ textDecoration: "none" })
    }
    const getPersonalData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getPatientsid.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setPersonalInfo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const navigate = useNavigate();
    function back() {
        window.history.pushState({ id: 1 }, null, "GetPatients")
        navigate("/GetPatients", { replace: true });
    }
    /*useEffect(() => {
        setUserId(window.localStorage.getItem("userid"))
        console.log(`vvvv ${window.localStorage.getItem("userid")}`);
    }, [!userId])
    useEffect(() => {
        if (userId) {
            window.localStorage.setItem("userid", userId);
        }
    }, [])*/
    useEffect(() => {
        getPersonalData();
    }, []);
    /*useEffect(()=>{
        window.history.pushState({id:1},null,"Profile")
    },[])*/
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <div>
            {auth == 1 && <div>{isDesktopOrLaptop &&
                <div className="container-get-profile">
                    <div className="top-page">
                        {personalInfo.map((e) => {
                            return (
                                <h1 className="text-profile-name">{e.name}</h1>
                            )
                        })}
                        <div className="nav-bar-profile">
                            <Link onClick={Checked1} style={underLine1} to="ProfilePersonalInfo" className="nav-item-profile">المعلومات الشخصية</Link>
                            <Link onClick={Checked2} style={underLine2} to="ProfilePayments" className="nav-item-profile">الحسابات</Link>
                            <Link onClick={Checked3} style={underLine3} to="ProfileSessions" className="nav-item-profile">الجلسات</Link>
                        </div>
                    </div>
                    <div className="container-get-profile">
                        <div className="container-v-profile">
                            <Routes>
                                <Route path="/*" element={<ProfilePersonalInfo />}></Route>
                                <Route path="ProfilePayments/*" element={<PaymentProfile />}></Route>
                                <Route path="ProfileSessions/*" element={<SessionProfile />}></Route>
                            </Routes>
                        </div>
                    </div>
                </div>
            }
            </div>}
            {auth == 1 && <div>{isTabletOrMobile && <div className="container-get-profile-mobile">
                <div className="top-page-mobile">
                    {personalInfo.map((e) => {
                        return (
                            <h1 className="text">{e.name}</h1>
                        )
                    })}
                </div>
                <div className="nav-bar-mobile">
                    <Menu menuButton={<MenuButton className="m-btn"><AiOutlineMenu color='white' size={25} /></MenuButton>}>
                        <MenuItem className="menu-nav">
                            <Link onClick={Checked1} style={underLine1} to="ProfilePersonalInfo" className="nav-item">المعلومات الشخصية</Link>
                        </MenuItem>
                        <MenuItem className="menu-nav">
                            <Link onClick={Checked2} style={underLine2} to="ProfilePayments" className="nav-item">الحسابات</Link>
                        </MenuItem>
                        <MenuItem className="menu-nav">
                            <Link onClick={Checked3} style={underLine3} to="ProfileSessions" className="nav-item">الجلسات</Link>
                        </MenuItem>
                    </Menu>
                </div>
                <div className="profile-mobile">
                    <Routes>
                        <Route path="/*" element={<ProfilePersonalInfo />}></Route>
                        <Route path="ProfilePayments/*" element={<PaymentProfile />}></Route>
                        <Route path="ProfileSessions/*" element={<SessionProfile />}></Route>
                    </Routes>
                </div>
            </div>}
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default Profile;