import { createContext, useEffect, useState } from "react";
import React from "react";
export const SessionContext = createContext();

function SessionProvider(props) {
    const [sessionId, setSessionId] = useState(0);
    const value = { sessionId, setSessionId };
    return (
        <SessionContext.Provider value={value}>
            {props.children}
        </SessionContext.Provider>
    );
}

export default SessionProvider;