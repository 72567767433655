import { useContext, useEffect, useState } from "react";
import PatientCard from "./PatientCard";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./GetData.css";
import { BsPersonFillAdd } from "react-icons/bs";
import AddData from "./AddData";
import { ConfirmContext } from "./Main";
import AddPayment from "./AddPayment";
import LogIn, { loginAuth } from "./login";
import { loginkey } from "./LoginContext";
import { IdContext } from "./userIdContext";
import { useMediaQuery } from "react-responsive";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";


function PaymentProfile() {
    const [infooo, setInfooo] = useState([]);
    const [infoooCost, setInfoooCost] = useState([]);
    const [infoooSumPayments, setInfoooSumPayments] = useState([]);
    const [infoooBalance, setInfoooBalance] = useState([]);
    const [message, setMessage] = useState("");
    const [sumCost, setSumCost] = useState();
    const [sumAmount, setSumAmount] = useState();
    const [toAdd, setToAdd] = useState(false);
    const { auth } = useContext(loginkey)
    const { userId } = useContext(IdContext);
    const {setUserId} = useContext(IdContext)
    const getData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getpayments.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                }
                return setInfooo(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getCostData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getCosts.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                    data.values.map((e) => {
                        setSumCost(e.sumcost)
                    })
                }
                return setInfoooCost(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
        console.log(`infoCostfunc ${infoooCost}`)
    };
    const getPaymentsSumData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getamountpayments.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                    data.values.map((e) => {
                        setSumAmount(e.sumamount)
                    })
                }
                return setInfoooSumPayments(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const navigate = useNavigate();
    function navToAdd() {
        setToAdd(true)
        navigate("/AddPayment", { replace: true });
    }
    function back() {
        navigate("/GetPatients", { replace: true });
    }
    /*useEffect(() => {
        setUserId(window.localStorage.getItem("userid"))
        console.log(`vvvv ${window.localStorage.getItem("userid")}`);
    }, [!userId])
    useEffect(() => {
        if (userId) {
            window.localStorage.setItem("userid", userId);
        }
    }, [])*/
    useEffect(() => {
        getData();
        getCostData();
        getPaymentsSumData();
    }, [])
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <div className="container-payments-sessions">
            {isDesktopOrLaptop && <div className="container-get">
                {!toAdd && <div className="container-profile">
                    <h4 className="text">معلومات الحسابات <AiOutlineDollarCircle /></h4>
                    <div className="btn-add-container-profile">
                        <button className="btn btn-success btn-add" onClick={navToAdd}><IoMdAdd /></button>
                        <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack/></div></button>
                    </div>
                    <div className="items-container">
                        <table className="table-cell">
                            <tr className="item-table-title">
                                <th className="item">المبلغ</th>
                                <th className="item">التاريخ</th>
                            </tr>
                            {!message && <div className="loading-all">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">يرجى الانتظار...</span>
                                </div>
                                <h2 className="loading">يرجى الانتظار...</h2>
                            </div>}
                            {infooo.map((e) => {
                                return (
                                    <tr className="item-table">
                                        <td className="item">{e.amount}</td>
                                        <td className="item">{e.date}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                }
            </div>}
            {isTabletOrMobile && <div className="container-get-mobile">
                {!toAdd && <div className="container-profile-mobile">
                    <h4 className="text">معلومات الحسابات</h4>
                    <div className="btn-add-container-profile-mobile">
                        <button className="btn btn-success btn-add" onClick={navToAdd}><IoMdAdd /></button>
                        <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack/></div></button>
                    </div>
                    <div className="items-container-profile-mobile">
                        <table className="table-cell-mobile">
                            <tr className="item-table-title-mobile">
                                <th className="item th-mobile">المبلغ</th>
                                <th className="item th-mobile">التاريخ</th>
                            </tr>
                            {!message && <div className="loading-all">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">يرجى الانتظار...</span>
                                </div>
                                <h2 className="loading">يرجى الانتظار...</h2>
                            </div>}
                            {infooo.map((e) => {
                                return (
                                    <tr className="item-table-mobile">
                                        <td className="item">{e.amount}</td>
                                        <td className="item">{e.date}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div>
                </div>
                }
            </div>}
        </div>
    );
}

export default PaymentProfile;