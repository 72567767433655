import { useContext, useEffect, useState } from "react";
/*import t1u from "../assets/t1u.png"
import t2u from "../assets/t2u.png"
import t3u from "../assets/t3u.png"
import t4u from "../assets/t4u.png"
import t5u from "../assets/t5u.png"
import t6u from "../assets/t6u.png"
import t7u from "../assets/t7u.png"
import t8u from "../assets/t8u.png"


import t1ru from "../assets/1ru.png"
import t2ru from "../assets/2ru.png"
import t3ru from "../assets/3ru.png"
import t4ru from "../assets/4ru.png"
import t5ru from "../assets/5ru.png"
import t6ru from "../assets/6ru.png"
import t7ru from "../assets/7ru.png"
import t8ru from "../assets/8ru.png"

import t1rd from "../assets/1rd.png"
import t2rd from "../assets/2rd.png"
import t3rd from "../assets/3rd.png"
import t4rd from "../assets/4rd.png"
import t5rd from "../assets/5rd.png"
import t6rd from "../assets/6rd.png"
import t7rd from "../assets/7rd.png"
import t8rd from "../assets/8rd.png"


import t1tlu from "../assets/t1tlu.png"
import t2tlu from "../assets/t2tlu.png"
import t3tlu from "../assets/t3tlu.png"
import t4tlu from "../assets/t4tlu.png"
import t5tlu from "../assets/t5tlu.png"

import t1tld from "../assets/t1tld.png"
import t2tld from "../assets/t2tld.png"
import t3tld from "../assets/t3tld.png"
import t4tld from "../assets/t4tld.png"
import t5tld from "../assets/t5tld.png"




import t1d from "../assets/t1d.png"
import t2d from "../assets/t2d.png"
import t3d from "../assets/t3d.png"
import t4d from "../assets/t4d.png"
import t5d from "../assets/t5d.png"
import t6d from "../assets/t6d.png"
import t7d from "../assets/t7d.png"
import t8d from "../assets/t8d.png"
import t1tu from "../assets/t1tu.png"
import t2tu from "../assets/t2tu.png"
import t3tu from "../assets/t3tu.png"
import t4tu from "../assets/t4tu.png"
import t5tu from "../assets/t5tu.png"
import t1td from "../assets/t1td.png"
import t2td from "../assets/t2td.png"
import t3td from "../assets/t3td.png"
import t4td from "../assets/t4td.png"
import t5td from "../assets/t5td.png"*/
import t1u from "../assets/mywork/1lu.png"
import t2u from "../assets/mywork/2lu.png"
import t3u from "../assets/mywork/3lu.png"
import t4u from "../assets/mywork/4lu.png"
import t5u from "../assets/mywork/5lu.png"
import t6u from "../assets/mywork/6lu.png"
import t7u from "../assets/mywork/7lu.png"
import t8u from "../assets/mywork/8lu.png"

import t1ru from "../assets/mywork/1ru.png"
import t2ru from "../assets/mywork/2ru.png"
import t3ru from "../assets/mywork/3ru.png"
import t4ru from "../assets/mywork/4ru.png"
import t5ru from "../assets/mywork/5ru.png"
import t6ru from "../assets/mywork/6ru.png"
import t7ru from "../assets/mywork/7ru.png"
import t8ru from "../assets/mywork/8ru.png"

import t1rd from "../assets/mywork/1rd.png"
import t2rd from "../assets/mywork/2rd.png"
import t3rd from "../assets/mywork/3rd.png"
import t4rd from "../assets/mywork/4rd.png"
import t5rd from "../assets/mywork/5rd.png"
import t6rd from "../assets/mywork/6rd.png"
import t7rd from "../assets/mywork/7rd.png"
import t8rd from "../assets/mywork/8rd.png"


import t1tlu from "../assets/mywork/t1tlu.png"
import t2tlu from "../assets/mywork/t2tlu.png"
import t3tlu from "../assets/mywork/t3tlu.png"
import t4tlu from "../assets/mywork/t4tlu.png"
import t5tlu from "../assets/mywork/t5tlu.png"

import t1tld from "../assets/mywork/t1tld.png"
import t2tld from "../assets/mywork/t2tld.png"
import t3tld from "../assets/mywork/t3tld.png"
import t4tld from "../assets/mywork/t4tld.png"
import t5tld from "../assets/mywork/t5tld.png"


import t1d from "../assets/mywork/1ld.png"
import t2d from "../assets/mywork/2ld.png"
import t3d from "../assets/mywork/3ld.png"
import t4d from "../assets/mywork/4ld.png"
import t5d from "../assets/mywork/5ld.png"
import t6d from "../assets/mywork/6ld.png"
import t7d from "../assets/mywork/7ld.png"
import t8d from "../assets/mywork/8ld.png"

import t1tu from "../assets/mywork/t1tu.png"
import t2tu from "../assets/mywork/t2tu.png"
import t3tu from "../assets/mywork/t3tu.png"
import t4tu from "../assets/mywork/t4tu.png"
import t5tu from "../assets/mywork/t5tu.png"

import t1td from "../assets/mywork/t1td.png"
import t2td from "../assets/mywork/t2td.png"
import t3td from "../assets/mywork/t3td.png"
import t4td from "../assets/mywork/t4td.png"
import t5td from "../assets/mywork/t5td.png"
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IdContext } from "./userIdContext";
import { SessionContext } from "./SessionContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IdTreat } from "./treatIdContext";
import { useMediaQuery } from "react-responsive";
import { IoMdArrowRoundBack } from "react-icons/io";
import Popup from "react-widget-popup";
import { loginkey } from "./LoginContext";
import LogIn from "./login";

function TreatmentsList() {
    const [infoooTeeth, setInfoooTeeth] = useState([]);
    const [infoooTeethh, setInfoooTeethh] = useState([]);
    const [infooo, setInfooo] = useState([]);
    const [toothList, setToothList] = useState([]);
    const [toothoutClinicList, setToothoutClinicList] = useState([]);
    const [tooth, setTooth] = useState("");
    const [inclinic, setInclinic] = useState("");
    const [cannals, setCannals] = useState([]);
    const [message, setMessage] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [messageAddCosts, setMessageAddCosts] = useState("");
    const [sumCost, setSumCost] = useState("");
    const [open, setOpen] = useState("");
    const [infoooCost, setInfoooCost] = useState("");
    const [infoooSum, setInfoooSum] = useState("");
    const [selectedId, setSelectedId] = useState("")
    const [status, setStatus] = useState("");
    const { userId } = useContext(IdContext);
    const { setUserId } = useContext(IdContext);
    const { sessionId } = useContext(SessionContext);
    const { setTreatId } = useContext(IdTreat);
    const { auth } = useContext(loginkey)


    const getDataTooth = async (toothh) => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getidTreatsTooth.php?sessionid=${sessionId}&userid=${userId}&tooth=${toothh}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        /*if (e.inclinic === "in") {
                            toothList.push(e.tooth);
                        }*/
                        if (e.treatmentType === "معالجة لبية" && e.cannals.length > 0) {
                            setCannals(JSON.parse(e.cannals))
                        }
                        setStatus(e.status);
                        setInclinic(e.inclinic);
                        /*if (e.inclinic === "out") {
                            toothoutClinicList.push(e.tooth)
                        }*/
                    })
                    setMessage('Done')
                }
                return setInfoooTeethh(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };

    const changeTooth1RD = (e) => {
        setTooth("1RD")
        getDataTooth("1RD")
    }

    const changeTooth2RD = (e) => {
        setTooth("2RD")
        getDataTooth("2RD")
    }

    const changeTooth3RD = (e) => {
        setTooth("3RD")
        getDataTooth("3RD")
    }

    const changeTooth4RD = (e) => {
        setTooth("4RD")
        getDataTooth("4RD")
    }

    const changeTooth5RD = (e) => {
        setTooth("5RD")
        getDataTooth("5RD")
    }

    const changeTooth6RD = (e) => {
        setTooth("6RD")
        getDataTooth("6RD")
    }

    const changeTooth7RD = (e) => {
        setTooth("7RD")
        getDataTooth("7RD")
    }

    const changeTooth8RD = (e) => {
        setTooth("8RD")
        getDataTooth("8RD")
    }

    const changeTooth1RU = (e) => {
        setTooth("1RU")
        getDataTooth("1RU")
    }

    const changeTooth2RU = (e) => {
        setTooth("2RU")
        getDataTooth("2RU")
    }

    const changeTooth3RU = (e) => {
        setTooth("3RU")
        getDataTooth("3RU")
    }

    const changeTooth4RU = (e) => {
        setTooth("4RU")
        getDataTooth("4RU")
    }

    const changeTooth5RU = (e) => {
        setTooth("5RU")
        getDataTooth("5RU")
    }

    const changeTooth6RU = (e) => {
        setTooth("6RU")
        getDataTooth("6RU")
    }

    const changeTooth7RU = (e) => {
        setTooth("7RU")
        getDataTooth("7RU")
    }

    const changeTooth8RU = (e) => {
        setTooth("8RU")
        getDataTooth("8RU")
    }

    const changeTooth1LD = (e) => {
        setTooth("1LD")
        getDataTooth("1LD")
    }

    const changeTooth2LD = (e) => {
        setTooth("2LD")
        getDataTooth("2LD")
    }

    const changeTooth3LD = (e) => {
        setTooth("3LD")
        getDataTooth("3LD")
    }

    const changeTooth4LD = (e) => {
        setTooth("4LD")
        getDataTooth("4LD")
    }

    const changeTooth5LD = (e) => {
        setTooth("5LD")
        getDataTooth("5LD")
    }

    const changeTooth6LD = (e) => {
        setTooth("6LD")
        getDataTooth("6LD")
    }

    const changeTooth7LD = (e) => {
        setTooth("7LD")
        getDataTooth("7LD")
    }

    const changeTooth8LD = (e) => {
        setTooth("8LD")
        getDataTooth("8LD")
    }

    const changeTooth1LU = (e) => {
        setTooth("1LU")
        getDataTooth("1LU")
    }

    const changeTooth2LU = (e) => {
        setTooth("2LU")
        getDataTooth("2LU")
    }

    const changeTooth3LU = (e) => {
        setTooth("3LU")
        getDataTooth("3LU")
    }

    const changeTooth4LU = (e) => {
        setTooth("4LU")
        getDataTooth("4LU")
    }

    const changeTooth5LU = (e) => {
        setTooth("5LU")
        getDataTooth("5LU")
    }

    const changeTooth6LU = (e) => {
        setTooth("6LU")
        getDataTooth("6LU")
    }

    const changeTooth7LU = (e) => {
        setTooth("7LU")
        getDataTooth("7LU")
    }

    const changeTooth8LU = (e) => {
        setTooth("8LU")
        getDataTooth("8LU")
    }





    const changeTooth1LUTP = (e) => {
        setTooth("1LUTP")
        getDataTooth("1LUTP")
    }

    const changeTooth2LUTP = (e) => {
        setTooth("2LUTP")
        getDataTooth("2LUTP")
    }

    const changeTooth3LUTP = (e) => {
        setTooth("3LUTP")
        getDataTooth("3LUTP")
    }

    const changeTooth4LUTP = (e) => {
        setTooth("4LUTP")
        getDataTooth("4LUTP")
    }

    const changeTooth5LUTP = (e) => {
        setTooth("5LUTP")
        getDataTooth("5LUTP")
    }

    const changeTooth1RUTP = (e) => {
        setTooth("1RUTP")
        getDataTooth("1RUTP")
    }

    const changeTooth2RUTP = (e) => {
        setTooth("2RUTP")
        getDataTooth("2RUTP")
    }

    const changeTooth3RUTP = (e) => {
        setTooth("3RUTP")
        getDataTooth("3RUTP")
    }

    const changeTooth4RUTP = (e) => {
        setTooth("4RUTP")
        getDataTooth("4RUTP")
    }

    const changeTooth5RUTP = (e) => {
        setTooth("5RUTP")
        getDataTooth("5RUTP")
    }

    const changeTooth1LDTP = (e) => {
        setTooth("1LDTP")
        getDataTooth("1LDTP")
    }

    const changeTooth2LDTP = (e) => {
        setTooth("2LDTP")
        getDataTooth("2LDTP")
    }

    const changeTooth3LDTP = (e) => {
        setTooth("3LDTP")
        getDataTooth("3LDTP")
    }

    const changeTooth4LDTP = (e) => {
        setTooth("4LDTP")
        getDataTooth("4LDTP")
    }

    const changeTooth5LDTP = (e) => {
        setTooth("5LDTP")
        getDataTooth("5LDTP")
    }

    const changeTooth1RDTP = (e) => {
        setTooth("1RDTP")
        getDataTooth("1RDTP")
    }

    const changeTooth2RDTP = (e) => {
        setTooth("2RDTP")
        getDataTooth("2RDTP")
    }

    const changeTooth3RDTP = (e) => {
        setTooth("3RDTP")
        getDataTooth("3RDTP")
    }

    const changeTooth4RDTP = (e) => {
        setTooth("4RDTP")
        getDataTooth("4RDTP")
    }

    const changeTooth5RDTP = (e) => {
        setTooth("5RDTP")
        getDataTooth("5RDTP")
    }





    const changeTooth1LUT = (e) => {
        setTooth("1LUT")
        getDataTooth("1LUT")
    }

    const changeTooth2LUT = (e) => {
        setTooth("2LUT")
        getDataTooth("2LUT")
    }

    const changeTooth3LUT = (e) => {
        setTooth("3LUT")
        getDataTooth("3LUT")
    }

    const changeTooth4LUT = (e) => {
        setTooth("4LUT")
        getDataTooth("4LUT")
    }

    const changeTooth5LUT = (e) => {
        setTooth("5LUT")
        getDataTooth("5LUT")
    }

    const changeTooth1RUT = (e) => {
        setTooth("1RUT")
        getDataTooth("1RUT")
    }

    const changeTooth2RUT = (e) => {
        setTooth("2RUT")
        getDataTooth("2RUT")
    }

    const changeTooth3RUT = (e) => {
        setTooth("3RUT")
        getDataTooth("3RUT")
    }

    const changeTooth4RUT = (e) => {
        setTooth("4RUT")
        getDataTooth("4RUT")
    }

    const changeTooth5RUT = (e) => {
        setTooth("5RUT")
        getDataTooth("5RUT")
    }

    const changeTooth1LDT = (e) => {
        setTooth("1LDT")
        getDataTooth("1LDT")
    }

    const changeTooth2LDT = (e) => {
        setTooth("2LDT")
        getDataTooth("2LDT")
    }

    const changeTooth3LDT = (e) => {
        setTooth("3LDT")
        getDataTooth("3LDT")
    }

    const changeTooth4LDT = (e) => {
        setTooth("4LDT")
        getDataTooth("4LDT")
    }

    const changeTooth5LDT = (e) => {
        setTooth("5LDT")
        getDataTooth("5LDT")
    }

    const changeTooth1RDT = (e) => {
        setTooth("1RDT")
        getDataTooth("1RDT")
    }

    const changeTooth2RDT = (e) => {
        setTooth("2RDT")
        getDataTooth("2RDT")
    }

    const changeTooth3RDT = (e) => {
        setTooth("3RDT")
        getDataTooth("3RDT")
    }

    const changeTooth4RDT = (e) => {
        setTooth("4RDT")
        getDataTooth("4RDT")
    }

    const changeTooth5RDT = (e) => {
        setTooth("5RDT")
        getDataTooth("5RDT")
    }

    const changeTooth1LUP = (e) => {
        setTooth("1LUP")
        getDataTooth("1LUP")
    }

    const changeTooth2LUP = (e) => {
        setTooth("2LUP")
        getDataTooth("2LUP")
    }

    const changeTooth3LUP = (e) => {
        setTooth("3LUP")
        getDataTooth("3LUP")
    }

    const changeTooth4LUP = (e) => {
        setTooth("4LUP")
        getDataTooth("4LUP")
    }

    const changeTooth5LUP = (e) => {
        setTooth("5LUP")
        getDataTooth("5LUP")
    }

    const changeTooth6LUP = (e) => {
        setTooth("6LUP")
        getDataTooth("6LUP")
    }

    const changeTooth7LUP = (e) => {
        setTooth("7LUP")
        getDataTooth("7LUP")
    }

    const changeTooth8LUP = (e) => {
        setTooth("8LUP")
        getDataTooth("8LUP")
    }


    const changeTooth1RUP = (e) => {
        setTooth("1RUP")
        getDataTooth("1RUP")
    }

    const changeTooth2RUP = (e) => {
        setTooth("2RUP")
        getDataTooth("2RUP")
    }

    const changeTooth3RUP = (e) => {
        setTooth("3RUP")
        getDataTooth("3RUP")
    }

    const changeTooth4RUP = (e) => {
        setTooth("4RUP")
        getDataTooth("4RUP")
    }

    const changeTooth5RUP = (e) => {
        setTooth("5RUP")
        getDataTooth("5RUP")
    }

    const changeTooth6RUP = (e) => {
        setTooth("6RUP")
        getDataTooth("6RUP")
    }

    const changeTooth7RUP = (e) => {
        setTooth("7RUP")
        getDataTooth("7RUP")
    }

    const changeTooth8RUP = (e) => {
        setTooth("8RUP")
        getDataTooth("8RUP")
    }




    const changeTooth1LDP = (e) => {
        setTooth("1LDP")
        getDataTooth("1LDP")
    }

    const changeTooth2LDP = (e) => {
        setTooth("2LDP")
        getDataTooth("2LDP")
    }

    const changeTooth3LDP = (e) => {
        setTooth("3LDP")
        getDataTooth("3LDP")
    }

    const changeTooth4LDP = (e) => {
        setTooth("4LDP")
        getDataTooth("4LDP")
    }

    const changeTooth5LDP = (e) => {
        setTooth("5LDP")
        getDataTooth("5LDP")
    }

    const changeTooth6LDP = (e) => {
        setTooth("6LDP")
        getDataTooth("6LDP")
    }

    const changeTooth7LDP = (e) => {
        setTooth("7LDP")
        getDataTooth("7LDP")
    }

    const changeTooth8LDP = (e) => {
        setTooth("8LDP")
        getDataTooth("8LDP")
    }



    const changeTooth1RDP = (e) => {
        setTooth("1RDP")
        getDataTooth("1RDP")
    }

    const changeTooth2RDP = (e) => {
        setTooth("2RDP")
        getDataTooth("2RDP")
    }

    const changeTooth3RDP = (e) => {
        setTooth("3RDP")
        getDataTooth("3RDP")
    }

    const changeTooth4RDP = (e) => {
        setTooth("4RDP")
        getDataTooth("4RDP")
    }

    const changeTooth5RDP = (e) => {
        setTooth("5RDP")
        getDataTooth("5RDP")
    }

    const changeTooth6RDP = (e) => {
        setTooth("6RDP")
        getDataTooth("6RDP")
    }

    const changeTooth7RDP = (e) => {
        setTooth("7RDP")
        getDataTooth("7RDP")
    }

    const changeTooth8RDP = (e) => {
        setTooth("8RDP")
        getDataTooth("8RDP")
    }



    const getDataa = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getidTreats.php?sessionid=${sessionId}&userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    data.values.map((e) => {
                        if (e.inclinic === "in") {
                            toothList.push(e.tooth);
                        }
                        if (e.treatmentType === "معالجة لبية" && e.cannals.length > 0) {
                            setCannals(JSON.parse(e.cannals))
                        }
                        setStatus(e.status);
                        setInclinic(e.inclinic);
                        if (e.inclinic === "out") {
                            toothoutClinicList.push(e.tooth)
                        }
                    })
                    setMessage('Done')
                }
                return setInfoooTeeth(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    const getCostData = async () => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/getCosts.php?userid=${userId}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessage('Done')
                    data.values.map((e) => {
                        console.log(`sumcost ${e.sumcost}`);
                        if (e.sumcost == null) {
                            addSum(0)
                        } else {
                            addSum(e.sumcost);
                        }
                        setSumCost(e.sumcost)
                    })
                }
                return setInfoooCost(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
        console.log(`infoCostfunc ${infoooCost}`)
    };
    console.log(`infoocost  ${infoooCost}`)
    const addSum = async (sumValue) => {
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/addcostssum.php?userid=${userId}&costssum=${sumValue}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw Error('Could not fetch the data')

                }
                else if (response.ok) {
                    return response.json()
                }
            })
            .then((data) => {
                if (data.successful) {
                    setMessageAddCosts('Done')
                }
                return setInfoooSum(data.values)

            })
            .catch(err => {
                setMessage(err)
            })
    };
    function deleteTreat() {
        //setTreatId(selectedId);
        handleDelete();
    }
    function handleDelete() {
        setDeleteMessage("... يرجى الانتظار")
        const url = `https://dr-meladjouriah.fxcode.tech/php/deleteTreat.php?treatid=${selectedId}`;
        const formData = new FormData();
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                let index = toothList.indexOf(tooth)
                if (index !== -1) {
                    toothList.splice(index, 1)
                }
                let indexOut = toothoutClinicList.indexOf(tooth)
                if (indexOut !== -1) {
                    toothoutClinicList.splice(indexOut, 1)
                }
                getCostData();
                setDeleteMessage("تم الحذف")
                getDataa();
                getDataTooth(tooth)
            }
            console.log(response);
        })
            .catch(error => {
                console.log(error);
            });
    }
    const navigate = useNavigate();
    function edit() {
        setTreatId(selectedId);
        //window.history.pushState({ id: 1 }, null, "EditTreat")
        navigate("/EditTreat", { replace: true });
    }
    function back() {
        window.history.pushState({ id: 1 }, null, "GetSession")
        navigate("/GetSession", { replace: true });
    }
    /* useEffect(() => {
         setUserId(window.localStorage.getItem("userid"))
         console.log(`vvvv ${window.localStorage.getItem("userid")}`);
     }, [!userId])
     useEffect(() => {
         if (userId) {
             window.localStorage.setItem("userid", userId);
         }
     }, [])*/
    useEffect(() => {
        getDataa();
        getCostData();
    }, []);
    const cannalsItems = cannals.map((cannal) => {
        //let cannalInstance = JSON.parse(cannal);
        return (<div><h5>{cannal.cannalName} طول القناة</h5> <p>{cannal.cannalLength}</p></div>)
    });
    console.log(`tooth list ${toothList}`)
    console.log(`tooth out list ${toothoutClinicList}`)
    console.log(`selectedId ${selectedId}`)
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    /*useEffect(()=>{
        window.history.pushState({id:1},null,"TreatmentsList")
    },[])*/
    return (
        <div>
            {auth == 1 && <div>{isDesktopOrLaptop && <div className="ground-get">
                {!status && infoooTeeth.length > 0 && <div className="loading-all">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">... يرجى الانتظار</span>
                    </div>
                    <h2 className="loading">... يرجى الانتظار</h2>
                </div>}
                <div className="fk">
                    <div className='h'>
                        {status === "دائم" && <div className='hh'>
                            <img className={(toothList.includes("8LU") ? "b" : "") || (toothoutClinicList.includes("8LU") ? "out" : "")} src={t8u} width="30px" onClick={changeTooth8LU}></img>
                            <img className={(toothList.includes("7LU") ? "b" : "") || (toothoutClinicList.includes("7LU") ? "out" : "")} src={t7u} width="30px" onClick={changeTooth7LU}></img>
                            <img className={(toothList.includes("6LU") ? "b" : "") || (toothoutClinicList.includes("6LU") ? "out" : "")} src={t6u} width="30px" onClick={changeTooth6LU}></img>
                            <img className={(toothList.includes("5LU") ? "b" : "") || (toothoutClinicList.includes("5LU") ? "out" : "")} src={t5u} width="30px" onClick={changeTooth5LU}></img>
                            <img className={(toothList.includes("4LU") ? "b" : "") || (toothoutClinicList.includes("4LU") ? "out" : "")} src={t4u} width="30px" onClick={changeTooth4LU}></img>
                            <img className={(toothList.includes("3LU") ? "b" : "") || (toothoutClinicList.includes("3LU") ? "out" : "")} src={t3u} width="30px" onClick={changeTooth3LU}></img>
                            <img className={(toothList.includes("2LU") ? "b" : "") || (toothoutClinicList.includes("2LU") ? "out" : "")} src={t2u} width="30px" onClick={changeTooth2LU}></img>
                            <img className={(toothList.includes("1LU") ? "b" : "") || (toothoutClinicList.includes("1LU") ? "out" : "")} src={t1u} width="30px" onClick={changeTooth1LU}></img>
                        </div>}
                        {status === "دائم" && <div className='hh'>
                            <img className={(toothList.includes("1RU") ? "b" : "") || (toothoutClinicList.includes("1RU") ? "out" : "")} src={t1ru} width="30px" onClick={changeTooth1RU}></img>
                            <img className={(toothList.includes("2RU") ? "b" : "") || (toothoutClinicList.includes("2RU") ? "out" : "")} src={t2ru} width="30px" onClick={changeTooth2RU}></img>
                            <img className={(toothList.includes("3RU") ? "b" : "") || (toothoutClinicList.includes("3RU") ? "out" : "")} src={t3ru} width="30px" onClick={changeTooth3RU}></img>
                            <img className={(toothList.includes("4RU") ? "b" : "") || (toothoutClinicList.includes("4RU") ? "out" : "")} src={t4ru} width="30px" onClick={changeTooth4RU}></img>
                            <img className={(toothList.includes("5RU") ? "b" : "") || (toothoutClinicList.includes("5RU") ? "out" : "")} src={t5ru} width="30px" onClick={changeTooth5RU}></img>
                            <img className={(toothList.includes("6RU") ? "b" : "") || (toothoutClinicList.includes("6RU") ? "out" : "")} src={t6ru} width="30px" onClick={changeTooth6RU}></img>
                            <img className={(toothList.includes("7RU") ? "b" : "") || (toothoutClinicList.includes("7RU") ? "out" : "")} src={t7ru} width="30px" onClick={changeTooth7RU}></img>
                            <img className={(toothList.includes("8RU") ? "b" : "") || (toothoutClinicList.includes("8RU") ? "out" : "")} src={t8ru} width="30px" onClick={changeTooth8RU}></img>
                        </div>}
                        {status === "مؤقت" && <div className='hh'>
                            <img className={(toothList.includes("5LUT") ? "b" : "") || (toothoutClinicList.includes("5LUT") ? "out" : "")} src={t5tlu} width="30px" onClick={changeTooth5LUT}></img>
                            <img className={(toothList.includes("4LUT") ? "b" : "") || (toothoutClinicList.includes("4LUT") ? "out" : "")} src={t4tlu} width="30px" onClick={changeTooth4LUT}></img>
                            <img className={(toothList.includes("3LUT") ? "b" : "") || (toothoutClinicList.includes("3LUT") ? "out" : "")} src={t3tlu} width="30px" onClick={changeTooth3LUT}></img>
                            <img className={(toothList.includes("2LUT") ? "b" : "") || (toothoutClinicList.includes("2LUT") ? "out" : "")} src={t2tlu} width="30px" onClick={changeTooth2LUT}></img>
                            <img className={(toothList.includes("1LUT") ? "b" : "") || (toothoutClinicList.includes("1LUT") ? "out" : "")} src={t1tlu} width="30px" onClick={changeTooth1LUT}></img>
                        </div>}
                        {status === "مؤقت" && <div className='hh'>
                            <img className={(toothList.includes("1RUT") ? "b" : "") || (toothoutClinicList.includes("1RUT") ? "out" : "")} src={t1tu} width="30px" onClick={changeTooth1RUT}></img>
                            <img className={(toothList.includes("2RUT") ? "b" : "") || (toothoutClinicList.includes("2RUT") ? "out" : "")} src={t2tu} width="30px" onClick={changeTooth2RUT}></img>
                            <img className={(toothList.includes("3RUT") ? "b" : "") || (toothoutClinicList.includes("3RUT") ? "out" : "")} src={t3tu} width="30px" onClick={changeTooth3RUT}></img>
                            <img className={(toothList.includes("4RUT") ? "b" : "") || (toothoutClinicList.includes("4RUT") ? "out" : "")} src={t4tu} width="30px" onClick={changeTooth4RUT}></img>
                            <img className={(toothList.includes("5RUT") ? "b" : "") || (toothoutClinicList.includes("5RUT") ? "out" : "")} src={t5tu} width="30px" onClick={changeTooth5RUT}></img>
                        </div>}
                    </div>
                    <div className="l">
                        {status === "دائم" && <div className='hh'>
                            <img className={(toothList.includes("8LD") ? "b" : "") || (toothoutClinicList.includes("8LD") ? "out" : "")} src={t8d} width="30px" onClick={changeTooth8LD}></img>
                            <img className={(toothList.includes("7LD") ? "b" : "") || (toothoutClinicList.includes("7LD") ? "out" : "")} src={t7d} width="30px" onClick={changeTooth7LD}></img>
                            <img className={(toothList.includes("6LD") ? "b" : "") || (toothoutClinicList.includes("6LD") ? "out" : "")} src={t6d} width="30px" onClick={changeTooth6LD}></img>
                            <img className={(toothList.includes("5LD") ? "b" : "") || (toothoutClinicList.includes("5LD") ? "out" : "")} src={t5d} width="30px" onClick={changeTooth5LD}></img>
                            <img className={(toothList.includes("4LD") ? "b" : "") || (toothoutClinicList.includes("4LD") ? "out" : "")} src={t4d} width="30px" onClick={changeTooth4LD}></img>
                            <img className={(toothList.includes("3LD") ? "b" : "") || (toothoutClinicList.includes("3LD") ? "out" : "")} src={t3d} width="30px" onClick={changeTooth3LD}></img>
                            <img className={(toothList.includes("2LD") ? "b" : "") || (toothoutClinicList.includes("2LD") ? "out" : "")} src={t2d} width="30px" onClick={changeTooth2LD}></img>
                            <img className={(toothList.includes("1LD") ? "b" : "") || (toothoutClinicList.includes("1LD") ? "out" : "")} src={t1d} width="30px" onClick={changeTooth1LD}></img>
                        </div>}
                        {status === "دائم" && <div className='hh'>
                            <img className={(toothList.includes("1RD") ? "b" : "") || (toothoutClinicList.includes("1RD") ? "out" : "")} src={t1rd} width="30px" onClick={changeTooth1RD}></img>
                            <img className={(toothList.includes("2RD") ? "b" : "") || (toothoutClinicList.includes("2RD") ? "out" : "")} src={t2rd} width="30px" onClick={changeTooth2RD}></img>
                            <img className={(toothList.includes("3RD") ? "b" : "") || (toothoutClinicList.includes("3RD") ? "out" : "")} src={t3rd} width="30px" onClick={changeTooth3RD}></img>
                            <img className={(toothList.includes("4RD") ? "b" : "") || (toothoutClinicList.includes("4RD") ? "out" : "")} src={t4rd} width="30px" onClick={changeTooth4RD}></img>
                            <img className={(toothList.includes("5RD") ? "b" : "") || (toothoutClinicList.includes("5RD") ? "out" : "")} src={t5rd} width="30px" onClick={changeTooth5RD}></img>
                            <img className={(toothList.includes("6RD") ? "b" : "") || (toothoutClinicList.includes("6RD") ? "out" : "")} src={t6rd} width="30px" onClick={changeTooth6RD}></img>
                            <img className={(toothList.includes("7RD") ? "b" : "") || (toothoutClinicList.includes("7RD") ? "out" : "")} src={t7rd} width="30px" onClick={changeTooth7RD}></img>
                            <img className={(toothList.includes("8RD") ? "b" : "") || (toothoutClinicList.includes("8RD") ? "out" : "")} src={t8rd} width="30px" onClick={changeTooth8RD}></img>
                        </div>}
                    </div>
                    <div className="l">
                        {status === "مؤقت" && <div className='hh'>
                            <img className={(toothList.includes("5LDT") ? "b" : "") || (toothoutClinicList.includes("5LDT") ? "out" : "")} src={t5tld} width="30px" onClick={changeTooth5LDT}></img>
                            <img className={(toothList.includes("4LDT") ? "b" : "") || (toothoutClinicList.includes("4LDT") ? "out" : "")} src={t4tld} width="30px" onClick={changeTooth4LDT}></img>
                            <img className={(toothList.includes("3LDT") ? "b" : "") || (toothoutClinicList.includes("3LDT") ? "out" : "")} src={t3tld} width="30px" onClick={changeTooth3LDT}></img>
                            <img className={(toothList.includes("2LDT") ? "b" : "") || (toothoutClinicList.includes("2LDT") ? "out" : "")} src={t2tld} width="30px" onClick={changeTooth2LDT}></img>
                            <img className={(toothList.includes("1LDT") ? "b" : "") || (toothoutClinicList.includes("1LDT") ? "out" : "")} src={t1tld} width="30px" onClick={changeTooth1LDT}></img>
                        </div>}
                        {status === "مؤقت" && <div className='hh'>
                            <img className={(toothList.includes("1RDT") ? "b" : "") || (toothoutClinicList.includes("1RDT") ? "out" : "")} src={t1td} width="30px" onClick={changeTooth1RDT}></img>
                            <img className={(toothList.includes("2RDT") ? "b" : "") || (toothoutClinicList.includes("2RDT") ? "out" : "")} src={t2td} width="30px" onClick={changeTooth2RDT}></img>
                            <img className={(toothList.includes("3RDT") ? "b" : "") || (toothoutClinicList.includes("3RDT") ? "out" : "")} src={t3td} width="30px" onClick={changeTooth3RDT}></img>
                            <img className={(toothList.includes("4RDT") ? "b" : "") || (toothoutClinicList.includes("4RDT") ? "out" : "")} src={t4td} width="30px" onClick={changeTooth4RDT}></img>
                            <img className={(toothList.includes("5RDT") ? "b" : "") || (toothoutClinicList.includes("5RDT") ? "out" : "")} src={t5td} width="30px" onClick={changeTooth5RDT}></img>
                        </div>}
                    </div>
                    {status == "مختلط" && <div className='hh-both'>
                        <div className="adult">
                            <div className='h'>
                                {status === "مختلط" && <div className='hh'>
                                    <img className={(toothList.includes("8LUP") ? "b" : "") || (toothoutClinicList.includes("8LUP") ? "out" : "")} src={t8u} width="30px" onClick={changeTooth8LUP}></img>
                                    <img className={(toothList.includes("7LUP") ? "b" : "") || (toothoutClinicList.includes("7LUP") ? "out" : "")} src={t7u} width="30px" onClick={changeTooth7LUP}></img>
                                    <img className={(toothList.includes("6LUP") ? "b" : "") || (toothoutClinicList.includes("6LUP") ? "out" : "")} src={t6u} width="30px" onClick={changeTooth6LUP}></img>
                                    <img className={(toothList.includes("5LUP") ? "b" : "") || (toothoutClinicList.includes("5LUP") ? "out" : "")} src={t5u} width="30px" onClick={changeTooth5LUP}></img>
                                    <img className={(toothList.includes("4LUP") ? "b" : "") || (toothoutClinicList.includes("4LUP") ? "out" : "")} src={t4u} width="30px" onClick={changeTooth4LUP}></img>
                                    <img className={(toothList.includes("3LUP") ? "b" : "") || (toothoutClinicList.includes("3LUP") ? "out" : "")} src={t3u} width="30px" onClick={changeTooth3LUP}></img>
                                    <img className={(toothList.includes("2LUP") ? "b" : "") || (toothoutClinicList.includes("2LUP") ? "out" : "")} src={t2u} width="30px" onClick={changeTooth2LUP}></img>
                                    <img className={(toothList.includes("1LUP") ? "b" : "") || (toothoutClinicList.includes("1LUP") ? "out" : "")} src={t1u} width="30px" onClick={changeTooth1LUP}></img>
                                </div>}
                                {status === "مختلط" && <div className='hh'>
                                    <img className={(toothList.includes("1RUP") ? "b" : "") || (toothoutClinicList.includes("1RUP") ? "out" : "")} src={t1ru} width="30px" onClick={changeTooth1RUP}></img>
                                    <img className={(toothList.includes("2RUP") ? "b" : "") || (toothoutClinicList.includes("2RUP") ? "out" : "")} src={t2ru} width="30px" onClick={changeTooth2RUP}></img>
                                    <img className={(toothList.includes("3RUP") ? "b" : "") || (toothoutClinicList.includes("3RUP") ? "out" : "")} src={t3ru} width="30px" onClick={changeTooth3RUP}></img>
                                    <img className={(toothList.includes("4RUP") ? "b" : "") || (toothoutClinicList.includes("4RUP") ? "out" : "")} src={t4ru} width="30px" onClick={changeTooth4RUP}></img>
                                    <img className={(toothList.includes("5RUP") ? "b" : "") || (toothoutClinicList.includes("5RUP") ? "out" : "")} src={t5ru} width="30px" onClick={changeTooth5RUP}></img>
                                    <img className={(toothList.includes("6RUP") ? "b" : "") || (toothoutClinicList.includes("6RUP") ? "out" : "")} src={t6ru} width="30px" onClick={changeTooth6RUP}></img>
                                    <img className={(toothList.includes("7RUP") ? "b" : "") || (toothoutClinicList.includes("7RUP") ? "out" : "")} src={t7ru} width="30px" onClick={changeTooth7RUP}></img>
                                    <img className={(toothList.includes("8RUP") ? "b" : "") || (toothoutClinicList.includes("8RUP") ? "out" : "")} src={t8ru} width="30px" onClick={changeTooth8RUP}></img>
                                </div>}
                            </div>
                            <div className="l">
                                {status === "مختلط" && <div className='hh'>
                                    <img className={(toothList.includes("8LDP") ? "b" : "") || (toothoutClinicList.includes("8LDP") ? "out" : "")} src={t8d} width="30px" onClick={changeTooth8LDP}></img>
                                    <img className={(toothList.includes("7LDP") ? "b" : "") || (toothoutClinicList.includes("7LDP") ? "out" : "")} src={t7d} width="30px" onClick={changeTooth7LDP}></img>
                                    <img className={(toothList.includes("6LDP") ? "b" : "") || (toothoutClinicList.includes("6LDP") ? "out" : "")} src={t6d} width="30px" onClick={changeTooth6LDP}></img>
                                    <img className={(toothList.includes("5LDP") ? "b" : "") || (toothoutClinicList.includes("5LDP") ? "out" : "")} src={t5d} width="30px" onClick={changeTooth5LDP}></img>
                                    <img className={(toothList.includes("4LDP") ? "b" : "") || (toothoutClinicList.includes("4LDP") ? "out" : "")} src={t4d} width="30px" onClick={changeTooth4LDP}></img>
                                    <img className={(toothList.includes("3LDP") ? "b" : "") || (toothoutClinicList.includes("3LDP") ? "out" : "")} src={t3d} width="30px" onClick={changeTooth3LDP}></img>
                                    <img className={(toothList.includes("2LDP") ? "b" : "") || (toothoutClinicList.includes("2LDP") ? "out" : "")} src={t2d} width="30px" onClick={changeTooth2LDP}></img>
                                    <img className={(toothList.includes("1LDP") ? "b" : "") || (toothoutClinicList.includes("1LDP") ? "out" : "")} src={t1d} width="30px" onClick={changeTooth1LDP}></img>
                                </div>}
                                {status === "مختلط" && <div className='hh'>
                                    <img className={(toothList.includes("1RDP") ? "b" : "") || (toothoutClinicList.includes("1RDP") ? "out" : "")} src={t1rd} width="30px" onClick={changeTooth1RDP}></img>
                                    <img className={(toothList.includes("2RDP") ? "b" : "") || (toothoutClinicList.includes("2RDP") ? "out" : "")} src={t2rd} width="30px" onClick={changeTooth2RDP}></img>
                                    <img className={(toothList.includes("3RDP") ? "b" : "") || (toothoutClinicList.includes("3RDP") ? "out" : "")} src={t3rd} width="30px" onClick={changeTooth3RDP}></img>
                                    <img className={(toothList.includes("4RDP") ? "b" : "") || (toothoutClinicList.includes("4RDP") ? "out" : "")} src={t4rd} width="30px" onClick={changeTooth4RDP}></img>
                                    <img className={(toothList.includes("5RDP") ? "b" : "") || (toothoutClinicList.includes("5RDP") ? "out" : "")} src={t5rd} width="30px" onClick={changeTooth5RDP}></img>
                                    <img className={(toothList.includes("6RDP") ? "b" : "") || (toothoutClinicList.includes("6RDP") ? "out" : "")} src={t6rd} width="30px" onClick={changeTooth6RDP}></img>
                                    <img className={(toothList.includes("7RDP") ? "b" : "") || (toothoutClinicList.includes("7RDP") ? "out" : "")} src={t7rd} width="30px" onClick={changeTooth7RDP}></img>
                                    <img className={(toothList.includes("8RDP") ? "b" : "") || (toothoutClinicList.includes("8RDP") ? "out" : "")} src={t8rd} width="30px" onClick={changeTooth8RDP}></img>
                                </div>}
                            </div>
                        </div>
                        <div className="kids">
                            <div className="h">
                                {status === "مختلط" && <div className='hh'>
                                    <img className={(toothList.includes("5LUTP") ? "b" : "") || (toothoutClinicList.includes("5LUTP") ? "out" : "")} src={t5tlu} width="30px" onClick={changeTooth5LUTP}></img>
                                    <img className={(toothList.includes("4LUTP") ? "b" : "") || (toothoutClinicList.includes("4LUTP") ? "out" : "")} src={t4tlu} width="30px" onClick={changeTooth4LUTP}></img>
                                    <img className={(toothList.includes("3LUTP") ? "b" : "") || (toothoutClinicList.includes("3LUTP") ? "out" : "")} src={t3tlu} width="30px" onClick={changeTooth3LUTP}></img>
                                    <img className={(toothList.includes("2LUTP") ? "b" : "") || (toothoutClinicList.includes("2LUTP") ? "out" : "")} src={t2tlu} width="30px" onClick={changeTooth2LUTP}></img>
                                    <img className={(toothList.includes("1LUTP") ? "b" : "") || (toothoutClinicList.includes("1LUTP") ? "out" : "")} src={t1tlu} width="30px" onClick={changeTooth1LUTP}></img>
                                </div>}
                                {status === "مختلط" && <div className='hh'>
                                    <img className={(toothList.includes("1RUTP") ? "b" : "") || (toothoutClinicList.includes("1RUTP") ? "out" : "")} src={t1tu} width="30px" onClick={changeTooth1RUTP}></img>
                                    <img className={(toothList.includes("2RUTP") ? "b" : "") || (toothoutClinicList.includes("2RUTP") ? "out" : "")} src={t2tu} width="30px" onClick={changeTooth2RUTP}></img>
                                    <img className={(toothList.includes("3RUTP") ? "b" : "") || (toothoutClinicList.includes("3RUTP") ? "out" : "")} src={t3tu} width="30px" onClick={changeTooth3RUTP}></img>
                                    <img className={(toothList.includes("4RUTP") ? "b" : "") || (toothoutClinicList.includes("4RUTP") ? "out" : "")} src={t4tu} width="30px" onClick={changeTooth4RUTP}></img>
                                    <img className={(toothList.includes("5RUTP") ? "b" : "") || (toothoutClinicList.includes("5RUTP") ? "out" : "")} src={t5tu} width="30px" onClick={changeTooth5RUTP}></img>
                                </div>}
                            </div>
                            <div className="l">
                                {status === "مختلط" && <div className='hh'>
                                    <img className={(toothList.includes("5LDTP") ? "b" : "") || (toothoutClinicList.includes("5LDTP") ? "out" : "")} src={t5tld} width="30px" onClick={changeTooth5LDTP}></img>
                                    <img className={(toothList.includes("4LDTP") ? "b" : "") || (toothoutClinicList.includes("4LDTP") ? "out" : "")} src={t4tld} width="30px" onClick={changeTooth4LDTP}></img>
                                    <img className={(toothList.includes("3LDTP") ? "b" : "") || (toothoutClinicList.includes("3LDTP") ? "out" : "")} src={t3tld} width="30px" onClick={changeTooth3LDTP}></img>
                                    <img className={(toothList.includes("2LDTP") ? "b" : "") || (toothoutClinicList.includes("2LDTP") ? "out" : "")} src={t2tld} width="30px" onClick={changeTooth2LDTP}></img>
                                    <img className={(toothList.includes("1LDTP") ? "b" : "") || (toothoutClinicList.includes("1LDTP") ? "out" : "")} src={t1tld} width="30px" onClick={changeTooth1LDTP}></img>
                                </div>}
                                {status === "مختلط" && <div className='hh'>
                                    <img className={(toothList.includes("1RDTP") ? "b" : "") || (toothoutClinicList.includes("1RDTP") ? "out" : "")} src={t1td} width="30px" onClick={changeTooth1RDTP}></img>
                                    <img className={(toothList.includes("2RDTP") ? "b" : "") || (toothoutClinicList.includes("2RDTP") ? "out" : "")} src={t2td} width="30px" onClick={changeTooth2RDTP}></img>
                                    <img className={(toothList.includes("3RDTP") ? "b" : "") || (toothoutClinicList.includes("3RDTP") ? "out" : "")} src={t3td} width="30px" onClick={changeTooth3RDTP}></img>
                                    <img className={(toothList.includes("4RDTP") ? "b" : "") || (toothoutClinicList.includes("4RDTP") ? "out" : "")} src={t4td} width="30px" onClick={changeTooth4RDTP}></img>
                                    <img className={(toothList.includes("5RDTP") ? "b" : "") || (toothoutClinicList.includes("5RDTP") ? "out" : "")} src={t5td} width="30px" onClick={changeTooth5RDTP}></img>
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="head-treats-container">
                    <h2 className="label-text">المعالجات</h2>
                    <div className="tools-up">
                        <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack /></div></button>
                    </div>
                    <div className={toothList.includes(tooth) ? "head-treats-list-container-in" : (toothoutClinicList.includes(tooth) ? "head-treats-list-container-out" : "")}>
                        {infoooTeeth.length === 0 && <h4 className="label-text">لا يوجد معالجات</h4>}
                        {!tooth && infoooTeeth.length > 0 && <h4 className="label-text">من فضلك قم باختيار السن</h4>}
                        {(toothList.includes(tooth) || toothoutClinicList.includes(tooth)) && tooth && !infoooTeethh.length && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden label-text">... يرجى الانتظار</span>
                            </div>
                            <h2 className="loading label-text">... يرجى الانتظار</h2>
                        </div>}
                        {infoooTeethh.map((e) => {
                            function handleBackPopup() {
                                setOpen("close");
                            }

                            function handleOpenPopup() {
                                setOpen(e.id);
                                setDeleteMessage("")
                            }
                            function addDetails() {
                                setSelectedId(e.id)
                            }
                            return (
                                <div className="treat" tabIndex={-1} >
                                    {open === e.id && <Popup
                                        id="d"
                                        visible={true}
                                        style={{
                                            border: "2px solid white",
                                            paddingTop: "20px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                            top: 100,
                                            width: "100%",
                                        }}>
                                        <div className="delete-popup">
                                            <h4>هل تريد بالتأكيد الحذف؟</h4>
                                            <div className="btns-popup">
                                                <button className="btn btn-danger" onClick={deleteTreat}>حذف</button>
                                                <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                            </div>
                                            {deleteMessage && <div className="loading-all">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden label-text">{deleteMessage}</span>
                                                </div>
                                                <h2 className="loading label-text">{deleteMessage}</h2>
                                            </div>}
                                        </div>
                                    </Popup>}
                                    {tooth === e.tooth && <div className={toothList.includes(tooth) ? "treatments-tools-container-in" : (toothoutClinicList.includes(tooth) ? "treatments-tools-container-out" : "")} onClick={addDetails}>
                                        <div className={toothList.includes(tooth) ? "treatments-container-in" : (toothoutClinicList.includes(tooth) ? "treatments-container-out" : "")} >
                                            {e.status && <div className="label-text"><h5>نوع الإطباق</h5><p>{e.status}</p></div>}
                                            {e.treatmentType && <div className="label-text"><h5>نوع المعالجة</h5><p>{e.treatmentType}</p></div>}
                                            {e.lopiaType && <div className="label-text"><h5>نوع المعالجة اللبية </h5><p>{e.lopiaType}</p></div>}
                                            {e.channal && <div className="label-text"><h5>طول القناة</h5> <p>{e.channal}</p></div>}
                                            {e.treatmentType === "معالجة لبية" && <div className="label-text">{cannalsItems}</div>}
                                            {e.channalB && <div className="label-text"><h5>B طول القناة</h5> <p>{e.channalB}</p></div>}
                                            {e.channalP && <div className="label-text"><h5>P طول القناة</h5> <p>{e.channalP}</p></div>}
                                            {e.channalDB && <div className="label-text"><h5>DB طول القناة</h5> <p>{e.channalDB}</p></div>}
                                            {e.channalMB2 && <div className="label-text"><h5>MB2طول القناة</h5> <p>{e.channalMB2}</p></div>}
                                            {e.channalMB1 && <div className="label-text"><h5>MB1 طول القناة</h5> <p>{e.channalMB1}</p></div>}
                                            {e.channalMB && <div className="label-text"><h5>MB طول القناة</h5> <p>{e.channalMB}</p></div>}
                                            {e.channalL && <div className="label-text"><h5>L طول القناة</h5> <p>{e.channalL}</p></div>}
                                            {e.channalML && <div className="label-text"><h5>ML طول القناة</h5> <p>{e.channalML}</p></div>}
                                            {e.channalD && <div className="label-text"><h5>D طول القناة</h5> <p>{e.channalD}</p></div>}
                                            {e.tarmimMaterial && <div className="label-text"><h5>نوع المادة الترميمية</h5> <p>{e.tarmimMaterial}</p></div>}
                                            {e.chooseClass && <div className="label-text"><h5>Class نوع ال</h5> <p>{e.chooseClass}</p></div>}
                                            {e.class2Option && <div className="label-text"><h5>class2 نوع ال</h5> <p>{e.class2Option}</p></div>}
                                            {e.tagGazafMadan && <div className="label-text"><h5>تاج خزف على معدن</h5> <p>{e.tagGazafMadan}</p></div>}
                                            {e.tagGazafiKamel && <div className="label-text"><h5>تاج خزفي كامل</h5> <p>{e.tagGazafiKamel}</p></div>}
                                            {e.GsrGazafMadan && <div className="label-text"><h5>جسر خزف على معدن</h5> <p>{e.GsrGazafMadan}</p></div>}
                                            {e.GsrGazafiKamel && <div className="label-text"><h5>جسر خزفي كامل</h5> <p>{e.GsrGazafiKamel}</p></div>}
                                            {e.Tigan && <div className="label-text"><h5>تيجان</h5> <p>{e.Tigan}</p></div>}
                                            {e.Gsr && <div className="label-text"><h5>جسور</h5> <p>{e.Gsr}</p></div>}
                                            {e.tanzif && <div className="label-text"><h5>تنظيف لثة وأسنان</h5> <p>{e.tanzif}</p></div>}
                                            {e.tabeed && <div className="label-text"><h5>تبييض</h5> <p>{e.tabeed}</p></div>}
                                            {e.kala && <div className="label-text"><h5>قلع</h5> <p>{e.kala}</p></div>}
                                            {e.noteLopia && <div className="label-text"><h5>ملاحظات المعالجة</h5> <p>{e.noteLopia}</p></div>}
                                            {e.noteVeneers && <div className="label-text"><h5>Veneers ملاحظات ال</h5> <p>{e.noteVeneers}</p></div>}
                                            {e.noteoutclinic && <div className="label-text"><h5>ملاحظات</h5> <p>{e.noteoutclinic}</p></div>}
                                            {e.noteTegan && <div className="label-text"><h5>ملاحظات التيجان</h5> <p>{e.noteTegan}</p></div>}
                                            {e.noteGsoor && <div className="label-text"><h5>ملاحظات الجسور</h5> <p>{e.noteGsoor}</p></div>}
                                            {e.noteKala && <div className="label-text"><h5>ملاحظات القلع</h5> <p>{e.noteKala}</p></div>}
                                            {e.noteTanzif && <div className="label-text"><h5>ملاحظات التنظيف</h5> <p>{e.noteTanzif}</p></div>}
                                            {e.noteTabeed && <div className="label-text"><h5>ملاحظات التبييض</h5> <p>{e.noteTabeed}</p></div>}
                                            {e.noteTarmim && <div className="label-text"><h5>ملاحظات الترميم</h5> <p>{e.noteTarmim}</p></div>}
                                            {e.channalNote && <div className="label-text"><h5>ملاحظات للقنوات</h5> <p>{e.channalNote}</p></div>}
                                            {e.cost && <div className="label-text-cost"><h4>التكلفة</h4> <h5>{e.cost}</h5></div>}
                                            {/*e.tooth && <div className="label-text"><b>رقم السن أو الضرس:</b> {e.tooth}</div>*/}
                                        </div>
                                        <div className="tools-treat">
                                            {selectedId === e.id && <button className="btn btn-light" onClick={edit}><BiEditAlt /></button>}
                                            <a href="#d" className="btn btn-danger" onClick={handleOpenPopup}><RiDeleteBin6Line /></a>
                                        </div>
                                    </div>}
                                </div>
                            );

                        })}
                    </div>
                </div>
            </div>}
            </div>}









            {auth == 1 && <div>{isTabletOrMobile && <div className="ground-get-mobile">
                {!status && infoooTeeth.length > 0 && <div className="loading-all">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">... يرجى الانتظار</span>
                    </div>
                    <h2 className="loading">... يرجى الانتظار</h2>
                </div>}
                <div className="fk-mobile">
                    <div className='h-mobile'>
                        {status === "دائم" && <div className='hh-mobile'>
                            <img className={toothList.includes("8LU") ? "b-mobile" : (toothoutClinicList.includes("8LU") ? "out-mobile" : "tooth-mobile")} src={t8u} onClick={changeTooth8LU}></img>
                            <img className={toothList.includes("7LU") ? "b-mobile" : (toothoutClinicList.includes("7LU") ? "out-mobile" : "tooth-mobile")} src={t7u} onClick={changeTooth7LU}></img>
                            <img className={toothList.includes("6LU") ? "b-mobile" : (toothoutClinicList.includes("6LU") ? "out-mobile" : "tooth-mobile")} src={t6u} onClick={changeTooth6LU}></img>
                            <img className={toothList.includes("5LU") ? "b-mobile" : (toothoutClinicList.includes("5LU") ? "out-mobile" : "tooth-mobile")} src={t5u} onClick={changeTooth5LU}></img>
                            <img className={toothList.includes("4LU") ? "b-mobile" : (toothoutClinicList.includes("4LU") ? "out-mobile" : "tooth-mobile")} src={t4u} onClick={changeTooth4LU}></img>
                            <img className={toothList.includes("3LU") ? "b-mobile" : (toothoutClinicList.includes("3LU") ? "out-mobile" : "tooth-mobile")} src={t3u} onClick={changeTooth3LU}></img>
                            <img className={toothList.includes("2LU") ? "b-mobile" : (toothoutClinicList.includes("2LU") ? "out-mobile" : "tooth-mobile")} src={t2u} onClick={changeTooth2LU}></img>
                            <img className={toothList.includes("1LU") ? "b-mobile" : (toothoutClinicList.includes("1LU") ? "out-mobile" : "tooth-mobile")} src={t1u} onClick={changeTooth1LU}></img>
                        </div>}
                        {status === "دائم" && <div className='hh-mobile'>
                            <img className={toothList.includes("1RU") ? "b-mobile" : (toothoutClinicList.includes("1RU") ? "out-mobile" : "tooth-mobile")} src={t1ru} onClick={changeTooth1RU}></img>
                            <img className={toothList.includes("2RU") ? "b-mobile" : (toothoutClinicList.includes("2RU") ? "out-mobile" : "tooth-mobile")} src={t2ru} onClick={changeTooth2RU}></img>
                            <img className={toothList.includes("3RU") ? "b-mobile" : (toothoutClinicList.includes("3RU") ? "out-mobile" : "tooth-mobile")} src={t3ru} onClick={changeTooth3RU}></img>
                            <img className={toothList.includes("4RU") ? "b-mobile" : (toothoutClinicList.includes("4RU") ? "out-mobile" : "tooth-mobile")} src={t4ru} onClick={changeTooth4RU}></img>
                            <img className={toothList.includes("5RU") ? "b-mobile" : (toothoutClinicList.includes("5RU") ? "out-mobile" : "tooth-mobile")} src={t5ru} onClick={changeTooth5RU}></img>
                            <img className={toothList.includes("6RU") ? "b-mobile" : (toothoutClinicList.includes("6RU") ? "out-mobile" : "tooth-mobile")} src={t6ru} onClick={changeTooth6RU}></img>
                            <img className={toothList.includes("7RU") ? "b-mobile" : (toothoutClinicList.includes("7RU") ? "out-mobile" : "tooth-mobile")} src={t7ru} onClick={changeTooth7RU}></img>
                            <img className={toothList.includes("8RU") ? "b-mobile" : (toothoutClinicList.includes("8RU") ? "out-mobile" : "tooth-mobile")} src={t8ru} onClick={changeTooth8RU}></img>
                        </div>}
                        {status === "مؤقت" && <div className='hh-mobile'>
                            <img className={toothList.includes("5LUT") ? "b-mobile" : (toothoutClinicList.includes("5LUT") ? "out-mobile" : "tooth-mobile")} src={t5tlu} onClick={changeTooth5LUT}></img>
                            <img className={toothList.includes("4LUT") ? "b-mobile" : (toothoutClinicList.includes("4LUT") ? "out-mobile" : "tooth-mobile")} src={t4tlu} onClick={changeTooth4LUT}></img>
                            <img className={toothList.includes("3LUT") ? "b-mobile" : (toothoutClinicList.includes("3LUT") ? "out-mobile" : "tooth-mobile")} src={t3tlu} onClick={changeTooth3LUT}></img>
                            <img className={toothList.includes("2LUT") ? "b-mobile" : (toothoutClinicList.includes("2LUT") ? "out-mobile" : "tooth-mobile")} src={t2tlu} onClick={changeTooth2LUT}></img>
                            <img className={toothList.includes("1LUT") ? "b-mobile" : (toothoutClinicList.includes("1LUT") ? "out-mobile" : "tooth-mobile")} src={t1tlu} onClick={changeTooth1LUT}></img>
                        </div>}
                        {status === "مؤقت" && <div className='hh-mobile'>
                            <img className={toothList.includes("1RUT") ? "b-mobile" : (toothoutClinicList.includes("1RUT") ? "out-mobile" : "tooth-mobile")} src={t1tu} onClick={changeTooth1RUT}></img>
                            <img className={toothList.includes("2RUT") ? "b-mobile" : (toothoutClinicList.includes("2RUT") ? "out-mobile" : "tooth-mobile")} src={t2tu} onClick={changeTooth2RUT}></img>
                            <img className={toothList.includes("3RUT") ? "b-mobile" : (toothoutClinicList.includes("3RUT") ? "out-mobile" : "tooth-mobile")} src={t3tu} onClick={changeTooth3RUT}></img>
                            <img className={toothList.includes("4RUT") ? "b-mobile" : (toothoutClinicList.includes("4RUT") ? "out-mobile" : "tooth-mobile")} src={t4tu} onClick={changeTooth4RUT}></img>
                            <img className={toothList.includes("5RUT") ? "b-mobile" : (toothoutClinicList.includes("5RUT") ? "out-mobile" : "tooth-mobile")} src={t5tu} onClick={changeTooth5RUT}></img>
                        </div>}
                    </div>
                    <div className="l-mobile">
                        {status === "دائم" && <div className='hh-mobile'>
                            <img className={toothList.includes("8LD") ? "b-mobile" : (toothoutClinicList.includes("8LD") ? "out-mobile" : "tooth-mobile")} src={t8d} onClick={changeTooth8LD}></img>
                            <img className={toothList.includes("7LD") ? "b-mobile" : (toothoutClinicList.includes("7LD") ? "out-mobile" : "tooth-mobile")} src={t7d} onClick={changeTooth7LD}></img>
                            <img className={toothList.includes("6LD") ? "b-mobile" : (toothoutClinicList.includes("6LD") ? "out-mobile" : "tooth-mobile")} src={t6d} onClick={changeTooth6LD}></img>
                            <img className={toothList.includes("5LD") ? "b-mobile" : (toothoutClinicList.includes("5LD") ? "out-mobile" : "tooth-mobile")} src={t5d} onClick={changeTooth5LD}></img>
                            <img className={toothList.includes("4LD") ? "b-mobile" : (toothoutClinicList.includes("4LD") ? "out-mobile" : "tooth-mobile")} src={t4d} onClick={changeTooth4LD}></img>
                            <img className={toothList.includes("3LD") ? "b-mobile" : (toothoutClinicList.includes("3LD") ? "out-mobile" : "tooth-mobile")} src={t3d} onClick={changeTooth3LD}></img>
                            <img className={toothList.includes("2LD") ? "b-mobile" : (toothoutClinicList.includes("2LD") ? "out-mobile" : "tooth-mobile")} src={t2d} onClick={changeTooth2LD}></img>
                            <img className={toothList.includes("1LD") ? "b-mobile" : (toothoutClinicList.includes("1LD") ? "out-mobile" : "tooth-mobile")} src={t1d} onClick={changeTooth1LD}></img>
                        </div>}
                        {status === "دائم" && <div className='hh-mobile'>
                            <img className={toothList.includes("1RD") ? "b-mobile" : (toothoutClinicList.includes("1RD") ? "out-mobile" : "tooth-mobile")} src={t1rd} onClick={changeTooth1RD}></img>
                            <img className={toothList.includes("2RD") ? "b-mobile" : (toothoutClinicList.includes("2RD") ? "out-mobile" : "tooth-mobile")} src={t2rd} onClick={changeTooth2RD}></img>
                            <img className={toothList.includes("3RD") ? "b-mobile" : (toothoutClinicList.includes("3RD") ? "out-mobile" : "tooth-mobile")} src={t3rd} onClick={changeTooth3RD}></img>
                            <img className={toothList.includes("4RD") ? "b-mobile" : (toothoutClinicList.includes("4RD") ? "out-mobile" : "tooth-mobile")} src={t4rd} onClick={changeTooth4RD}></img>
                            <img className={toothList.includes("5RD") ? "b-mobile" : (toothoutClinicList.includes("5RD") ? "out-mobile" : "tooth-mobile")} src={t5rd} onClick={changeTooth5RD}></img>
                            <img className={toothList.includes("6RD") ? "b-mobile" : (toothoutClinicList.includes("6RD") ? "out-mobile" : "tooth-mobile")} src={t6rd} onClick={changeTooth6RD}></img>
                            <img className={toothList.includes("7RD") ? "b-mobile" : (toothoutClinicList.includes("7RD") ? "out-mobile" : "tooth-mobile")} src={t7rd} onClick={changeTooth7RD}></img>
                            <img className={toothList.includes("8RD") ? "b-mobile" : (toothoutClinicList.includes("8RD") ? "out-mobile" : "tooth-mobile")} src={t8rd} onClick={changeTooth8RD}></img>
                        </div>}
                    </div>
                    <div className="l-mobile">
                        {status === "مؤقت" && <div className='hh-mobile'>
                            <img className={toothList.includes("5LDT") ? "b-mobile" : (toothoutClinicList.includes("5LDT") ? "out-mobile" : "tooth-mobile")} src={t5tld} onClick={changeTooth5LDT}></img>
                            <img className={toothList.includes("4LDT") ? "b-mobile" : (toothoutClinicList.includes("4LDT") ? "out-mobile" : "tooth-mobile")} src={t4tld} onClick={changeTooth4LDT}></img>
                            <img className={toothList.includes("3LDT") ? "b-mobile" : (toothoutClinicList.includes("3LDT") ? "out-mobile" : "tooth-mobile")} src={t3tld} onClick={changeTooth3LDT}></img>
                            <img className={toothList.includes("2LDT") ? "b-mobile" : (toothoutClinicList.includes("2LDT") ? "out-mobile" : "tooth-mobile")} src={t2tld} onClick={changeTooth2LDT}></img>
                            <img className={toothList.includes("1LDT") ? "b-mobile" : (toothoutClinicList.includes("1LDT") ? "out-mobile" : "tooth-mobile")} src={t1tld} onClick={changeTooth1LDT}></img>
                        </div>}
                        {status === "مؤقت" && <div className='hh-mobile'>
                            <img className={toothList.includes("1RDT") ? "b-mobile" : (toothoutClinicList.includes("1RDT") ? "out-mobile" : "tooth-mobile")} src={t1td} onClick={changeTooth1RDT}></img>
                            <img className={toothList.includes("2RDT") ? "b-mobile" : (toothoutClinicList.includes("2RDT") ? "out-mobile" : "tooth-mobile")} src={t2td} onClick={changeTooth2RDT}></img>
                            <img className={toothList.includes("3RDT") ? "b-mobile" : (toothoutClinicList.includes("3RDT") ? "out-mobile" : "tooth-mobile")} src={t3td} onClick={changeTooth3RDT}></img>
                            <img className={toothList.includes("4RDT") ? "b-mobile" : (toothoutClinicList.includes("4RDT") ? "out-mobile" : "tooth-mobile")} src={t4td} onClick={changeTooth4RDT}></img>
                            <img className={toothList.includes("5RDT") ? "b-mobile" : (toothoutClinicList.includes("5RDT") ? "out-mobile" : "tooth-mobile")} src={t5td} onClick={changeTooth5RDT}></img>
                        </div>}
                    </div>
                    {status == "مختلط" && <div className='hh-both'>
                        <div className="adult">
                            <div className='h-mobile'>
                                {status === "مختلط" && <div className='hh-mobile'>
                                    <img className={toothList.includes("8LUP") ? "b-mobile" : (toothoutClinicList.includes("8LUP") ? "out-mobile" : "tooth-mobile")} src={t8u} onClick={changeTooth8LUP}></img>
                                    <img className={toothList.includes("7LUP") ? "b-mobile" : (toothoutClinicList.includes("7LUP") ? "out-mobile" : "tooth-mobile")} src={t7u} onClick={changeTooth7LUP}></img>
                                    <img className={toothList.includes("6LUP") ? "b-mobile" : (toothoutClinicList.includes("6LUP") ? "out-mobile" : "tooth-mobile")} src={t6u} onClick={changeTooth6LUP}></img>
                                    <img className={toothList.includes("5LUP") ? "b-mobile" : (toothoutClinicList.includes("5LUP") ? "out-mobile" : "tooth-mobile")} src={t5u} onClick={changeTooth5LUP}></img>
                                    <img className={toothList.includes("4LUP") ? "b-mobile" : (toothoutClinicList.includes("4LUP") ? "out-mobile" : "tooth-mobile")} src={t4u} onClick={changeTooth4LUP}></img>
                                    <img className={toothList.includes("3LUP") ? "b-mobile" : (toothoutClinicList.includes("3LUP") ? "out-mobile" : "tooth-mobile")} src={t3u} onClick={changeTooth3LUP}></img>
                                    <img className={toothList.includes("2LUP") ? "b-mobile" : (toothoutClinicList.includes("2LUP") ? "out-mobile" : "tooth-mobile")} src={t2u} onClick={changeTooth2LUP}></img>
                                    <img className={toothList.includes("1LUP") ? "b-mobile" : (toothoutClinicList.includes("1LUP") ? "out-mobile" : "tooth-mobile")} src={t1u} onClick={changeTooth1LUP}></img>
                                </div>}
                                {status === "مختلط" && <div className='hh-mobile'>
                                    <img className={toothList.includes("1RUP") ? "b-mobile" : (toothoutClinicList.includes("1RUP") ? "out-mobile" : "tooth-mobile")} src={t1ru} onClick={changeTooth1RUP}></img>
                                    <img className={toothList.includes("2RUP") ? "b-mobile" : (toothoutClinicList.includes("2RUP") ? "out-mobile" : "tooth-mobile")} src={t2ru} onClick={changeTooth2RUP}></img>
                                    <img className={toothList.includes("3RUP") ? "b-mobile" : (toothoutClinicList.includes("3RUP") ? "out-mobile" : "tooth-mobile")} src={t3ru} onClick={changeTooth3RUP}></img>
                                    <img className={toothList.includes("4RUP") ? "b-mobile" : (toothoutClinicList.includes("4RUP") ? "out-mobile" : "tooth-mobile")} src={t4ru} onClick={changeTooth4RUP}></img>
                                    <img className={toothList.includes("5RUP") ? "b-mobile" : (toothoutClinicList.includes("5RUP") ? "out-mobile" : "tooth-mobile")} src={t5ru} onClick={changeTooth5RUP}></img>
                                    <img className={toothList.includes("6RUP") ? "b-mobile" : (toothoutClinicList.includes("6RUP") ? "out-mobile" : "tooth-mobile")} src={t6ru} onClick={changeTooth6RUP}></img>
                                    <img className={toothList.includes("7RUP") ? "b-mobile" : (toothoutClinicList.includes("7RUP") ? "out-mobile" : "tooth-mobile")} src={t7ru} onClick={changeTooth7RUP}></img>
                                    <img className={toothList.includes("8RUP") ? "b-mobile" : (toothoutClinicList.includes("8RUP") ? "out-mobile" : "tooth-mobile")} src={t8ru} onClick={changeTooth8RUP}></img>
                                </div>}
                            </div>
                            <div className="l-mobile">
                                {status === "مختلط" && <div className='hh-mobile'>
                                    <img className={toothList.includes("8LDP") ? "b-mobile" : (toothoutClinicList.includes("8LDP") ? "out-mobile" : "tooth-mobile")} src={t8d} onClick={changeTooth8LDP}></img>
                                    <img className={toothList.includes("7LDP") ? "b-mobile" : (toothoutClinicList.includes("7LDP") ? "out-mobile" : "tooth-mobile")} src={t7d} onClick={changeTooth7LDP}></img>
                                    <img className={toothList.includes("6LDP") ? "b-mobile" : (toothoutClinicList.includes("6LDP") ? "out-mobile" : "tooth-mobile")} src={t6d} onClick={changeTooth6LDP}></img>
                                    <img className={toothList.includes("5LDP") ? "b-mobile" : (toothoutClinicList.includes("5LDP") ? "out-mobile" : "tooth-mobile")} src={t5d} onClick={changeTooth5LDP}></img>
                                    <img className={toothList.includes("4LDP") ? "b-mobile" : (toothoutClinicList.includes("4LDP") ? "out-mobile" : "tooth-mobile")} src={t4d} onClick={changeTooth4LDP}></img>
                                    <img className={toothList.includes("3LDP") ? "b-mobile" : (toothoutClinicList.includes("3LDP") ? "out-mobile" : "tooth-mobile")} src={t3d} onClick={changeTooth3LDP}></img>
                                    <img className={toothList.includes("2LDP") ? "b-mobile" : (toothoutClinicList.includes("2LDP") ? "out-mobile" : "tooth-mobile")} src={t2d} onClick={changeTooth2LDP}></img>
                                    <img className={toothList.includes("1LDP") ? "b-mobile" : (toothoutClinicList.includes("1LDP") ? "out-mobile" : "tooth-mobile")} src={t1d} onClick={changeTooth1LDP}></img>
                                </div>}
                                {status === "مختلط" && <div className='hh-mobile'>
                                    <img className={toothList.includes("1RDP") ? "b-mobile" : (toothoutClinicList.includes("1RDP") ? "out-mobile" : "tooth-mobile")} src={t1rd} onClick={changeTooth1RDP}></img>
                                    <img className={toothList.includes("2RDP") ? "b-mobile" : (toothoutClinicList.includes("2RDP") ? "out-mobile" : "tooth-mobile")} src={t2rd} onClick={changeTooth2RDP}></img>
                                    <img className={toothList.includes("3RDP") ? "b-mobile" : (toothoutClinicList.includes("3RDP") ? "out-mobile" : "tooth-mobile")} src={t3rd} onClick={changeTooth3RDP}></img>
                                    <img className={toothList.includes("4RDP") ? "b-mobile" : (toothoutClinicList.includes("4RDP") ? "out-mobile" : "tooth-mobile")} src={t4rd} onClick={changeTooth4RDP}></img>
                                    <img className={toothList.includes("5RDP") ? "b-mobile" : (toothoutClinicList.includes("5RDP") ? "out-mobile" : "tooth-mobile")} src={t5rd} onClick={changeTooth5RDP}></img>
                                    <img className={toothList.includes("6RDP") ? "b-mobile" : (toothoutClinicList.includes("6RDP") ? "out-mobile" : "tooth-mobile")} src={t6rd} onClick={changeTooth6RDP}></img>
                                    <img className={toothList.includes("7RDP") ? "b-mobile" : (toothoutClinicList.includes("7RDP") ? "out-mobile" : "tooth-mobile")} src={t7rd} onClick={changeTooth7RDP}></img>
                                    <img className={toothList.includes("8RDP") ? "b-mobile" : (toothoutClinicList.includes("8RDP") ? "out-mobile" : "tooth-mobile")} src={t8rd} onClick={changeTooth8RDP}></img>
                                </div>}
                            </div>
                        </div>
                        <div className="kids">
                            <div className="h-mobile">
                                {status === "مختلط" && <div className='hh-mobile'>
                                    <img className={toothList.includes("5LUTP") ? "b-mobile" : (toothoutClinicList.includes("5LUTP") ? "out-mobile" : "tooth-mobile")} src={t5tlu} onClick={changeTooth5LUTP}></img>
                                    <img className={toothList.includes("4LUTP") ? "b-mobile" : (toothoutClinicList.includes("4LUTP") ? "out-mobile" : "tooth-mobile")} src={t4tlu} onClick={changeTooth4LUTP}></img>
                                    <img className={toothList.includes("3LUTP") ? "b-mobile" : (toothoutClinicList.includes("3LUTP") ? "out-mobile" : "tooth-mobile")} src={t3tlu} onClick={changeTooth3LUTP}></img>
                                    <img className={toothList.includes("2LUTP") ? "b-mobile" : (toothoutClinicList.includes("2LUTP") ? "out-mobile" : "tooth-mobile")} src={t2tlu} onClick={changeTooth2LUTP}></img>
                                    <img className={toothList.includes("1LUTP") ? "b-mobile" : (toothoutClinicList.includes("1LUTP") ? "out-mobile" : "tooth-mobile")} src={t1tlu} onClick={changeTooth1LUTP}></img>
                                </div>}
                                {status === "مختلط" && <div className='hh-mobile'>
                                    <img className={toothList.includes("1RUTP") ? "b-mobile" : (toothoutClinicList.includes("1RUTP") ? "out-mobile" : "tooth-mobile")} src={t1tu} onClick={changeTooth1RUTP}></img>
                                    <img className={toothList.includes("2RUTP") ? "b-mobile" : (toothoutClinicList.includes("2RUTP") ? "out-mobile" : "tooth-mobile")} src={t2tu} onClick={changeTooth2RUTP}></img>
                                    <img className={toothList.includes("3RUTP") ? "b-mobile" : (toothoutClinicList.includes("3RUTP") ? "out-mobile" : "tooth-mobile")} src={t3tu} onClick={changeTooth3RUTP}></img>
                                    <img className={toothList.includes("4RUTP") ? "b-mobile" : (toothoutClinicList.includes("4RUTP") ? "out-mobile" : "tooth-mobile")} src={t4tu} onClick={changeTooth4RUTP}></img>
                                    <img className={toothList.includes("5RUTP") ? "b-mobile" : (toothoutClinicList.includes("5RUTP") ? "out-mobile" : "tooth-mobile")} src={t5tu} onClick={changeTooth5RUTP}></img>
                                </div>}
                            </div>
                            <div className="l-mobile">
                                {status === "مختلط" && <div className='hh-mobile'>
                                    <img className={toothList.includes("5LDTP") ? "b-mobile" : (toothoutClinicList.includes("5LDTP") ? "out-mobile" : "tooth-mobile")} src={t5tld} onClick={changeTooth5LDTP}></img>
                                    <img className={toothList.includes("4LDTP") ? "b-mobile" : (toothoutClinicList.includes("4LDTP") ? "out-mobile" : "tooth-mobile")} src={t4tld} onClick={changeTooth4LDTP}></img>
                                    <img className={toothList.includes("3LDTP") ? "b-mobile" : (toothoutClinicList.includes("3LDTP") ? "out-mobile" : "tooth-mobile")} src={t3tld} onClick={changeTooth3LDTP}></img>
                                    <img className={toothList.includes("2LDTP") ? "b-mobile" : (toothoutClinicList.includes("2LDTP") ? "out-mobile" : "tooth-mobile")} src={t2tld} onClick={changeTooth2LDTP}></img>
                                    <img className={toothList.includes("1LDTP") ? "b-mobile" : (toothoutClinicList.includes("1LDTP") ? "out-mobile" : "tooth-mobile")} src={t1tld} onClick={changeTooth1LDTP}></img>
                                </div>}
                                {status === "مختلط" && <div className='hh-mobile'>
                                    <img className={toothList.includes("1RDTP") ? "b-mobile" : (toothoutClinicList.includes("1RDTP") ? "out-mobile" : "tooth-mobile")} src={t1td} onClick={changeTooth1RDTP}></img>
                                    <img className={toothList.includes("2RDTP") ? "b-mobile" : (toothoutClinicList.includes("2RDTP") ? "out-mobile" : "tooth-mobile")} src={t2td} onClick={changeTooth2RDTP}></img>
                                    <img className={toothList.includes("3RDTP") ? "b-mobile" : (toothoutClinicList.includes("3RDTP") ? "out-mobile" : "tooth-mobile")} src={t3td} onClick={changeTooth3RDTP}></img>
                                    <img className={toothList.includes("4RDTP") ? "b-mobile" : (toothoutClinicList.includes("4RDTP") ? "out-mobile" : "tooth-mobile")} src={t4td} onClick={changeTooth4RDTP}></img>
                                    <img className={toothList.includes("5RDTP") ? "b-mobile" : (toothoutClinicList.includes("5RDTP") ? "out-mobile" : "tooth-mobile")} src={t5td} onClick={changeTooth5RDTP}></img>
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>
                <div lassName="head-treats-container-mobile">
                    <h2 className="label-text">المعالجات</h2>
                    <div className="tools-up">
                        <button className="btn btn-dark btn-add" onClick={back}><div><IoMdArrowRoundBack /></div></button>
                    </div>
                    <div className={toothList.includes(tooth) ? "head-treats-container-list-in-mobile" : (toothoutClinicList.includes(tooth) ? "head-treats-container-list-out-mobile" : "")}>
                        {infoooTeeth.length === 0 && <h4 className="label-text">لا يوجد معالجات</h4>}
                        {!tooth && infoooTeeth.length > 0 && <h4 className="label-text">من فضلك قم باختيار السن</h4>}
                        {(toothList.includes(tooth) || toothoutClinicList.includes(tooth)) && tooth && !infoooTeethh.length && <div className="loading-all">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden label-text">... يرجى الانتظار</span>
                            </div>
                            <h2 className="loading label-text">... يرجى الانتظار</h2>
                        </div>}
                        {infoooTeethh.map((e) => {
                            function handleBackPopup() {
                                setOpen("close");
                            }

                            function handleOpenPopup() {
                                setOpen(e.id);
                                setDeleteMessage("")
                            }
                            function addDetails() {
                                setSelectedId(e.id)
                            }
                            return (
                                <div className="treat" tabIndex={-1}>
                                    {open === e.id && <Popup
                                        id="d"
                                        visible={true}
                                        style={{
                                            border: "2px solid white",
                                            paddingTop: "20px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundImage: "linear-gradient(rgba(225, 225, 225, 0.727),rgb(253 111 136))",
                                            top: 100,
                                            width: "100%",
                                        }}>
                                        <div className="delete-popup-mobile">
                                            <h4>هل تريد بالتأكيد الحذف؟</h4>
                                            <div className="btns-popup">
                                                <button className="btn btn-danger" onClick={deleteTreat}>حذف</button>
                                                <button className="btn btn-dark" onClick={handleBackPopup} >رجوع</button>
                                            </div>
                                            {deleteMessage && <div className="loading-all">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden label-text">{deleteMessage}</span>
                                                </div>
                                                <h2 className="loading label-text">{deleteMessage}</h2>
                                            </div>}
                                        </div>
                                    </Popup>}
                                    {tooth === e.tooth && <div className={toothList.includes(tooth) ? "treatments-tools-container-mobile-in" : (toothoutClinicList.includes(tooth) ? "treatments-tools-container-mobile-out" : "")} onClick={addDetails}>
                                        <div className={toothList.includes(tooth) ? "treatments-container-mobile-in" : (toothoutClinicList.includes(tooth) ? "treatments-container-mobile-out" : "")} >
                                            {e.status && <div className="label-text"><h5>نوع الإطباق</h5><p>{e.status}</p></div>}
                                            {e.treatmentType && <div className="label-text"><h5>نوع المعالجة</h5><p>{e.treatmentType}</p></div>}
                                            {e.lopiaType && <div className="label-text"><h5>نوع المعالجة اللبية </h5><p>{e.lopiaType}</p></div>}
                                            {e.channal && <div className="label-text"><h5>طول القناة</h5> <p>{e.channal}</p></div>}
                                            {e.treatmentType === "معالجة لبية" && <div className="label-text">{cannalsItems}</div>}
                                            {e.channalB && <div className="label-text"><h5>B طول القناة</h5> <p>{e.channalB}</p></div>}
                                            {e.channalP && <div className="label-text"><h5>P طول القناة</h5> <p>{e.channalP}</p></div>}
                                            {e.channalDB && <div className="label-text"><h5>DB طول القناة</h5> <p>{e.channalDB}</p></div>}
                                            {e.channalMB2 && <div className="label-text"><h5>MB2طول القناة</h5> <p>{e.channalMB2}</p></div>}
                                            {e.channalMB1 && <div className="label-text"><h5>MB1 طول القناة</h5> <p>{e.channalMB1}</p></div>}
                                            {e.channalMB && <div className="label-text"><h5>MB طول القناة</h5> <p>{e.channalMB}</p></div>}
                                            {e.channalL && <div className="label-text"><h5>L طول القناة</h5> <p>{e.channalL}</p></div>}
                                            {e.channalML && <div className="label-text"><h5>ML طول القناة</h5> <p>{e.channalML}</p></div>}
                                            {e.channalD && <div className="label-text"><h5>D طول القناة</h5> <p>{e.channalD}</p></div>}
                                            {e.tarmimMaterial && <div className="label-text"><h5>نوع المادة الترميمية</h5> <p>{e.tarmimMaterial}</p></div>}
                                            {e.chooseClass && <div className="label-text"><h5>Class نوع ال</h5> <p>{e.chooseClass}</p></div>}
                                            {e.class2Option && <div className="label-text"><h5>class2 نوع ال</h5> <p>{e.class2Option}</p></div>}
                                            {e.tagGazafMadan && <div className="label-text"><h5>تاج خزف على معدن</h5> <p>{e.tagGazafMadan}</p></div>}
                                            {e.tagGazafiKamel && <div className="label-text"><h5>تاج خزفي كامل</h5> <p>{e.tagGazafiKamel}</p></div>}
                                            {e.GsrGazafMadan && <div className="label-text"><h5>جسر خزف على معدن</h5> <p>{e.GsrGazafMadan}</p></div>}
                                            {e.GsrGazafiKamel && <div className="label-text"><h5>جسر خزفي كامل</h5> <p>{e.GsrGazafiKamel}</p></div>}
                                            {e.Tigan && <div className="label-text"><h5>تيجان</h5> <p>{e.Tigan}</p></div>}
                                            {e.Gsr && <div className="label-text"><h5>جسور</h5> <p>{e.Gsr}</p></div>}
                                            {e.tanzif && <div className="label-text"><h5>تنظيف لثة وأسنان</h5> <p>{e.tanzif}</p></div>}
                                            {e.tabeed && <div className="label-text"><h5>تبييض</h5> <p>{e.tabeed}</p></div>}
                                            {e.kala && <div className="label-text"><h5>قلع</h5> <p>{e.kala}</p></div>}
                                            {e.noteLopia && <div className="label-text"><h5>ملاحظات المعالجة</h5> <p>{e.noteLopia}</p></div>}
                                            {e.noteVeneers && <div className="label-text"><h5>Veneers ملاحظات ال</h5> <p>{e.noteVeneers}</p></div>}
                                            {e.noteoutclinic && <div className="label-text"><h5>ملاحظات</h5> <p>{e.noteoutclinic}</p></div>}
                                            {e.noteTegan && <div className="label-text"><h5>ملاحظات التيجان</h5> <p>{e.noteTegan}</p></div>}
                                            {e.noteGsoor && <div className="label-text"><h5>ملاحظات الجسور</h5> <p>{e.noteGsoor}</p></div>}
                                            {e.noteKala && <div className="label-text"><h5>ملاحظات القلع</h5> <p>{e.noteKala}</p></div>}
                                            {e.noteTanzif && <div className="label-text"><h5>ملاحظات التنظيف</h5> <p>{e.noteTanzif}</p></div>}
                                            {e.noteTabeed && <div className="label-text"><h5>ملاحظات التبييض</h5> <p>{e.noteTabeed}</p></div>}
                                            {e.noteTarmim && <div className="label-text"><h5>ملاحظات الترميم</h5> <p>{e.noteTarmim}</p></div>}
                                            {e.channalNote && <div className="label-text"><h5>ملاحظات للقنوات</h5> <p>{e.channalNote}</p></div>}
                                            {e.cost && <div className="label-text-cost"><h4>التكلفة</h4> <h5>{e.cost}</h5></div>}
                                            {/*e.tooth && <div className="label-text"><b>رقم السن أو الضرس:</b> {e.tooth}</div>*/}
                                        </div>
                                        <div className="tools-treat">
                                            {selectedId === e.id && <button className="btn btn-light" onClick={edit}><BiEditAlt /></button>}
                                            <a href="#d" className="btn btn-danger" onClick={handleOpenPopup}><RiDeleteBin6Line /></a>
                                        </div>
                                    </div>}
                                </div>

                            );

                        })}

                    </div>
                </div>
                <div>
                    {/*treatmentType
                lopiaType
                status
                channal
                channalNote
                channalB
                channalP
                channalDB
                channalMB2
                channalMB1
                channalMB
                channalL
                channalML
                channalD
                tarmimMaterial
                chooseClass
                class2Option
                tagGazafMadan
                tagGazafiKamel
                GsrGazafMadan
                GsrGazafiKamel
                Tigan
                Gsr
                tanzif
                tabeed
                kala
                noteLopia
                noteVeneers
                noteLopiaKamela
                noteTegan
                noteGsoor
                noteKala
                noteTanzif
                noteTabeed
                noteTarmim
                note
        tooth*/}
                </div>
            </div>}
            </div>}
            {auth == 0 && <LogIn />}
        </div>
    );
}

export default TreatmentsList;

/*<div className="treat" tabIndex={-1}>
                                    {tooth === e.tooth && <div className={toothList.includes(tooth) ? "treatments-container-in" : (toothoutClinicList.includes(tooth) ? "treatments-container-out" : "")} onClick={addDetails}>
                                        {e.status && <div className="label-text"><h5>نوع الإطباق</h5><p>{e.status}</p></div>}
                                        {e.treatmentType && <div className="label-text"><h5>نوع المعالجة</h5><p>{e.treatmentType}</p></div>}
                                        {e.lopiaType && <div className="label-text"><h5>نوع المعالجة اللبية </h5><p>{e.lopiaType}</p></div>}
                                        {e.channal && <div className="label-text"><h5>طول القناة</h5> <p>{e.channal}</p></div>}
                                        {e.channalB && <div className="label-text"><h5>B طول القناة</h5> <p>{e.channalB}</p></div>}
                                        {e.channalP && <div className="label-text"><h5>P طول القناة</h5> <p>{e.channalP}</p></div>}
                                        {e.channalDB && <div className="label-text"><h5>DB طول القناة</h5> <p>{e.channalDB}</p></div>}
                                        {e.channalMB2 && <div className="label-text"><h5>MB2طول القناة</h5> <p>{e.channalMB2}</p></div>}
                                        {e.channalMB1 && <div className="label-text"><h5>MB1 طول القناة</h5> <p>{e.channalMB1}</p></div>}
                                        {e.channalMB && <div className="label-text"><h5>MB طول القناة</h5> <p>{e.channalMB}</p></div>}
                                        {e.channalL && <div className="label-text"><h5>L طول القناة</h5> <p>{e.channalL}</p></div>}
                                        {e.channalML && <div className="label-text"><h5>ML طول القناة</h5> <p>{e.channalML}</p></div>}
                                        {e.channalD && <div className="label-text"><h5>D طول القناة</h5> <p>{e.channalD}</p></div>}
                                        {e.tarmimMaterial && <div className="label-text"><h5>نوع المادة الترميمية</h5> <p>{e.tarmimMaterial}</p></div>}
                                        {e.chooseClass && <div className="label-text"><h5>Class نوع ال</h5> <p>{e.chooseClass}</p></div>}
                                        {e.class2Option && <div className="label-text"><h5>class2 نوع ال</h5> <p>{e.class2Option}</p></div>}
                                        {e.tagGazafMadan && <div className="label-text"><h5>تاج خزف على معدن</h5> <p>{e.tagGazafMadan}</p></div>}
                                        {e.tagGazafiKamel && <div className="label-text"><h5>تاج خزفي كامل</h5> <p>{e.tagGazafiKamel}</p></div>}
                                        {e.GsrGazafMadan && <div className="label-text"><h5>جسر خزف على معدن</h5> <p>{e.GsrGazafMadan}</p></div>}
                                        {e.GsrGazafiKamel && <div className="label-text"><h5>جسر خزفي كامل</h5> <p>{e.GsrGazafiKamel}</p></div>}
                                        {e.Tigan && <div className="label-text"><h5>تيجان</h5> <p>{e.Tigan}</p></div>}
                                        {e.Gsr && <div className="label-text"><h5>جسور</h5> <p>{e.Gsr}</p></div>}
                                        {e.tanzif && <div className="label-text"><h5>تنظيف لثة وأسنان</h5> <p>{e.tanzif}</p></div>}
                                        {e.tabeed && <div className="label-text"><h5>تبييض</h5> <p>{e.tabeed}</p></div>}
                                        {e.kala && <div className="label-text"><h5>قلع</h5> <p>{e.kala}</p></div>}
                                        {e.noteLopia && <div className="label-text"><h5>ملاحظات المعالجة</h5> <p>{e.noteLopia}</p></div>}
                                        {e.noteVeneers && <div className="label-text"><h5>Veneers ملاحظات ال</h5> <p>{e.noteVeneers}</p></div>}
                                        {e.noteoutclinic && <div className="label-text"><h5>ملاحظات</h5> <p>{e.noteoutclinic}</p></div>}
                                        {e.noteTegan && <div className="label-text"><h5>ملاحظات التيجان</h5> <p>{e.noteTegan}</p></div>}
                                        {e.noteGsoor && <div className="label-text"><h5>ملاحظات الجسور</h5> <p>{e.noteGsoor}</p></div>}
                                        {e.noteKala && <div className="label-text"><h5>ملاحظات القلع</h5> <p>{e.noteKala}</p></div>}
                                        {e.noteTanzif && <div className="label-text"><h5>ملاحظات التنظيف</h5> <p>{e.noteTanzif}</p></div>}
                                        {e.noteTabeed && <div className="label-text"><h5>ملاحظات التبييض</h5> <p>{e.noteTabeed}</p></div>}
                                        {e.noteTarmim && <div className="label-text"><h5>ملاحظات الترميم</h5> <p>{e.noteTarmim}</p></div>}
                                        {e.channalNote && <div className="label-text"><h5>ملاحظات للقنوات</h5> <p>{e.channalNote}</p></div>}
                                        {e.cost && <div className="label-text-cost"><h4>التكلفة</h4> <h5>{e.cost}</h5></div>}
                                        {/*e.tooth && <div className="label-text"><b>رقم السن أو الضرس:</b> {e.tooth}</div>*///}
//</div>}
// </div>*/