import { useMediaQuery } from "react-responsive";
import LogoPic from "../assets/header.png"
import "./Header.css"
import NavBar from "./navBar";
import { useContext, useEffect, useState } from "react";
import { SearcgContext } from "./SearchContext";
import { loginkey } from "./LoginContext";
import { AddDetailsContext } from "./AddDetailsContext";
import { NavContext } from "./NavContext";
import Logo from '../assets/companyLogo2.png';
import React from "react";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function Header() {
    const { setSearch } = useContext(SearcgContext);
    const { auth } = useContext(loginkey);
    const { setAuth } = useContext(loginkey);
    const [a, setA] = useState("")
    const [d, setD] = useState("")
    const { addDetailsContextt } = useContext(AddDetailsContext);
    const { setAddDetailsContextt } = useContext(AddDetailsContext);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const handleSearch = (e) => {
        setSearch(e.target.value);
    }
    const navigate= useNavigate();
    function handleLogout(){
        setAuth(0);
        navigate("/", { replace: true });
    }
    /* useEffect(() => {
         if (auth === 0) {
             setAuth(window.localStorage.getItem("auth"))
             setAddDetailsContextt(window.localStorage.getItem("details"))
             console.log(`auth ${window.localStorage.getItem("auth")}`);
             console.log(`details ${window.localStorage.getItem("details")}`);
         }
     }, [])
     useEffect(() => {
             window.localStorage.setItem("auth", auth);
             console.log(`auth from 1111 ${auth}`)
             window.localStorage.setItem("details", addDetailsContextt);
     }, [addDetailsContextt])*/
    console.log(`aaaauth ${auth}`);
    console.log(`ddddetails ${addDetailsContextt}`);
    return (
        <div>
            {isDesktopOrLaptop && <header className="header-container">
                <div className="header-search">
                    <img src={Logo} alt="logo-img" className="logo"></img>
                    {auth===1 && <button className="btn" onClick={handleLogout}><BiLogOut color="gray" size={32}/></button>}
                </div>
                <div className="title-container">
                    <div className="title">عيادة الدكتور ميلاد جورية </div>
                    {auth == 0 && <img src={LogoPic} alt="logoPic-img" className="logoPic-img"></img>}
                    {/*<div className="title">C l i n i c</div>*/}
                </div>
                {auth == 1 && !addDetailsContextt && <NavBar />}
            </header>}
            {isTabletOrMobile && <header className="header-container-mobile">
                <div className="header-search">
                    <img src={Logo} alt="logo-img-mobile" className="logo"></img>
                    {auth===1 && <button className="btn" onClick={handleLogout}><BiLogOut color="gray" size={32}/></button>}
                </div>
                <div className="title-container">
                    <div className="title-mobile">عيادة الدكتور ميلاد جورية </div>
                    <img src={LogoPic} alt="logoPic-img" className="logoPic-img-mobile"></img>
                    {/*<div className="title">C l i n i c</div>*/}
                </div>
                {/*auth == 1 && !addDetailsContextt && <NavBar />*/}
            </header>}
        </div>
    );
}

export default Header;