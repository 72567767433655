import { createContext, useContext, useEffect, useState } from "react";
import "./login.css"
import axios from "axios";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import RestPassword from "./RestPassword";
import Sync from "./sync";
import { loginkey } from "./LoginContext";
import { useMediaQuery } from "react-responsive";
import React from "react";


function Register() {
    const [uploadmessage, setUploadMessage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [sentCode, setSentCode] = useState(false);
    const [code, setCode] = useState("");
    const [message, setMessage] = useState("");
    const [codeMessage, setCodeMessage] = useState("");
    const [renew, setRenew] = useState(false);
    const [infoo, setInfoo] = useState([]);
    const { setAuth } = useContext(loginkey);

    function sendEmail(event) {
        event.preventDefault();
        setUploadMessage('... يرجى الانتظار')
        const url = 'https://dr-meladjouriah.fxcode.tech/php/sendemailRegister.php';
        const formData = new FormData();
        formData.append('email', email);
        formData.append('message', message);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setUploadMessage("تم")
                setRenew(true);
            }
            console.log(response);
        })
            .catch(error => {
                setMessage('تم')
                setUploadMessage(error.message)
                console.log(error);
            });
        setSentCode(true)
    }

    const handleRegister = async (event) => {
        //event.preventDefault();
        setUploadMessage('... يرجى الانتظار')
        const url = 'https://dr-meladjouriah.fxcode.tech/php/register.php';
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        const config = {
            headers: {
                "Content-Type": "text/html",
                "charset": "utf-8"
            },
        };
        axios.post(url, formData, config).then(response => {
            if (response.status === 200) {
                setUploadMessage("تم")
                window.location.reload()
                navToGet();
            }
            console.log(response);
        })
            .catch(error => {
                setUploadMessage(error.message)
                console.log(error);
            });
    }


    const getRest = async (event) => {
        event.preventDefault();
        await fetch(`https://dr-meladjouriah.fxcode.tech/php/checkCodeRegister.php?code=${code}`,
            {
                headers: {
                    "Content-Type": "text/html",
                    "charset": "utf-8"
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    setMessage('يرجى الانتظار')
                    throw Error('Could not fetch the data')
                }
                else if (response.ok) {
                    setMessage('تم')
                    return response.json()
                }
            })
            .then((data) => {
                if (data.values.length > 0) {
                    handleRegister();
                    setCodeMessage('تم')
                }
                else {
                    setCodeMessage("رمز التفعيل خاطئ")
                }
                console.log(data)
                return (
                    setInfoo(data.values));
            })
            .catch(err => {
                setMessage(err.message)
            })
    };



    var sha1 = require('sha1');

    const handleCodeChange = (e) => {
        setCode(e.target.value);
    }
    /*const handlereNewChange = () => {
        sendEmail();
        setRenew(true);
    }*/

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handlePasswordChange = (e) => {
        setPassword(sha1(`${e.target.value}`));
    }
    const navigate = useNavigate()

    function navToGet() {
        navigate("/", { replace: true });
    }
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    return (
        <div>
            {isDesktopOrLaptop && <div className="login-page">
                <div className="container">
                    {renew && <div className="rest-send">
                        <div className="container-send">
                            <div className="submit-container">
                                <div>من فضلك أدخل رمز التفعيل</div>
                                <form className="form-submit">
                                    <input className="form-control" onChange={(event) => { handleCodeChange(event) }} required></input>
                                    <div className="submit-btn-container">
                                        <button className="btn btn-dark submit-btn" onClick={getRest}>تأكيد</button>
                                    </div>
                                    {codeMessage && <div className="label-text">{codeMessage}</div>}
                                </form>
                            </div>
                        </div>
                    </div>}
                    {!renew && <div className="container">
                        <div className="login-container">
                            <form className="login-form" onSubmit={sendEmail}><h4 className="title-login">التسجيل</h4>
                                <input type="email" className="form-control login-input" placeholder="الإيميل" onChange={(event) => { handleEmailChange(event) }}></input>
                                <input type="password" className="form-control login-input" placeholder="كلمة السر" onChange={(event) => { handlePasswordChange(event) }}></input>
                                <div className="login-btn-container">
                                    <button type="submit" className="btn btn-dark login-btn">تسجيل</button>
                                </div>
                                {uploadmessage && <div className="label-text">{uploadmessage}</div>}
                            </form>
                        </div>
                    </div>}
                </div>
            </div>}
            {isTabletOrMobile && <div className="login-page-mobile">
                <div className="container">
                    {renew && <div className="rest-send-mobile">
                        <div className="container-send-mobile">
                            <div className="submit-container-mobile">
                                <div>من فضلك أدخل رمز التفعيل</div>
                                <form className="form-submit-mobile">
                                    <input className="form-control input-form-mobile" onChange={(event) => { handleCodeChange(event) }} required></input>
                                    <div className="submit-btn-container-mobile">
                                        <button className="btn btn-dark submit-btn" onClick={getRest}>تأكيد</button>
                                    </div>
                                    {codeMessage && <div className="label-text">{codeMessage}</div>}
                                </form>
                            </div>
                        </div>
                    </div>}
                    {!renew && <div className="container">
                        <div className="login-container-mobile" >
                            <form className="login-form-mobile" onSubmit={sendEmail}><h4 className="title-login">التسجيل</h4>
                                <input type="email" className="form-control login-input" placeholder="الإيميل" onChange={(event) => { handleEmailChange(event) }}></input>
                                <input type="password" className="form-control login-input" placeholder="كلمة السر" onChange={(event) => { handlePasswordChange(event) }}></input>
                                <div className="login-btn-container-mobile">
                                    <button className="btn btn-dark login-btn">تسجيل</button>
                                </div>
                                {uploadmessage && <div className="label-text">{uploadmessage}</div>}
                            </form>

                        </div>
                    </div>}
                </div>
            </div>}
        </div>
    );
}

export default Register;